import React, { useState } from "react"
import { useLazyQuery, useMutation } from "@apollo/client"
import { Row, Col, Button } from "reactstrap"

import { deleteUserMutation } from "domains/auth/graphql"
import { userByEmailQuery } from "domains/accountSettings/graphql"
import { fullName } from "util/nameUtil"
import { DeleteModal, SearchInput, InlineError } from "components"

export const AdminSearch = () => {
  const [user, setUser] = useState()
  const [getUser, { loading, error }] = useLazyQuery(userByEmailQuery, {
    fetchPolicy: "network-only",
    onCompleted: result => setUser(result.userByEmail),
    onError: () => setUser(),
  })

  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const [deleteUser, { loading: deletingUser }] = useMutation(
    deleteUserMutation,
    { onError: () => {} }
  )

  const [searchValue, setSearchValue] = useState("")
  const onSearchChange = e => {
    if (e === undefined) {
      setSearchValue("")
    } else {
      setSearchValue(e.target.value)
    }
  }

  const handleDeleteUser = () => {
    setShowDeleteModal(false)
    deleteUser({ variables: { id: user.id, role: user.role } }).then(() => {
      setShowDeleteModal(false)
      setUser()
    })
  }

  const handleDeleteClick = () => setShowDeleteModal(true)

  const searchPlaceholder = "Search for User By Email..."

  return (
    <>
      <div className="u-content-border-bottom pb-5">
        <h2 className="mb-5 mt-5 u-blue-primary">Admin Search</h2>
        <div className="mb-5 d-flex justify-content-between">
          <SearchInput
            placeholder={searchPlaceholder}
            value={searchValue}
            onChange={onSearchChange}
          />
          <Button
            className="c-button--secondary h-50 align-self-end"
            onClick={() => getUser({ variables: { email: searchValue } })}
            disabled={searchValue === ""}>
            Search
          </Button>
        </div>
        {loading && <p>Loading ...</p>}
        {error && <InlineError text="Unable to find user" />}
        {user && (
          <SearchResults user={user} onDeleteClick={handleDeleteClick} />
        )}
      </div>
      {showDeleteModal && (
        <DeleteModal
          isOpen
          toggle={() => setShowDeleteModal(false)}
          loading={deletingUser}
          heading="Delete this user?"
          text={
            <div>
              Deleting user with email <code>{user.email}</code> will unlink
              them from all accounts and completely remove their profile.
            </div>
          }
          onButtonClick={handleDeleteUser}
        />
      )}
    </>
  )
}

const SearchResults = ({ user, onDeleteClick }) => {
  return (
    <div className="container-fluid">
      <div className="mb-4">
        <div className="d-flex justify-between">
          <h2>User Details</h2>
          <a className="text-primary" onClick={onDeleteClick}>
            Delete
          </a>
        </div>
        <div>{fullName(user)}</div>
        <div>{user.email}</div>
        <div>{user.positionTitle}</div>
        <div>{user.role}</div>
      </div>
      <Row className="mb-1">
        <Col>
          <div className="font-weight-bold">School ID</div>
        </Col>
        <Col>
          <div className="font-weight-bold">School Name</div>
        </Col>
        <Col>
          <div className="font-weight-bold">CRM Name</div>
        </Col>
      </Row>
      {user.schools.length === 0 && (
        <div>User is not linked to any schools</div>
      )}
      {user.schools.map((school, index) => {
        return (
          <Row key={index} className="my-3">
            <Col>
              <div className="u-white-space-pre-wrap">{school.id}</div>
            </Col>
            <Col>
              <div className="u-white-space-pre-wrap">{school.name}</div>
            </Col>
            <Col>
              <div className="u-white-space-pre-wrap">
                {school.crm_account_name}
              </div>
            </Col>
          </Row>
        )
      })}
    </div>
  )
}
