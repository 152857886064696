import React, { useState } from "react"

import powerschoolPlugin from "templateFiles/powerschool_plugin.xml"
import {
  Button,
  Modal,
  ModalBody,
  FormGroup,
  Label,
  Input,
  Row,
} from "reactstrap"
import { useNavigate } from "react-router-dom"
import { ButtonBar, ModalHeader, InlineError } from "components"
import { POWERSCHOOL_INTEGRATION_INSTRUCTIONS } from "domains/app/constants/documentLinks"

import { HOME_ROOM, SECTION_ENROLLMENT } from "../constants"

const PowerschoolConfig = ({
  apiEndpoint,
  clientId,
  clientSecret,
  setEdit,
  powerschoolSchoolId,
  useHomeRoomAttribute,
}) => {
  return (
    <>
      <Row>
        <div className="col sm-6">
          <div>
            <b>PowerSchool URL</b>
          </div>
          <p>{apiEndpoint || "Not configured"}</p>
        </div>
        <div className="col sm-6">
          <Label>
            <b>School DCID in PowerSchool</b>
          </Label>
          <p>{powerschoolSchoolId || "Not configured"}</p>
        </div>
      </Row>
      <Row>
        <div className="col sm-6">
          <div>
            <b>Client ID</b>
          </div>
          <p>{clientId || "Not configured"}</p>
        </div>

        <div className="col sm-6">
          <div>
            <b>Client Secret</b>
          </div>
          <p>{clientSecret || "Not configured"}</p>
        </div>
      </Row>
      <Row>
        <FormGroup className="col sm-6">
          <Label className="pl-4" style={{ cursor: "default" }} check>
            <Input
              disabled
              type="checkbox"
              name="clientId"
              checked={useHomeRoomAttribute}
            />
            Use the <code>home_room</code> attribute in Powerschool as current
            class
          </Label>
        </FormGroup>
      </Row>
      <span
        className="text-primary font-size-14 mr-4 cursor-pointer"
        onClick={() => setEdit(true)}>
        Edit
      </span>
    </>
  )
}

const EditPowerschoolConfig = ({
  schoolId,
  apiEndpoint,
  clientId,
  clientSecret,
  setEdit,
  createOrUpdateSisConfigPowerschoolMutation,
  powerschoolSchoolId,
  useHomeRoomAttribute,
}) => {
  const [errors, setErrors] = useState("")
  const [newClientId, setNewClientId] = useState(clientId || "")
  const [newClientSecret, setNewClientSecret] = useState(clientSecret || "")
  const [newApiEndpoint, setNewApiEndpoint] = useState(apiEndpoint || "")
  const [newPowerschoolSchoolId, setNewPowerschoolSchoolId] = useState(
    powerschoolSchoolId || ""
  )
  const [newUseHomeRoomAttribute, setNewUseHomeRoomAttribute] = useState(
    useHomeRoomAttribute || false
  )

  const onClientIdChange = e => setNewClientId(e.target.value)
  const onClientSecretChange = e => setNewClientSecret(e.target.value)
  const onApiEndpointChange = e => setNewApiEndpoint(e.target.value)
  const onPowerschoolSchoolIdChange = e =>
    setNewPowerschoolSchoolId(e.target.value)
  const onHomeRoomAttributeChange = e => {
    setNewUseHomeRoomAttribute(e.target.checked)
  }

  const onSave = () => {
    const variables = {
      schoolId: schoolId,
      config: {
        clientId: newClientId,
        clientSecret: newClientSecret,
        powerschoolSchoolId: newPowerschoolSchoolId,
        apiEndpoint: newApiEndpoint,
        currentClassOption: newUseHomeRoomAttribute
          ? HOME_ROOM
          : SECTION_ENROLLMENT,
      },
    }

    createOrUpdateSisConfigPowerschoolMutation({
      variables,
    })
      .then(() => {
        setEdit(false)
      })
      .catch(() => {
        setErrors("Error saving configuration")
      })
  }

  return (
    <div>
      <Row>
        <FormGroup className="col sm-6">
          <Label>
            <b>PowerSchool URL</b>
          </Label>
          <Input
            type="text"
            name="apiEndpoint"
            onChange={onApiEndpointChange}
            value={newApiEndpoint}
          />
          {newApiEndpoint === "" && <InlineError text="Cannot be blank" />}
        </FormGroup>
        <FormGroup className="col sm-6">
          <Label>
            <b>School DCID in PowerSchool</b>
          </Label>
          <Input
            type="text"
            name="powerschoolSchoolId"
            onChange={onPowerschoolSchoolIdChange}
            value={newPowerschoolSchoolId}
          />
          {newPowerschoolSchoolId === "" && (
            <InlineError text="Cannot be blank" />
          )}
        </FormGroup>
      </Row>
      <Row>
        <FormGroup className="col sm-6">
          <Label>
            <b>Client ID</b>
          </Label>
          <Input
            type="text"
            name="clientId"
            onChange={onClientIdChange}
            value={newClientId}
          />
          {newClientId === "" && <InlineError text="Cannot be blank" />}
        </FormGroup>
        <FormGroup className="col sm-6">
          <Label>
            <b>Client Secret</b>
          </Label>
          <Input
            type="text"
            name="clientSecret"
            onChange={onClientSecretChange}
            value={newClientSecret}
          />
          {newClientSecret === "" && <InlineError text="Cannot be blank" />}
        </FormGroup>
      </Row>
      <Row>
        <FormGroup className="col sm-6">
          <Label className="pl-4" check>
            <Input
              type="checkbox"
              name="clientId"
              onChange={onHomeRoomAttributeChange}
              checked={newUseHomeRoomAttribute}
            />
            Use the <code>home_room</code> attribute in Powerschool as current
            class
          </Label>
        </FormGroup>
      </Row>
      <ButtonBar
        onCancelClick={() => setEdit(false)}
        buttonText="Save Configuration"
        onButtonClick={onSave}
      />
      <InlineError text={errors} />
    </div>
  )
}

const PowerschoolModal = ({
  school: { id: schoolId, powerschoolSisConfig: config },
  createOrUpdateSisConfigPowerschoolMutation,
  toggle,
}) => {
  const [edit, setEdit] = useState(false)
  const clientId = config ? config.clientId : null
  const clientSecret = config ? config.clientSecret : null
  const apiEndpoint = config ? config.apiEndpoint : null
  const powerschoolSchoolId = config ? config.powerschoolSchoolId : null
  const useHomeRoomAttribute = config
    ? config.currentClassOption === HOME_ROOM
    : false

  return (
    <Modal isOpen className="modal-dialog-centered">
      <ModalHeader
        title="Set up Powerschool Integration"
        className="px-5 py-4"
        toggle={toggle}
      />
      <ModalBody className="px-5 py-4">
        <p>
          Please see detailed instructions available{" "}
          <a
            href={POWERSCHOOL_INTEGRATION_INSTRUCTIONS}
            target="_blank"
            rel="noopener noreferrer">
            here
          </a>
          .
        </p>
        <p>Download and install the following plugin into Power School</p>
        <a
          href={powerschoolPlugin}
          download="Class_Solver_PowerSchool_Plugin.xml">
          <Button color="primary m-2">Download PowerSchool Plugin</Button>
        </a>
        <p className="py-2">
          After installing plugin, please enter the Client ID and Client Secret
        </p>
        {edit ? (
          <EditPowerschoolConfig
            schoolId={schoolId}
            clientId={clientId}
            clientSecret={clientSecret}
            apiEndpoint={apiEndpoint}
            setEdit={setEdit}
            powerschoolSchoolId={powerschoolSchoolId}
            createOrUpdateSisConfigPowerschoolMutation={
              createOrUpdateSisConfigPowerschoolMutation
            }
            useHomeRoomAttribute={useHomeRoomAttribute}
          />
        ) : (
          <PowerschoolConfig
            apiEndpoint={apiEndpoint}
            clientId={clientId}
            clientSecret={clientSecret}
            setEdit={setEdit}
            powerschoolSchoolId={powerschoolSchoolId}
            useHomeRoomAttribute={useHomeRoomAttribute}
          />
        )}
      </ModalBody>
    </Modal>
  )
}

export const AdvancedFeatures = ({
  school,
  createOrUpdateSisConfigPowerschoolMutation,
}) => {
  const [showPowerschoolModal, setPowerschoolModal] = useState(false)
  const togglePowerschoolModal = () =>
    setPowerschoolModal(!showPowerschoolModal)

  const navigate = useNavigate()

  return (
    <div id="advanced-features" className="u-content-border-bottom pb-5">
      <h2 className="mb-2 mt-5 u-blue-primary">Advanced Features</h2>
      <div className="mb-5">
        These features that are generally used with the assistance of the Class
        Solver Support Team.
      </div>

      <div className="d-flex justify-content-between mb-4 align-items-center">
        <div>
          <div className="c-misc-upload__description mb-2">
            Import Class Lists
          </div>
          <p className="c-misc-upload__description">
            Import a pre-allocated list of students into the Solver page based
            on the Student ID.
          </p>
        </div>
        <div
          className="c-button--secondary"
          onClick={() => navigate("/AccountSettings/ImportClassLists")}>
          Import Class Lists
        </div>
      </div>

      <div className="d-flex justify-content-between mb-4 align-items-center">
        <div>
          <div className="c-misc-upload__description mb-2">
            Transfer Student Requests
          </div>
          <p className="c-misc-upload__description">
            Export student requests from an account and then import them into a
            different account.
          </p>
        </div>
        <div
          className="c-button--secondary"
          onClick={() => {
            navigate("/AccountSettings/TransferStudentRequests")
          }}>
          Transfer Student Requests
        </div>
      </div>

      <div className="d-flex justify-content-between mb-4 align-items-center">
        <div>
          <div className="c-misc-upload__description mb-2">
            Transfer Teacher Requests
          </div>
          <p className="c-misc-upload__description">
            Export teacher requests from an account and then import them into a
            different account.
          </p>
        </div>
        <div
          className="c-button--secondary"
          onClick={() => {
            navigate("/Requests/TeacherRequests/Transfer")
          }}>
          Transfer Teacher Requests
        </div>
      </div>

      <div className="d-flex justify-content-between mb-4 align-items-center">
        <div>
          <div className="c-misc-upload__description mb-2">
            Power School Integration
          </div>
          <p className="c-misc-upload__description">
            Schools that use the Power School Student Information System can
            import their students details directly without using a CSV.
          </p>
        </div>
        <div className="c-button--secondary" onClick={togglePowerschoolModal}>
          Configure PowerSchool
        </div>
      </div>

      {showPowerschoolModal && (
        <PowerschoolModal
          toggle={togglePowerschoolModal}
          school={school}
          createOrUpdateSisConfigPowerschoolMutation={
            createOrUpdateSisConfigPowerschoolMutation
          }
        />
      )}
    </div>
  )
}
