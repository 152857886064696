import React from "react"
import Select from "react-select"

export const ShareSolutionAssignmentRow = ({
  onDeleteRowClick,
  firstColumnProps = {
    options: [],
    onChange: () => {},
    value: [],
    styles: {},
  },
  secondColumnProps = {
    options: [],
    onChange: () => {},
    value: [],
    styles: {},
  },
}) => {
  return (
    <div className="row py-2">
      <div className="col-1">
        <i
          className="fa fa-trash c-classes__delete-icon"
          onClick={onDeleteRowClick}
        />
      </div>
      <div className="col-5">
        <Select
          options={firstColumnProps.options}
          onChange={firstColumnProps.onChange}
          value={firstColumnProps.value}
          isMulti
          styles={firstColumnProps.styles}
          closeMenuOnSelect={false}
        />
      </div>
      <div className="col-6">
        <Select
          options={secondColumnProps.options}
          onChange={secondColumnProps.onChange}
          value={secondColumnProps.value}
          isMulti
          styles={secondColumnProps.styles}
          closeMenuOnSelect={false}
        />
      </div>
    </div>
  )
}
