import React, { useState } from "react"

import { Modal, ModalBody } from "reactstrap"
import {
  AddButton,
  ModalHeader,
  ModalNote,
  CautionModal,
  SuccessModal,
  VideoModal,
} from "components"
import { CurrentlySharedSolutions } from "./"
import { ShareSolutionMenu } from "./"
import { GRADES } from "../constants"

import { AccountTypeContext } from "config/accountTypeContext"

import { INSTRUCTION_SHARE_CLASSES_PLAYER_URL } from "constants/videos"

const handleShareError = error => {
  if (error) {
    switch (error.graphQLErrors[0].message) {
      case "missing-teacher-email":
        return "Some teachers are missing emails"

      case "teacher-not-found":
        return "Some teachers do not exist in system"

      case "teacher-not-in-school":
        return "You are not allowed to share a solution to some teachers"

      case "characteristics-needs-metrics":
        return "Characteristics needs metrics"

      case "requests-needs-metrics":
        return "Requests needs metrics"

      default:
        return "Error trying to share solution"
    }
  }
}

const handleUnshareError = error => {
  if (error) {
    switch (error.graphQLErrors[0].message) {
      default:
        return "Error trying to unshare solution"
    }
  }
}

const DEFAULT_SHARE_OPTIONS = {
  showTeachers: false,
  showTeacherRequests: false,
  showStudentRequests: false,
  showFriendships: false,
  showCharacteristics: false,
  showMetrics: true,
  writable: false,
}

export const ShareSolutionModalContent = ({
  teachers,
  teachersWithSharedSolutions,
  shareSolution,
  unshareSolution,
  toggle,
  schoolId,
  user,
  availableGrades,
  availableClasses,
  shareError,
  unshareError,
  requestNewToken,
  requestNewTokenError,
  allowWritableSharedSolutions,
}) => {
  // Resend caution modal
  const [resendRequested, setResendRequested] = useState(false)
  const [teacherToResendEmail, setTeacherToResendEmail] = useState()

  // Successful Share modal
  const [shareSuccessful, setShareSuccessful] = useState(false)

  // Show video modal
  const [showVideo, setShowVideo] = useState(false)

  // Some error handling state
  const shareErrorMessage = handleShareError(shareError)
  const unshareErrorMessage = handleUnshareError(unshareError)

  // Show share solution menu
  const [showShareSolutionMenu, setShowShareSolutionMenu] = useState(false)

  // State used for share mutation
  const [assignments, setAssignments] = useState([])

  const gettextObj = React.useContext(AccountTypeContext).gettextObj

  const initialMessage = `Hello Teachers

Please click on the link above to view the ${gettextObj.gettext(
    "class"
  )} lists.  Please let me know if you have any feedback.
Thanks!`

  const [message, setMessage] = useState(initialMessage)
  const [shareOptions, setShareOptions] = useState(DEFAULT_SHARE_OPTIONS)

  const extendedSetShareOptions = options => {
    setShareOptions(prevOptions => {
      if (
        allowWritableSharedSolutions &&
        options.writable &&
        !prevOptions.writable
      ) {
        // Set all options to true by default if we switch on writable
        return {
          showTeachers: true,
          showTeacherRequests: true,
          showStudentRequests: true,
          showFriendships: true,
          showCharacteristics: true,
          showMetrics: true,
          writable: true,
        }
      } else {
        return options
      }
    })
  }

  const getAllClassIdsFromGradeIds = gradeIds => {
    const classIds = availableGrades
      .filter(({ id }) => gradeIds.includes(id))
      .flatMap(({ newClasses }) => newClasses.map(({ id }) => id))

    return [...new Set(classIds)]
  }

  const onShareClick = shareType => {
    return () => {
      // Transform the assignment list of classTeachersAssignment
      //
      // {
      //   classId: ID,
      //   teacherIds: [ID],
      //   shareOptions: ShareOptions
      // }
      const classTeacherMap = assignments.reduce(
        (acc, { gradesOrClasses, teachers }) => {
          const teacherIds = teachers.map(({ value }) => value)
          const gradeOrClassIds = gradesOrClasses.map(({ value }) => value)
          const classIds =
            shareType === GRADES
              ? getAllClassIdsFromGradeIds(gradeOrClassIds)
              : gradeOrClassIds

          for (const classId of classIds) {
            acc[classId] = (acc[classId] || []).concat(teacherIds)
          }
          return acc
        },
        {}
      )

      const classTeachersAssignments = Object.entries(classTeacherMap).map(
        ([classId, teacherIds]) => {
          const uniqueTeacherIds = [...new Set(teacherIds)]
          return {
            classId,
            teacherIds: uniqueTeacherIds,
            shareOptions,
          }
        }
      )

      shareSolution({
        variables: {
          classTeachersAssignments: classTeachersAssignments,
          message: message,
          ccList: [user.email],
        },
      }).then(data => {
        if (data) {
          setAssignments([])
          setShowShareSolutionMenu(false)
          setShareSuccessful(true)
        }
      })
    }
  }

  const onUnshareClick = teacher => {
    unshareSolution({
      variables: {
        schoolId: schoolId,
        teachers: [teacher.id],
      },
    })
  }

  const resendSolution = teacher => {
    const currentToken = teacher.sharedSolutionToken.token

    requestNewToken({
      variables: {
        currentToken,
        tokenType: "SHARED_SOLUTION",
        ccList: [user.email],
      },
    })

    setAssignments([])
    setResendRequested(false)
  }

  const onResendClick = teacher => {
    setTeacherToResendEmail(teacher)
    setResendRequested(true)
  }

  const onUnshareAllClick = () => {
    const allTeacherIds = teachersWithSharedSolutions.map(({ id }) => id)
    unshareSolution({
      variables: {
        schoolId: schoolId,
        teachers: allTeacherIds,
      },
    })
  }

  const toggleVideo = () => {
    setShowVideo(!showVideo)
  }

  return (
    <div>
      <Modal isOpen className="shared-solution-modal modal-dialog-centered">
        <ModalHeader
          title={`Share ${gettextObj.gettext("class")} lists with teachers`}
          toggle={toggle}
          toggle2={toggleVideo}
          className="px-5 py-4"
        />
        <ModalNote text="This function grants teachers access to the Solver page ONLY. They will be able to see Student Notes, but cannot see Admin Only Requests." />
        <ModalBody className="px-5 py-4">
          <CurrentlySharedSolutions
            sharedTeachers={teachersWithSharedSolutions}
            onUnshareClick={onUnshareClick}
            onResendClick={onResendClick}
            onUnshareAllClick={onUnshareAllClick}
            availableGrades={availableGrades}
            allowWritableSharedSolutions={allowWritableSharedSolutions}
          />
          {showShareSolutionMenu ? (
            <>
              <div className="u-separator-border-top pb-4" />
              <ShareSolutionMenu
                message={message}
                setMessage={setMessage}
                shareOptions={shareOptions}
                setShareOptions={extendedSetShareOptions}
                onShareClick={onShareClick}
                shareErrorMessage={shareErrorMessage}
                unshareErrorMessage={unshareErrorMessage}
                toggle={toggle}
                assignments={assignments}
                setAssignments={setAssignments}
                availableClasses={availableClasses}
                availableGrades={availableGrades}
                availableTeachers={teachers}
                allowWritableSharedSolutions={allowWritableSharedSolutions}
              />
            </>
          ) : (
            <AddButton
              text="Add New Share"
              onClick={() => setShowShareSolutionMenu(true)}
            />
          )}
          {resendRequested && (
            <CautionModal
              isOpen
              toggle={() => setResendRequested(false)}
              heading="Resend?"
              text="Are you sure you want to resend shared lists for this teacher?"
              buttonText="Yes, send"
              onButtonClick={() => {
                resendSolution(teacherToResendEmail)
              }}
              error={requestNewTokenError}
            />
          )}
          {shareSuccessful && (
            <SuccessModal
              isOpen
              toggle={() => setShareSuccessful(false)}
              heading="List shared with teacher/s"
            />
          )}
          {showVideo && (
            <VideoModal
              toggle={toggleVideo}
              videoUrl={INSTRUCTION_SHARE_CLASSES_PLAYER_URL}
              // title="Instruction Step 1"
            />
          )}
        </ModalBody>
      </Modal>
    </div>
  )
}
