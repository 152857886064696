import React from "react"
import { Badge } from "./badge"
import { Label } from "reactstrap"
import { useTable } from "react-table"
import moment from "moment"

export const CurrentlySharedSolutions = ({
  sharedTeachers,
  availableGrades,
  onUnshareClick,
  onUnshareAllClick,
  onResendClick,
  allowWritableSharedSolutions,
}) => {
  const renderFullName = ({ row }) => {
    const teacher = row.original
    return `${teacher.firstName} ${teacher.lastName}`
  }

  const renderSharingOption = ({ row, column }) => {
    const accessor = column.id
    const optionIsSet = row.original.sharedSolutionToken[accessor]

    return (
      <div className="text-center">
        {optionIsSet && (
          <i className="fa fa-check-square color-grey-mid align-center" />
        )}
      </div>
    )
  }
  const hasAllClassesInGrades = (grade, classesLabels) => {
    return grade.newClasses.every(({ label }) => classesLabels.includes(label))
  }

  const renderSharedClasses = React.useCallback(
    ({ row }) => {
      const classes = row.original.sharedSolutionToken.newClasses

      const classLabels = classes.map(({ label }) => label)

      // Find the grades which all their classes have been selected
      const gradesWithAllClasses = availableGrades.filter(grade =>
        hasAllClassesInGrades(grade, classLabels)
      )

      // De-duplicate the class labels
      const gradeClassLabelSet = new Set(
        gradesWithAllClasses.flatMap(({ newClasses }) =>
          newClasses.map(({ label }) => label)
        )
      )

      // Find the difference
      const leftOverClasses = classLabels.filter(
        label => !gradeClassLabelSet.has(label)
      )

      // Switch out the class labels with grade labels plus any leftover
      const labels = gradesWithAllClasses
        .map(({ label }) => label)
        .concat(leftOverClasses)

      return (
        <div className="d-flex">
          {labels.map(label => (
            <Badge key={label} label={label} />
          ))}
        </div>
      )
    },
    [availableGrades]
  )

  const renderStatus = ({ row }) => {
    const sharedSolutionToken = row.original.sharedSolutionToken
    return (
      <div className="d-flex justify-content-between align-items-center">
        {sharedSolutionToken.expired ? (
          <span className="color-red">Expired</span>
        ) : (
          <span className="color-green-light">Sent</span>
        )}
        <span>
          {moment(sharedSolutionToken.updatedAt).format("D MMM YYYY")}
        </span>
      </div>
    )
  }

  const renderAction = React.useCallback(
    ({ row }) => {
      const teacher = row.original
      return (
        <div className="text-center">
          <span
            className="cursor-pointer color-blue-mid u-bold"
            onClick={() => onResendClick(teacher)}>
            Resend
          </span>
        </div>
      )
    },
    [onResendClick]
  )

  const renderUnshare = React.useCallback(
    ({ row }) => {
      const teacher = row.original
      return (
        <div className="text-center">
          <span
            className="cursor-pointer color-blue-mid u-bold"
            onClick={() => onUnshareClick(teacher)}>
            Unshare
          </span>
        </div>
      )
    },
    [onUnshareClick]
  )

  const columns = React.useMemo(
    () => [
      {
        Header: "Teacher Name",
        accessor: "fullName",
        Cell: renderFullName,
      },
      {
        Header: "Class(s)",
        accessor: "classes",
        Cell: renderSharedClasses,
      },
      {
        Header: "Tch",
        accessor: "showTeachers",
        Cell: renderSharingOption,
      },
      {
        Header: "Tch Req",
        accessor: "showTeacherRequests",
        Cell: renderSharingOption,
      },
      {
        Header: "Stu Req",
        accessor: "showStudentRequests",
        Cell: renderSharingOption,
      },
      {
        Header: "Frd",
        accessor: "showFriendships",
        Cell: renderSharingOption,
      },
      {
        Header: "Cha",
        accessor: "showCharacteristics",
        Cell: renderSharingOption,
      },
      {
        Header: "Chg",
        accessor: "writable",
        Cell: renderSharingOption,
      },
      {
        Header: "Status",
        accessor: "status",
        Cell: renderStatus,
      },
      {
        Header: () => (
          <div className="font-weight-bold text-center">Action</div>
        ),
        accessor: "action",
        Cell: renderAction,
      },
      {
        Header: () => (
          <div
            className="mr-2 cursor-pointer color-blue-mid u-bold text-center"
            onClick={onUnshareAllClick}>
            Unshare All
          </div>
        ),
        accessor: "unshare",
        Cell: renderUnshare,
      },
    ],
    [renderAction, renderUnshare, onUnshareAllClick, renderSharedClasses]
  )

  const data = React.useMemo(() => sharedTeachers, [sharedTeachers])

  const tableProps = {
    data: data,
    columns: columns,
    initialState: {
      hiddenColumns: allowWritableSharedSolutions ? [] : ["writable"],
    },
  }

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable(tableProps)

  return (
    <div className="pb-4">
      <Label className="c-teachers-invite-modal__text">
        CURRENTLY SHARED WITH
      </Label>

      <div>
        {sharedTeachers.length === 0 ? (
          "Not currently shared with any teachers "
        ) : (
          <table className="shared-solution-table" {...getTableProps()}>
            <thead>
              {headerGroups.map(headerGroup => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map(column => (
                    <th className="py-3" {...column.getHeaderProps()}>
                      {column.render("Header")}
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody {...getTableBodyProps()}>
              {rows.map(row => {
                prepareRow(row)
                return (
                  <tr {...row.getRowProps()}>
                    {row.cells.map(cell => {
                      return (
                        <td className="py-3" {...cell.getCellProps()}>
                          {cell.render("Cell")}
                        </td>
                      )
                    })}
                  </tr>
                )
              })}
            </tbody>
          </table>
        )}
      </div>
    </div>
  )
}
