import React, { PureComponent, Fragment } from "react"
import { Form, Input, FormGroup, Label, Col, Row } from "reactstrap"
import { Query } from "@apollo/client/react/components"
import { countriesQuery } from "domains/auth/graphql"

import { InlineError, Loader, QueryError } from "components"

import { getPropertyIfDefined } from "util/objUtil"

import Select from "react-select"

import { defaultSelectStyles } from "components"

const styles = {
  container: {
    position: "relative",
  },
  stepCounter: {
    position: "absolute",
    top: 0,
    left: 0,
    backgroundColor: "#d8d8d8",
    borderRadius: "100%",
    padding: "4px 12px 5px",
  },
  transition1: {
    backgroundColor: "pink",
  },
  transition2: {
    transition: "all 0.5s ease-in-out !important",
  },
}

export class SchoolDetailsInputs extends PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      numberOfStudentsOnBlur: true,
      positionTitleOnBlur: true,
    }
  }

  renderCountryOptions(countries) {
    if (countries !== undefined) {
      const countryOptions = countries.map((country, index) => {
        return (
          <option key={index + 1} value={country.code}>
            {country.name}
          </option>
        )
      })

      return (
        <>
          <option key={0} disabled value="">
            -- Please select a country --
          </option>
          {countryOptions}
        </>
      )
    }
  }

  renderStateInput(countries, countryCode, value) {
    const country = countries.find(c => c.code === countryCode)
    const states = getPropertyIfDefined(country, "states")

    if (states && states.length > 0) {
      const options = states.map((state, index) => {
        return (
          <option key={index} value={state.code}>
            {state.name}
          </option>
        )
      })
      return (
        <Input
          type="select"
          value={value}
          onChange={this.props.onChange}
          name="stateCode"
          id="stateCode"
          placeholder="State Code">
          <option key="" value="">
            Select State
          </option>
          {options}
        </Input>
      )
    }

    return (
      <Input
        type="text"
        value={value}
        onChange={this.props.onChange}
        name="stateCode"
        id="stateCode"
        placeholder="State"
      />
    )
  }

  renderAccountTypeInput(value) {
    const options = ["CLASS", "CAMP", "ACTIVITY_GROUP"].map(
      (accountType, index) => {
        const lowerCaseValue = accountType.toLowerCase()
        return (
          <option key={index} value={accountType}>
            {lowerCaseValue}
          </option>
        )
      }
    )
    return (
      <Input
        type="select"
        value={value}
        onChange={this.props.onChange}
        name="accountType"
        id="accountType"
        placeholder="Account Type">
        <option key="" value="">
          Select Account Type
        </option>
        {options}
      </Input>
    )
  }

  render() {
    const { numberOfStudentsOnBlur, positionTitleOnBlur } = this.state
    const {
      onChange,
      data,
      errors,
      edit,
      client,
      isSuper,
      availableManagers,
      onManagerChange,
    } = this.props
    const {
      schoolName,
      addressLine1,
      suburb,
      countryCode,
      stateCode,
      postcode,
      contactFirstName,
      contactLastName,
      contactNumber,
      contactPositionTitle,
      contactEmail,
      numberOfStudents,
      crmAccountName,
      crmStatus,
      accountType,
      accountName,
      referrer,
      managers,
    } = data

    const changeFocusOfNumberOfStudents = () => {
      this.setState({
        numberOfStudentsOnBlur: !numberOfStudentsOnBlur,
      })
    }

    const changeFocusOfPositionTitle = () => {
      this.setState({
        positionTitleOnBlur: !positionTitleOnBlur,
      })
    }

    const numberLessThanThreshold = (string, threshold) => {
      const num = parseInt(string, 10)
      if (!isNaN(num) && String(num) === String(string)) {
        return num < threshold
      } else {
        return false
      }
    }

    const stringContainsWord = (string, word) => {
      const lowerCaseString = string.toLowerCase()
      return lowerCaseString.includes(word)
    }

    return (
      <div>
        <Query query={countriesQuery} client={client}>
          {({ loading, error, data, refetch }) => {
            if (loading) return <Loader />
            if (error) return <QueryError error={error} refetch={refetch} />

            const { countries } = data

            return (
              <div>
                <Row className="d-flex align-items-center justify-content-between pb-4 pt-0">
                  <div className="w-100" style={styles.container}>
                    <div className={edit ? "" : "pl-5"}>
                      <Col>
                        {!edit && <h1 className="mb-4">Your school details</h1>}
                        <Form>
                          <FormGroup className="mb-4">
                            <Label htmlFor="schoolName">School name</Label>
                            <Input
                              type="text"
                              value={schoolName || ""}
                              onChange={onChange}
                              name="schoolName"
                              id="schoolName"
                              placeholder="School name"
                            />
                            {errors.schoolName && (
                              <InlineError text={errors.schoolName} />
                            )}
                          </FormGroup>
                          <FormGroup>
                            <Label for="countryCode">Country</Label>
                            <Input
                              type="select"
                              value={countryCode || ""}
                              onChange={onChange}
                              name="countryCode"
                              id="countryCode">
                              {this.renderCountryOptions(countries)}
                            </Input>
                            {errors.countryCode && (
                              <InlineError text={errors.countryCode} />
                            )}
                          </FormGroup>
                          {edit ? (
                            <FormGroup>
                              <Label htmlFor="addressLine1">
                                School address
                              </Label>
                              <Input
                                value={addressLine1 || ""}
                                onChange={onChange}
                                type="text"
                                name="addressLine1"
                                id="addressLine1"
                                placeholder="Address"
                              />
                              {errors.addressLine1 && (
                                <InlineError text={errors.addressLine1} />
                              )}
                              <Input
                                className="mt-4"
                                value={suburb || ""}
                                onChange={onChange}
                                type="text"
                                name="suburb"
                                id="suburb"
                                placeholder="Suburb"
                              />
                              {errors.suburb && (
                                <InlineError text={errors.suburb} />
                              )}
                              <Row className="mt-4">
                                <Col sm="6" className="pr-0">
                                  {this.renderStateInput(
                                    countries,
                                    countryCode,
                                    stateCode
                                  )}
                                  {errors.stateCode && (
                                    <InlineError text={errors.stateCode} />
                                  )}
                                </Col>
                                <Col sm="6">
                                  <Input
                                    type="text"
                                    value={postcode || ""}
                                    onChange={onChange}
                                    name="postcode"
                                    id="postcode"
                                    placeholder="ZIP code"
                                    maxLength={10}
                                  />
                                  {errors.postcode && (
                                    <InlineError text={errors.postcode} />
                                  )}
                                </Col>
                              </Row>
                            </FormGroup>
                          ) : (
                            <Fragment>
                              <FormGroup>
                                <Label for="suburb">Suburb/City</Label>
                                <Input
                                  value={suburb || ""}
                                  onChange={onChange}
                                  type="text"
                                  name="suburb"
                                  id="suburb"
                                  placeholder="Suburb"
                                />
                                {errors.suburb && (
                                  <InlineError text={errors.suburb} />
                                )}
                              </FormGroup>
                              <FormGroup>
                                <Label htmlFor="state">State</Label>
                                {this.renderStateInput(
                                  countries,
                                  countryCode,
                                  stateCode
                                )}
                                {errors.stateCode && (
                                  <InlineError text={errors.stateCode} />
                                )}
                              </FormGroup>
                            </Fragment>
                          )}
                          <FormGroup className="mb-4">
                            <Label htmlFor="contactNumber">
                              Contact number
                            </Label>
                            <Input
                              type="text"
                              value={contactNumber || ""}
                              onChange={onChange}
                              name="contactNumber"
                              id="contactNumber"
                              placeholder="Contact number"
                            />
                            {errors.contactNumber && (
                              <InlineError text={errors.contactNumber} />
                            )}
                          </FormGroup>
                          <FormGroup className="mb-4">
                            <Label htmlFor="numberOfStudents">
                              Number of students (to be placed into classes)
                            </Label>
                            <Input
                              type="number"
                              value={numberOfStudents || ""}
                              onFocus={changeFocusOfNumberOfStudents}
                              onBlur={changeFocusOfNumberOfStudents}
                              onChange={onChange}
                              name="numberOfStudents"
                              id="numberOfStudents"
                              placeholder="# Total Students"
                            />

                            {numberLessThanThreshold(numberOfStudents, 51) &&
                              numberOfStudentsOnBlur && (
                                <InlineError text="It looks like you are only signing up for one or two classes.  Please check that you don’t already have an account before creating a new account." />
                              )}

                            {errors.numberOfStudents && (
                              <InlineError text={errors.numberOfStudents} />
                            )}
                          </FormGroup>
                          {isSuper && (
                            <>
                              <FormGroup className="mb-4">
                                <Label htmlFor="crmAccountName">
                                  CRM Account Name
                                </Label>
                                <Input
                                  type="text"
                                  value={crmAccountName || ""}
                                  onChange={onChange}
                                  name="crmAccountName"
                                  id="crmAccountName"
                                />
                              </FormGroup>
                              <FormGroup className="mb-4">
                                <Label htmlFor="crmStatus">CRM Status</Label>
                                <Input
                                  type="text"
                                  value={crmStatus || ""}
                                  onChange={onChange}
                                  name="crmStatus"
                                  id="crmStatus"
                                />
                              </FormGroup>
                              <FormGroup className="mb-4">
                                <Label htmlFor="managers">Managers</Label>
                                <Select
                                  id="managers"
                                  className="c-input__multiselect"
                                  placeholder="Select managers"
                                  options={availableManagers}
                                  value={managers}
                                  onChange={onManagerChange}
                                  closeMenuOnSelect={false}
                                  isMulti
                                  styles={defaultSelectStyles}
                                />
                              </FormGroup>
                              <FormGroup className="mb-4">
                                <Label htmlFor="accountType">
                                  Account Type
                                </Label>
                                {this.renderAccountTypeInput(accountType)}
                              </FormGroup>
                              <FormGroup className="mb-4">
                                <Label htmlFor="accountName">
                                  Account Name
                                </Label>
                                <Input
                                  type="text"
                                  value={accountName || ""}
                                  onChange={onChange}
                                  name="accountName"
                                  id="accountName"
                                />
                              </FormGroup>
                            </>
                          )}
                        </Form>
                      </Col>
                    </div>
                  </div>
                </Row>
                {!edit && (
                  <Row className="d-flex align-items-center justify-content-between pb-4 pt-0">
                    <div className="w-100" style={styles.container}>
                      <div className="pl-5">
                        <Col>
                          <h1 className="mb-4">Your contact details</h1>
                          <Form>
                            <FormGroup className="mb-4">
                              <Label htmlFor="contactFirstName">
                                Contact name
                              </Label>
                              <Row>
                                <Col md={{ size: 6 }} className="pr-0">
                                  <Input
                                    type="text"
                                    value={contactFirstName || ""}
                                    onChange={onChange}
                                    name="contactFirstName"
                                    id="contactFirstName"
                                    placeholder="First name"
                                  />
                                  {errors.contactFirstName && (
                                    <InlineError
                                      text={errors.contactFirstName}
                                    />
                                  )}
                                </Col>
                                <Col md={{ size: 6 }}>
                                  <Input
                                    type="text"
                                    value={contactLastName || ""}
                                    onChange={onChange}
                                    name="contactLastName"
                                    id="contactLastName"
                                    placeholder="Last name"
                                  />
                                  {errors.contactLastName && (
                                    <InlineError
                                      text={errors.contactLastName}
                                    />
                                  )}
                                </Col>
                              </Row>
                            </FormGroup>
                            <FormGroup>
                              <Label htmlFor="contactPositionTitle">
                                Position Title
                              </Label>
                              <Input
                                type="text"
                                value={contactPositionTitle || ""}
                                onChange={onChange}
                                onFocus={changeFocusOfPositionTitle}
                                onBlur={changeFocusOfPositionTitle}
                                name="contactPositionTitle"
                                id="contactPositionTitle"
                                placeholder="Position Title"
                              />

                              {stringContainsWord(
                                contactPositionTitle,
                                "teacher"
                              ) &&
                                positionTitleOnBlur && (
                                  <InlineError text="If you are a teacher at a school, please check with administration that you don’t already have an account before creating a new account." />
                                )}

                              {errors.contactPositionTitle && (
                                <InlineError
                                  text={errors.contactPositionTitle}
                                />
                              )}
                            </FormGroup>
                            <FormGroup className="mb-4">
                              <Label htmlFor="contactEmail">
                                Email address
                              </Label>
                              <Input
                                type="email"
                                value={contactEmail || ""}
                                onChange={onChange}
                                name="contactEmail"
                                id="contactEmail"
                                placeholder="Email address"
                              />
                              {errors.contactEmail && (
                                <InlineError text={errors.contactEmail} />
                              )}
                            </FormGroup>
                            <FormGroup className="mb-4">
                              <Label htmlFor="referrer">
                                Subscription Code or name of referring school
                                (optional)
                              </Label>
                              <Input
                                type="text"
                                value={referrer || ""}
                                onChange={onChange}
                                name="referrer"
                                id="referrer"
                                placeholder="Subscription Code or name of school already using Class Solver"
                              />
                              {errors.referrer && (
                                <InlineError text={errors.referrer} />
                              )}
                            </FormGroup>
                          </Form>
                        </Col>
                      </div>
                    </div>
                  </Row>
                )}
              </div>
            )
          }}
        </Query>
      </div>
    )
  }
}
