import { useCallback } from "react"
import { useQuery, useMutation } from "@apollo/client"
import { gql } from "@apollo/client"

import { characteristicsQuery } from "domains/characteristics/graphql"

import { getSchoolId } from "util/app"

export const BULK_UPLOAD_JOB = gql`
  query bulkUploadJob($jobId: ID!) {
    bulkUploadJob(jobId: $jobId) {
      id
      meta
      type
      status
      failureCount
      updatedAt
      createdAt
      uploadedFile {
        csvData {
          canonicalHeaders
          headers
          rows
        }
      }
      fileSummary {
        ... on StudentCharacteristicUploadSummary {
          headers
          sourceResponseLabels {
            label
            studentCount
          }
          subjectCharacteristic {
            id
            name
            characteristicResponses {
              id
              label
            }
          }
        }
        ... on StudentCommentsUploadSummary {
          headers
        }
        ... on UploadSolutionUploadSummary {
          headers
        }
      }
    }
  }
`

export const UPDATE_BULK_UPLOAD_JOB = gql`
  mutation updateBulkUploadJob($jobParams: JobParams!) {
    updateBulkUploadJob(jobParams: $jobParams) {
      id
      meta
      type
      status
      failureCount
      updatedAt
      createdAt
      fileSummary {
        ... on StudentCharacteristicUploadSummary {
          headers
          sourceResponseLabels {
            label
            studentCount
          }
          subjectCharacteristic {
            id
            name
            characteristicResponses {
              id
              label
            }
          }
        }
        ... on StudentCommentsUploadSummary {
          headers
        }
      }
    }
  }
`

export const IMPORT_BULK_UPLOAD_JOB = gql`
  mutation importFileFromJobWithMeta($jobId: ID!) {
    importFileFromJobWithMeta(jobId: $jobId) {
      id
      status
      type
    }
  }
`

export const useBulkUploadJob = jobId => {
  // Retrieve the upload job so we can pass it on through
  const schoolId = getSchoolId()

  const query = useQuery(BULK_UPLOAD_JOB, {
    variables: {
      jobId: jobId,
    },
  })

  // Prepare a mutation allowing the caller to update the upload job
  const [mutate, status] = useMutation(UPDATE_BULK_UPLOAD_JOB)

  // Prepare a mutation allowing caller to import an uploaded job
  const [importMutation, importStatus] = useMutation(IMPORT_BULK_UPLOAD_JOB)

  // Wrap the mutator so it can be called without needing to pass in the
  // job id again
  const updateBulkUploadJob = useCallback(
    jobParams => {
      return mutate({
        variables: {
          jobParams: {
            id: jobId,
            ...jobParams,
          },
        },
      })
    },
    [mutate, jobId]
  )

  // Wrap the mutator so it can be called without needing to pass in the
  // job id again
  const importBulkUploadJob = useCallback(() => {
    return importMutation({
      variables: { jobId },
      refetchQueries: [
        {
          query: characteristicsQuery,
          variables: { schoolId },
        },
      ],
    })
  }, [importMutation, jobId, schoolId])

  // Create a helper to cancel the job by updating its status to CANCELLED
  const cancelUploadJob = useCallback(() => {
    return updateBulkUploadJob({ status: "CANCELLED" })
  }, [updateBulkUploadJob])

  // Return the query result, status and mutator and mutation status as tuples
  // in an object
  return {
    fetch: [query.data && query.data.bulkUploadJob, query],
    update: [updateBulkUploadJob, status],
    cancel: [cancelUploadJob, status],
    import: [importBulkUploadJob, importStatus],
  }
}
