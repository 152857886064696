import React, { useState } from "react"

import { Modal, Button, ModalBody, ModalFooter } from "reactstrap"

import { ModalHeader } from "components"

const MoreInfoModal = ({ toggle }) => {
  return (
    <Modal isOpen className="modal-dialog-centered u-modal__w-30rem">
      <ModalHeader
        title="Account Rolled Over"
        toggle={toggle}
        className="p-3"
      />

      <ModalBody className="pb-0 d-flex flex-column align-items-center">
        <div className="d-flex justify-content-between mr-2">
          <i className="fa fa-info-circle u-caution-icon mr-3" />
          <div>
            <h2>Why do I need to re-import lists?</h2>
            <p>
              It avoids you having to reconcile who has left or joined the
              school since you last used Class Solver.
            </p>
            <h2>Will information from last year be retained?</h2>
            <p>
              Yes - absolutely. All your Requests, Friendship Preferences,
              Characteristic responses and Notes will be automatically merged
              into the program when you import the new student list. You just
              need to make sure you use the same Student ID as last year.
            </p>
          </div>
        </div>
      </ModalBody>
      <ModalFooter>
        <Button color="link" onClick={toggle}>
          Close
        </Button>
      </ModalFooter>
    </Modal>
  )
}

export const RolledOverNote = () => {
  const [showMoreInfo, setShowMoreInfo] = useState(false)

  const toggle = () => setShowMoreInfo(prevState => !prevState)

  return (
    <>
      <div className="c-modal-note text-center">
        Your account has been rolled over. Please re-import a new list of your
        current students using the template below.{" "}
        <span className="cursor-pointer text-primary" onClick={toggle}>
          Learn more
        </span>
      </div>
      {showMoreInfo && <MoreInfoModal toggle={toggle} />}
    </>
  )
}
