import { getSchoolId } from "util/app"
import { detectBrowser } from "util/featureDetectionUtil"

import { useState } from "react"
import { useQuery } from "@apollo/client"
import { sendAdminNotificationMutation } from "domains/notifications/graphql"
import { userProfileQuery } from "domains/auth/graphql"
import { schoolQuery } from "domains/accountSettings/graphql"

import { Loader } from "components"

import Logo from "images/logo.png"

import { useMutation } from "@apollo/client"

import { Input, FormGroup, Button } from "reactstrap"
import { useNavigate, useRouteError, useLocation } from "react-router-dom"

const constructMessage = (
  profileData,
  schoolData,
  error,
  currentPath,
  message
) => {
  const browser = detectBrowser()

  const AccountInformation = profileData
    ? `Account Information:
            School Name: ${schoolData.school.name}
            School Id: ${schoolData.school.id}
            Account Type: ${schoolData.school.accountType}
            Address: ${schoolData.school.address}
            Administration System: ${schoolData.school.administrationSystem}
            Contact Number: ${schoolData.school.contactNumber}
            Crm Status: ${schoolData.school.crmStatus}`
    : ""

  const UserMessage = schoolData
    ? `User Information:
            User email: ${profileData.myprofile.email}
            User first name: ${profileData.myprofile.firstName}
            User last name: ${profileData.myprofile.lastName}
            User id: ${profileData.myprofile.id}
            User role: ${profileData.myprofile.role}`
    : ""

  return `
        ${AccountInformation}

        ${UserMessage}

        User message:
            ${message}

        Error message:
            ${error.message}

        Error stack trace:
            ${error.stack}

        Current Path:
            ${currentPath}

        Browser detection:
            Opera: ${browser.isOpera}
            Firefox: ${browser.isFirefox}
            Safari: ${browser.isSafari}
            Internet Explorer: ${browser.isIE}
            Edge: ${browser.isEdge}
            Chrome: ${browser.isChrome}
            Edge Chromium: ${browser.isEdgeChromium}
            Blink engine: ${browser.isBlink}
        `
}

export const ErrorBoundary = () => {
  const [message, setMessage] = useState("")
  const [sendNotification, { loading: sendingNotification }] = useMutation(
    sendAdminNotificationMutation,
    {
      onError: () => {},
      onCompleted: () => {
        setMessage("")
      },
    }
  )

  const { pathname: currentPath } = useLocation()

  const {
    data: profileData,
    loading: loadingProfile,
    error: profileDataError,
  } = useQuery(userProfileQuery)

  const {
    data: schoolData,
    loading: loadingSchool,
    error: schoolDataError,
  } = useQuery(schoolQuery, {
    variables: { schoolId: getSchoolId() },
    fetchPolicy: "network-only",
    skip: loadingProfile || !getSchoolId(),
  })

  const error = useRouteError()

  const navigate = useNavigate()

  if (loadingProfile || loadingSchool) {
    return <Loader />
  }

  const uploadMessage = constructMessage(
    profileData,
    schoolData,
    error,
    currentPath,
    message
  )

  const variables = {
    notificationType: "FRONTEND_ERROR",
    message: uploadMessage,
    schoolId: getSchoolId(),
  }

  return (
    <div>
      <img
        className="my-4"
        style={{ maxWidth: 150 }}
        src={Logo}
        alt="Class Solver Logo"
      />
      <div className="text-center">
        <div className="m-4">Sorry - we have encountered an issue</div>
        <h1 className="m-4">Please refresh your browser and try again</h1>

        {!(profileDataError || schoolDataError) && (
          <>
            <div className="m-4">
              If the issue continues, please provide a brief description of what
              happened and click on "Send to Support"
            </div>

            <FormGroup className="flex justify-center d-flex flex-column align-items-center">
              <div className="w-40rem text-center">
                <div className="text-left">Message (Optional)</div>
                <Input
                  type="textarea"
                  className=""
                  name="message"
                  value={message}
                  rows={5}
                  onChange={e => {
                    setMessage(e.target.value)
                  }}
                />
              </div>
              <div className="container w-lg-75">
                <div className="mt-3 d-flex flex-column align-items-center">
                  <Button
                    color="primary"
                    className="mt-2 w-15rem"
                    disabled={sendingNotification}
                    onClick={() => {
                      sendNotification({ variables })
                      navigate(`/`)
                    }}>
                    Send to Support
                  </Button>
                  <span
                    className="mt-4 w-15rem text-primary cursor-pointer"
                    disabled={sendingNotification}
                    onClick={() => navigate(`/`)}>
                    Cancel
                  </span>
                </div>
              </div>
            </FormGroup>
          </>
        )}
      </div>
    </div>
  )
}
