import * as React from "react"
import { Tooltip } from "react-tooltip"
import { Button } from "reactstrap"
import { ButtonBar } from "components"
import { MapRow } from "components"
import { HelpModal } from "domains/upload"
import { UploadErrorModal } from "domains/upload"

export const MapColumnHeadings = props => {
  const {
    importType,
    handleToggleHelpModal,
    showHelpModal,
    uploadJob,
    requiredColumns,
    optionalColumns,
    mappedColumns,
    options,
    handleColumnMapped,
    handleColumnClear,
    isMappingComplete,
    isUpdatingJob,
    isImportingJob,
    handleContinue,
    handleCancel,
    uploadError,
    errorType,
    setUploadError,
    knownWarnings,
    defaultWarning,
    errorModalActions,
  } = props

  return (
    <div className="container">
      <div className="mt-5 d-flex flex-row align-items-center">
        <h1 className="mb-0">Map Your Column Headings</h1>

        <Button
          className="u-font-weight-medium"
          color="link"
          onClick={handleToggleHelpModal}>
          Need Help Importing?
        </Button>

        <HelpModal
          isOpen={showHelpModal}
          toggle={handleToggleHelpModal}
          jobId={uploadJob.id}
          onSentNotification={handleToggleHelpModal}
          type={importType}
        />
      </div>

      <div className="pt-3 pb-4">
        Please map the required fields to the columns found in your file:
      </div>

      <MapColumnsRows
        requiredColumns={requiredColumns}
        optionalColumns={optionalColumns}
        mappedColumns={mappedColumns}
        options={options}
        handleColumnMapped={handleColumnMapped}
        handleColumnClear={handleColumnClear}
        firstHeading={"Optional fields for Class Solver"}
        optional={false}
      />

      <div className="u-content-border-bottom">&nbsp;</div>

      <ButtonBar
        buttonText="Save & Continue"
        cancelText="Cancel Import"
        className="p-3"
        disabled={!isMappingComplete || isUpdatingJob || isImportingJob}
        onButtonClick={handleContinue}
        onCancelClick={handleCancel}
      />

      {uploadError && (
        <UploadErrorModal
          isOpen
          title={(knownWarnings[uploadError.message] || defaultWarning).title}
          type={errorType}
          jobId={uploadJob && uploadJob.id}
          toggle={() => setUploadError()}
          actions={errorModalActions}>
          {(knownWarnings[uploadError.message] || defaultWarning).body(
            uploadError
          )}
        </UploadErrorModal>
      )}
    </div>
  )
}

export const MapColumnsRows = props => {
  // Destructure the two props we want
  const {
    requiredColumns = [],
    optionalColumns = [],
    mappedColumns,
    options,
    handleColumnMapped,
    handleColumnClear,
    firstHeading,
    firstHeadingTooltipText,
  } = props

  return (
    <div className="container">
      <div className="row pb-2 mb-3 u-content-border-bottom">
        <div className="col-lg font-weight-bold">
          {firstHeading}
          {firstHeadingTooltipText && (
            <>
              <i
                style={{ color: "#00000080" }}
                className="fa fa-info-circle ml-2"
                data-tooltip-id={firstHeadingTooltipText}
              />
              <Tooltip
                id={firstHeadingTooltipText}
                opacity={1}
                variant="light"
                // border colour is equivalent to $color-grey-class-header
                border="1px solid #dce1e8">
                {firstHeadingTooltipText}
              </Tooltip>
            </>
          )}
        </div>

        <div className="col-sm font-weight-bold">Columns in your file</div>
      </div>

      <RenderColumns
        columns={requiredColumns}
        mappedColumns={mappedColumns}
        handleColumnMapped={handleColumnMapped}
        handleColumnClear={handleColumnClear}
        options={options}
        optional={false}
      />

      <RenderColumns
        columns={optionalColumns}
        mappedColumns={mappedColumns}
        handleColumnMapped={handleColumnMapped}
        handleColumnClear={handleColumnClear}
        options={options}
        optional={true}
      />
    </div>
  )
}

const RenderColumns = props => {
  const {
    columns,
    mappedColumns,
    handleColumnMapped,
    handleColumnClear,
    options,
    optional,
  } = props

  return Object.keys(columns).map(column => {
    const columnData = columns[column]

    return (
      <MapRow
        key={column}
        column={column}
        columnData={columnData}
        mappedColumns={mappedColumns}
        options={options}
        handleColumnMapped={handleColumnMapped}
        handleColumnClear={handleColumnClear}
        optional={optional}
      />
    )
  })
}
