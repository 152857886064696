import React from "react"
import { useQuery } from "@apollo/client"
import { DragDropContext } from "react-beautiful-dnd"
import { newClassesQuery } from "domains/classes/graphql"
import {
  studentsQuery,
  selectedStudentsQuery,
  studentQuery,
} from "domains/students/graphql"
import { getApiBaseUri } from "util/apiUtil"
import { downloadAuthenticatedFile } from "util/downloadUtil"

import { Loader, CautionModal, LoadingModal, VideoModal } from "components"
import {
  ColdStartView,
  SolverClass,
  SideBar,
  ShareSolutionModal,
  PrintableSolver,
  RunningSolveView,
  RunningSolverPrompt,
  UnallocatedStudentsNote,
} from "domains/solver/components"

import {
  getAssignmentsQuery,
  getFavouritedAssignmentsQuery,
} from "domains/solver/graphql"

import { requestQuery } from "domains/requests/graphql"

import { handleSessionExpired, getSchoolId } from "util/app"
import {
  getMetricDifferences,
  getBoostedMetricDifferences,
  getBoostedCharacteristicMetricDifference,
  getStudentMetricDifferences,
  move,
  getBoostedStudentId,
  cloneAndSortData,
  sortClassesForActiveGrade,
  hasAsyncSolveInProgress,
  hasClassEntryCriteria,
} from "util/solverUtil"
import { sortSolverStudents, immutableSortByName } from "util/sortUtil"
import { getPropertyIfDefined, isDefinedNotNull } from "util/objUtil"
import { SHOW_SOLVER_MODAL } from "constants/storageTokens"
import { PUSH, CLEAR, UNDO } from "constants/resolverTypes"

import { NO_SOLUTION_GENERATED } from "domains/solver/errorFields"
import { AUTH_TOKEN } from "constants/storageTokens"

import { Auth0Context } from "domains/auth/auth0Wrapper"

import { INSTRUCTION_STEP_3_PLAYER_URL } from "constants/videos"

import { getNotificationsQuery } from "domains/notifications/graphql"

import { NORMAL, SHARED_READ_ONLY } from "domains/solver/constants"

import { isPrivilegedRole } from "util/userUtil"
import { AccountTypeContext } from "config/accountTypeContext"
import { useLocation } from "react-router-dom"

const showSensitiveData = settings => {
  return settings ? settings.adminOnlyRequests : false
}

const DEFAULT_SOLVER_DATA = {
  id: null,
  classes: [],
  lockedStudentIds: [],
  __typename: "",
  suggestions: [],
}

export const classesHaveMax = classes => {
  return (
    classes &&
    classes.some(
      ({ classDetails }) =>
        classDetails.maxClassSize || classDetails.maxSizePerGrade.length > 0
    )
  )
}

const studentCountForClasses = classes => {
  if (classes) {
    return classes.reduce((a, klass) => a + klass.counts.total, 0)
  }
}

const gradesFromClasses = classes => {
  if (classes) {
    const allGrades = classes.reduce((a, klass) => {
      const grades = klass.classDetails.schoolGrades

      return a.concat(grades)
    }, [])

    const uniqueGrades = [...new Set(allGrades)]

    return uniqueGrades
  }
}

const maxTeacherPerClass = classes => {
  if (classes) {
    return classes.reduce(
      (a, klass) => (a < klass.teachers.length ? klass.teachers.length : a),
      0
    )
  }
}

export class SolverPre extends React.Component {
  constructor(props) {
    super(props)
    let data = this.props.data
    // Sorts the students when mounted
    // Don't do anything to data if it's a SolutionStatus

    if (isDefinedNotNull(data) && !hasAsyncSolveInProgress(data)) {
      if (
        isDefinedNotNull(
          getPropertyIfDefined(this.props, "data.activeGrade")
        ) &&
        isDefinedNotNull(getPropertyIfDefined(this.props, "data.classes"))
      ) {
        data = cloneAndSortData(data)
      } else {
        data = {
          ...data,
          classes: data.classes.map(klass => ({
            ...klass,
            students: immutableSortByName(klass.students),
          })),
        }
      }
    }

    const queryTeachersAssigned = data => {
      if (data && data.warnings && data.warnings.classesWithoutTeachers) {
        return data.warnings.classesWithoutTeachers
      } else {
        return false
      }
    }

    this.state = {
      loading: false,
      loadingModal: false,
      loadingMessage: "",
      showLoadingOverlay: false,
      errors: {},
      panelOpen: true,
      teacherPanelIsOpen: false,
      boostModal: false,
      data,
      coldStartModal: false,
      highlightStudents: [],
      updateClasses: false,
      activeCharacteristicId: "",
      shareSolutionModal: false,
      selectedStudentId: "",
      runningSolverPrompt: false,
      unassignedTeacherPrompt: queryTeachersAssigned(data),
      showStep3Video: false,
      draggedStudent: null,
      studentsSelectedForDragging: [],
      sortByCharacteristicResponses: false,
      classDataPreview: null,
      classesToShow: [],
    }
  }

  toggleTeacherPanel = () => {
    this.setState({ teacherPanelIsOpen: !this.state.teacherPanelIsOpen })
  }

  toggleCharacteristicResponseSort = () => {
    this.setState(prevState => ({
      sortByCharacteristicResponses: !prevState.sortByCharacteristicResponses,
    }))
  }

  componentDidUpdate = prevProps => {
    if (this.props.selectedTeacherId !== prevProps.selectedTeacherId) {
      this.setState({ selectedStudentId: "" })
    }
  }

  static contextType = Auth0Context

  toggleSolverModal = () => {
    sessionStorage.setItem(SHOW_SOLVER_MODAL, "true")
    this.setState({})
  }

  toggleRunningSolverPrompt = () => {
    this.setState(prevState => ({
      runningSolverPrompt: !prevState.runningSolverPrompt,
    }))
  }

  toggleUnassignedTeacherPrompt = () => {
    this.setState(prevState => ({
      unassignedTeacherPrompt: !prevState.unassignedTeacherPrompt,
    }))
  }

  toggle = key => () => this.setState(prevState => ({ [key]: !prevState[key] }))

  // TODO: create standard pattern for modals including hidden, isOpen, data, functions

  clearSelectedStudent = () => {
    this.props.updateSelectedStudent({
      variables: {
        id: "",
        firstName: "",
        lastName: "",
        friends: [],
        teacherConstraints: [],
        studentConstraints: [],
      },
    })
    // also clear the highlighted students and boost difference object as well as any errors
    this.setState({
      errors: {},
      metricDifferences: {},
      highlightStudents: [],
      selectedStudentId: "",
    })
  }

  clearStudentsToBeDragged = () => {
    this.setState({
      studentsSelectedForDragging: [],
    })
  }

  onActiveGradeChange = value => {
    const { updateSolverUndoRedo, updateSolverActiveGrade } = this.props
    // clear the student
    this.clearSelectedStudent()
    this.clearStudentsToBeDragged()
    // clear any undo/redo moves that are stored
    updateSolverUndoRedo({ variables: { type: CLEAR } })
    // update grade
    updateSolverActiveGrade({
      variables: { activeGrade: parseInt(value, 10) },
    })
  }

  onActiveCharacteristicChange = e => {
    this.setState({
      activeCharacteristicId: e.target.value,
      metricDifferences: {},
      sortByCharacteristicResponses: false,
    })
  }

  getClass = id => {
    return this.state.data.classes.find(c => c.classDetails.id === id)
  }

  getClassByStudentId = id => {
    // Go through each student list to find student
    return this.state.data.classes.find(c => {
      if (c.students.length > 0) {
        return c.students.find(s => {
          if (s.id === id) {
            return true
          }
          return false
        })
      }
      return false
    })
  }

  onErrorMove = error => {
    handleSessionExpired(error)
    // Undo move in state
    this.setState({
      updateClasses: true,
      showLoadingOverlay: false,
      data: cloneAndSortData(this.props.data),
      highlightStudents: [],
      errors: { move: "Failed to move student" },
    })
  }

  moveStudents = (studentIds, fromClasses, toClass) => {
    const { classes, activeGrade } = this.state.data

    // This will be the array of students for the destination class. It will get updated as the list of classes in fromClasses is looped over.
    let destinationArray

    // This is an object which will have an key value pair of class id and student array respectively.
    // A new entry will be added for each class as fromClasses is looped over.
    const objectOfSourceArrays = {}

    for (const fromClass of fromClasses) {
      if (!destinationArray) {
        destinationArray = toClass.students
      }

      const studentIndexes = studentIds.reduce((acc, studentId) => {
        const studentIndex = fromClass.students.findIndex(
          student => student.id === studentId
        )
        // This logic is here because there could be a studentId which corresponds to a student NOT in fromClass.
        if (studentIndex !== -1) {
          return [...acc, studentIndex]
        } else {
          return acc
        }
      }, [])

      const result = move(fromClass.students, destinationArray, studentIndexes)

      destinationArray = result.destClone
      objectOfSourceArrays[fromClass.classDetails.id] = result.sourceClone
    }

    // With the data we need in objectOfSourceArrays and destinationArray we can now create newClasses
    const newClasses = classes.map(c => {
      const fromClass = fromClasses.find(
        fc => c.classDetails.id === fc.classDetails.id
      )
      if (fromClass) {
        return {
          ...fromClass,
          students: objectOfSourceArrays[fromClass.classDetails.id],
        }
      }
      if (c.classDetails.id === toClass.classDetails.id) {
        // sort the students
        sortSolverStudents(activeGrade.id.toString(), destinationArray)
        // return the new class
        return {
          ...toClass,
          students: destinationArray,
        }
      }
      // if neither source or destination, do not change
      return c
    })

    // Move Student and highlight
    this.setState({
      data: {
        ...this.state.data,
        classes: newClasses,
      },
      highlightStudents: [...this.state.highlightStudents, ...studentIds],

      metricDifferences: {},
    })
  }

  onDragStart = ({ draggableId }) => {
    const draggedStudent = this.state.data.classes
      .flatMap(({ students }) => students)
      .find(({ id }) => id === draggableId)

    this.setState({ draggedStudent })

    // Removes the selected student when a drag starts
    this.clearSelectedStudent()
  }

  onDragEnd = result => {
    const {
      moveStudentMutation,
      updateSolverUndoRedo,
      schoolSettings,
      settings,
    } = this.props
    const { activeCharacteristicId, studentsSelectedForDragging } = this.state
    const { id, activeGrade } = this.state.data
    const { source, destination, draggableId } = result
    const schoolId = getSchoolId()
    const topAssignment = this.props.assignmentsData[0]

    this.setState({
      isDropping: true,
      draggedStudent: null,
    })

    // dropped outside the list
    if (!destination) {
      return
    }

    // if dropping to same table
    if (source.droppableId === destination.droppableId) {
    } else {
      // show loading
      this.setState({
        showLoadingOverlay: true,
      })

      // get the classes affected by move
      const sourceClass = this.getClass(source.droppableId)

      // if there are no students selected using shift/only one student dragged the standard way, then we just pass in
      // sourceClass. Otherwise we loop through the students and get accumulate the classes they belong to.
      const sourceClasses =
        studentsSelectedForDragging.length === 0
          ? [sourceClass]
          : studentsSelectedForDragging.reduce((acc, student) => {
              const classOfStudent = this.getClassByStudentId(student.id)
              if (
                acc.find(c => c.classDetails === classOfStudent.classDetails)
              ) {
                return acc
              } else {
                return [...acc, classOfStudent]
              }
            }, [])

      const destinationClass = this.getClass(destination.droppableId)
      if (!sourceClasses || !destinationClass) {
        // todo: do something, like show an error message
        return
      }

      const studentToAndFromData =
        studentsSelectedForDragging.length > 0
          ? studentsSelectedForDragging.map(student => {
              const sourceClass = this.getClassByStudentId(student.id)
              return {
                fromClassId: sourceClass.classDetails.id,
                studentId: student.id,
                toClassId: destinationClass.classDetails.id,
              }
            })
          : [
              {
                fromClassId:
                  this.getClassByStudentId(draggableId).classDetails.id,
                studentId: draggableId,
                toClassId: destinationClass.classDetails.id,
              },
            ]

      const moveStudentData = studentToAndFromData.map(student => {
        return {
          studentId: student.studentId,
          newClassId: student.toClassId,
        }
      })

      // build variables for mutation
      const moveStudentParamsList = {
        moveStudents: moveStudentData,
        schoolId,
        solutionId: id,
        activeGradeId: activeGrade.id,
      }

      const idsForRefetchingStudents = moveStudentData.map(
        student => student.studentId
      )

      // refetch the student data
      const refetchQueries = [
        {
          query: studentQuery,
          variables: {
            id: draggableId,
            adminOnly: showSensitiveData(settings),
          },
        },
        {
          query: selectedStudentsQuery,
          variables: {
            schoolId: schoolId,
            ids: idsForRefetchingStudents,
            adminOnly: showSensitiveData(settings),
          },
        },
        {
          query: requestQuery,
          variables: { adminOnly: showSensitiveData(settings), schoolId },
        },
        {
          query: getAssignmentsQuery,
          variables: { gradeId: activeGrade.id },
        },
      ]

      // Move student on the frontend
      this.moveStudents(
        idsForRefetchingStudents,
        sourceClasses,
        destinationClass
      )

      // Fire the move student mutation
      moveStudentMutation({
        variables: {
          moveStudentParamsList,
          adminOnly: showSensitiveData(settings),
        },
        refetchQueries,
      })
        .then(data => {
          // set the new solution data
          this.setState({
            showLoadingOverlay: false,
            data: cloneAndSortData(data.data.moveStudent.solution),
            metricDifferences: getMetricDifferences(
              this.state.data.metrics,
              data.data.moveStudent.solution.metrics,
              schoolSettings.maxFriends > 0,
              schoolSettings.avoidSameTeacher,
              activeCharacteristicId
            ),
            errors: { lock: null, move: null },
          })

          const assignmentObject = {
            toAssignment: {
              id: topAssignment.id,
              movedStudents: topAssignment.movedStudents,
            },
            fromAssignment: {
              id: data.data.moveStudent.solution.assignment.id,
              movedStudents:
                data.data.moveStudent.solution.assignment.movedStudents,
            },
          }

          updateSolverUndoRedo({
            variables: { type: PUSH, assignmentObject },
          })
        })
        .catch(error => {
          this.onErrorMove(error)
        })
    }
  }

  onLockStudent = (e, studentId, lockState) => {
    e.stopPropagation()
    const { lockStudentMutation, refetchQueries } = this.props
    const { data } = this.state

    const lockParams = {
      studentId: studentId,
      lockState: lockState,
      solutionId: data.id,
    }

    // call mutation
    lockStudentMutation({
      variables: { lockParams },
      refetchQueries,
    })
      .then(() => {
        this.updateLockedStudentIdsOptimization([studentId], lockState)
      })
      .catch(error => {
        handleSessionExpired(error)
        // Undo lock in state
        this.setState({
          updateClasses: true,
          errors: { lock: "Unable to lock student" },
        })
      })
  }

  updateLockedStudentIdsOptimization = (studentIds, lockState) => {
    const data = this.state.data
    const lockedStudentIds = data.lockedStudentIds
    const studentIdIntegers = studentIds.map(id => parseInt(id, 10))

    let newLockedStudentIds = []
    if (lockState) {
      newLockedStudentIds = [...lockedStudentIds, ...studentIdIntegers]
    } else {
      newLockedStudentIds = lockedStudentIds.filter(
        id => !studentIdIntegers.includes(id)
      )
    }

    this.setState({
      data: {
        ...data,
        lockedStudentIds: Array.from(new Set(newLockedStudentIds)),
      },
    })
  }
  // this refetches the solver page data and updates internal state
  solverRefetch = () => {
    const { refetch, refetchAssignments, updateSolverUndoRedo } = this.props

    this.setState({
      loading: true,
    })
    refetch()
      .then(data => {
        if (data.data.solution.__typename === "SolutionStatus") {
          // Solver job is still running
          this.setState({
            loading: false,
          })
        } else {
          const solution = data.data.solution
          this.setState({
            data: cloneAndSortData(solution),
            loading: false,
            unassignedTeacherPrompt:
              solution.warnings && solution.warnings.classesWithoutTeachers,
          })
        }
      })
      .catch(error => {
        const errors = error.message.includes(NO_SOLUTION_GENERATED.key)
          ? { solverRun: NO_SOLUTION_GENERATED.message }
          : { solverRun: "Network Error" }

        this.setState({
          data: DEFAULT_SOLVER_DATA,
          loading: false,
          errors,
        })
      })

    refetchAssignments().catch(() => {
      // If something goes wrong clear out undo/redo so that it doesn't get out of sync and confuse user
      updateSolverUndoRedo({ variables: { type: CLEAR } })
    })
  }

  onRunSolver = () => {
    const { updateLastAssignment, assignmentsData } = this.props

    // toggle loading and clear highlighted students
    this.setState({
      loadingModal: true,
      loadingMessage: "Running Solver, please wait...",
      errors: {},
      highlightStudents: [],
      metricDifferences: {},
    })
    // clear any selected student
    this.clearSelectedStudent()
    this.clearStudentsToBeDragged()

    const topAssignmentId =
      assignmentsData && assignmentsData[0] && assignmentsData[0].id

    // When there has been no solution topAssignmentId will be undefined causing updateLastAssignment to blow up.
    if (topAssignmentId) {
      updateLastAssignment({
        variables: {
          lastAssignmentCached: topAssignmentId,
        },
      })
    }
  }

  onSuccessfulSolver = data => {
    const solveInProgress = hasAsyncSolveInProgress(data)

    this.setState({
      loadingModal: false,
      loadingMessage: "",
      runningSolverPrompt: solveInProgress,
      data: solveInProgress ? data : cloneAndSortData(data),
      highlightStudents: [],
    })
  }

  onErrorSolver = error => {
    handleSessionExpired(error)

    if (error.message && error.message.includes(NO_SOLUTION_GENERATED.key)) {
      this.setState({
        loadingModal: false,
        loadingMessage: "",
        errors: {
          solverRun: NO_SOLUTION_GENERATED.message,
        },
      })
    } else {
      // stop loading and update classes to what is returned from query
      this.setState({
        loadingModal: false,
        loadingMessage: "",
        updateClasses: true,
        errors: { solverRun: "Network Error" },
      })
    }
  }

  solveActiveGrade = () => {
    const { solveActiveGradeMutation, activeGradeId, settings } = this.props
    const schoolId = getSchoolId()

    let variables = {
      solution: { schoolId, gradeId: activeGradeId },
      adminOnly: showSensitiveData(settings),
    }

    // refetch students to update new grades
    const refetchQueries = [
      {
        query: newClassesQuery,
        variables: { schoolId },
      },
      {
        query: studentsQuery,
        variables: { schoolId },
      },
      {
        query: getNotificationsQuery,
        variables: { schoolId },
      },
      {
        query: getAssignmentsQuery,
        variables: { gradeId: activeGradeId },
      },
    ]

    this.onRunSolver()

    solveActiveGradeMutation({ variables, refetchQueries })
      .then(data => {
        this.onSuccessfulSolver(data.data.runSolveActiveGrade)
      })
      .catch(error => {
        this.onErrorSolver(error)
      })
  }

  pickAnAssignment = (assignmentId, activeGradeId) => {
    const { pickAnAssignmentMutation, updateSolverUndoRedo } = this.props

    const schoolId = getSchoolId()

    const refetchQueries = [
      {
        query: getNotificationsQuery,
        variables: { schoolId },
      },
      {
        query: getAssignmentsQuery,
        variables: { gradeId: activeGradeId },
      },
    ]

    let variables = {
      pickAssignmentParams: {
        assignmentId: assignmentId,
        solutionId: this.state.data.id,
      },
    }

    this.setState({ showLoadingOverlay: true })

    pickAnAssignmentMutation({ variables, refetchQueries }).then(
      assignmentData => {
        this.props
          .refetch()
          .then(data => {
            const dataToClone = data.data.solution
              ? data.data.solution
              : data.data.sharedSolution

            const clonedData = cloneAndSortData(dataToClone)

            const topAssignment = this.props.assignmentsData[0]

            const assignmentObject = {
              toAssignment: { id: topAssignment.id, movedStudents: [] },
              fromAssignment: {
                id: assignmentData.data.pickAnAssignment.assignment.id,
                movedStudents: [],
              },
            }

            updateSolverUndoRedo({
              variables: {
                type: PUSH,
                assignmentObject,
              },
            })

            this.setState({
              data: clonedData,
              highlightStudents: [],
            })
          })
          .finally(() => {
            this.setState({
              showLoadingOverlay: false,
            })
          })
      }
    )
  }

  // PREVIEW FEATURE: This is part of a beta feature which allows the user to preview an assignment without actually updating the solution
  onSuccessfulAssignment = (data, activeGradeId) => {
    if (
      data.data &&
      data.data.assignmentWithClasses &&
      data.data.assignmentWithClasses.classes
    ) {
      const classData = sortClassesForActiveGrade(
        data.data.assignmentWithClasses.classes,
        activeGradeId
      )
      this.setState({
        classDataPreview: classData,
      })
    }
  }

  // PREVIEW FEATURE: This is part of a beta feature which allows the user to preview an assignment without actually updating the solution
  assignmentWithClasses = assignmentId => {
    const { assignmentWithClassesMutation, activeGradeId, settings } =
      this.props

    let variables = {
      assignmentId,
      activeGradeId,

      adminOnly: showSensitiveData(settings),
    }

    const refetchQueries = []

    assignmentWithClassesMutation({ variables, refetchQueries }).then(data => {
      this.onSuccessfulAssignment(data, activeGradeId)
    })
  }

  undoRedoMoveStudent = (assignmentObject, type) => {
    const { pickAnAssignmentMutation, schoolSettings, settings } = this.props
    const { activeCharacteristicId } = this.state
    const { id, activeGrade } = this.state.data
    const schoolId = getSchoolId()
    const assignmentId = assignmentObject.fromAssignment
      ? assignmentObject.fromAssignment.id
      : null

    let refetchQueries = [
      {
        query: getAssignmentsQuery,
        variables: { gradeId: activeGrade.id },
      },
    ]

    this.setState({
      showLoadingOverlay: true,
    })

    refetchQueries.push({
      query: requestQuery,
      variables: { adminOnly: showSensitiveData(settings), schoolId },
    })

    const variables = {
      pickAssignmentParams: {
        assignmentId,
        solutionId: id,
        type: type === UNDO ? "UNDO" : "REDO",
      },
    }

    pickAnAssignmentMutation({ variables, refetchQueries }).then(() => {
      this.props
        .refetch()
        .then(data => {
          const solutionData = data.data.solution
            ? data.data.solution
            : data.data.sharedSolution

          const clonedData = cloneAndSortData(solutionData)

          this.setState({
            data: clonedData,
            metricDifferences: getMetricDifferences(
              this.state.data.metrics,
              clonedData.metrics,
              schoolSettings.maxFriends > 0,
              schoolSettings.avoidSameTeacher,
              activeCharacteristicId
            ),
          })
        })
        .finally(() => {
          const toAssignmentMovedStudents = assignmentObject.toAssignment
            ? assignmentObject.toAssignment.movedStudents
            : null

          const fromAssignmentMovedStudents = assignmentObject.fromAssignment
            ? assignmentObject.fromAssignment.movedStudents
            : null

          const moveStudents =
            type === UNDO
              ? toAssignmentMovedStudents
              : fromAssignmentMovedStudents

          const highlightStudents = moveStudents
            ? moveStudents.map(({ student }) => student.id)
            : []

          this.setState({
            showLoadingOverlay: false,
            highlightStudents,
          })
        })
    })
  }

  onUndoRedoClick = type => {
    const { updateSolverUndoRedo } = this.props
    // clear any selected student on solver
    this.clearSelectedStudent()
    this.clearStudentsToBeDragged()

    // build variables for mutaion
    const variables = {
      type,
    }
    updateSolverUndoRedo({ variables }).then(data => {
      this.undoRedoMoveStudent(
        data.data.updateSolverUndoRedo.assignmentObject,
        type
      )
    })
  }

  onRunBoost = clearStudent => {
    // clear any highlighted Students and start loading
    this.setState({
      loadingMessage: "Running Boost, please wait...",
      loadingModal: true,
      highlightStudents: [],
    })
    if (clearStudent) {
      this.clearSelectedStudent()
      this.clearStudentsToBeDragged()
    }
  }

  onSuccessfulBoost = boostData => {
    const { updateSolverUndoRedo, schoolSettings, assignmentsData } = this.props
    // stop loading and close loading modal
    this.setState({
      loadingMessage: "",
      loadingModal: false,
    })

    const topAssignment = assignmentsData[0]
    const newSolutionData = cloneAndSortData(boostData.solution)

    // Check that a boost actually occured
    if (isDefinedNotNull(boostData.new)) {
      this.setState({
        data: newSolutionData,
        highlightStudents: [
          getBoostedStudentId(boostData.new.student1),
          getBoostedStudentId(boostData.new.student2),
        ],
        metricDifferences: getBoostedMetricDifferences(
          boostData,
          schoolSettings.maxFriends > 0,
          schoolSettings.avoidSameTeacher
        ),
      })

      const toAssignment = topAssignment
        ? { id: topAssignment.id, movedStudents: topAssignment.movedStudents }
        : null
      const fromAssignment = boostData.solution.assignment
        ? {
            id: boostData.solution.assignment.id,
            movedStudents: boostData.solution.assignment.movedStudents,
          }
        : null

      // get the assignmentObject from the boosted data needed for undo/redo
      const assignmentObject = {
        toAssignment,
        fromAssignment,
      }

      updateSolverUndoRedo({
        variables: { type: PUSH, assignmentObject },
      })
    } else {
      // Open modal showing that cannot be boosted
      this.setState({
        data: newSolutionData,
        boostModal: true,
      })
    }
  }

  onBoostError = error => {
    handleSessionExpired(error)
    this.setState({
      loadingMessage: "",
      showLoadingOverlay: false,
      errors: {
        boost: "Network error running boost, please try again.",
      },
    })
  }

  repeatBoost = async (boost, times) => {
    for (const _ of Array(times)) {
      // If the boostModal is showing then it means there's no more boosts. Due to
      // the asynchronous nature of React setState, this is not foolproof so we may
      // end up doing an extra boost, which is not a massive issue at the moment
      if (this.state.boostModal) {
        break
      }

      await boost()
    }
  }

  maybeMultipleBoostClick = boostFunction => {
    const { user } = this.props

    return (e, args) => {
      if (user && isPrivilegedRole(user.role) && e.metaKey && e.shiftKey) {
        const boost = () => boostFunction(args)
        this.repeatBoost(boost, 10)
      } else {
        boostFunction(args)
      }
    }
  }

  onBoostCharacteristicClick = characteristicId => {
    const {
      boostCharacteristicMutation,
      settings,
      friendPreferences,
      schoolSettings,
    } = this.props
    const {
      data: { id, activeGrade },
    } = this.state

    this.onRunBoost(true)

    // Build mutation variables
    const variables = {
      boostCharacteristicParams: {
        activeGradeId: activeGrade.id,
        characteristicId,
        solutionId: id,
      },
      adminOnly: showSensitiveData(settings),
    }

    const refetchQueries = [
      {
        query: getAssignmentsQuery,
        variables: { gradeId: activeGrade.id },
      },
    ]

    return boostCharacteristicMutation({ variables, refetchQueries })
      .then(data => {
        this.onSuccessfulBoost(data.data.boostCharacteristicMetric)
        this.setState({
          metricDifferences: {
            ...getBoostedMetricDifferences(
              data.data.boostCharacteristicMetric,
              friendPreferences,
              schoolSettings.avoidSameTeacher
            ),
            ...this.state.metricDifferences,
            activeCharacteristic: getBoostedCharacteristicMetricDifference(
              data.data.boostCharacteristicMetric,
              characteristicId
            ),
          },
        })
      })
      .catch(error => {
        this.onBoostError(error)
      })
  }

  onBoostSolutionMetricClick = metric => {
    const { boostSolutionMetricMutation, settings } = this.props
    const {
      data: { id, activeGrade },
    } = this.state
    this.onRunBoost(true)

    // build variables for boost
    const variables = {
      boostSolutionParams: {
        activeGradeId: activeGrade.id,
        metric,
        solutionId: id,
      },
      adminOnly: showSensitiveData(settings),
    }

    const refetchQueries = [
      {
        query: getAssignmentsQuery,
        variables: { gradeId: activeGrade.id },
      },
    ]

    return boostSolutionMetricMutation({ variables, refetchQueries })
      .then(data => {
        this.onSuccessfulBoost(data.data.boostSolutionMetric)
      })
      .catch(error => {
        this.onBoostError(error)
      })
  }

  onBoostStudentMetricClick = metric => {
    const {
      boostStudentMetricMutation,
      settings,
      selectedStudent: { studentId },
      friendPreferences,
      schoolSettings,
    } = this.props
    const {
      data: { id, activeGrade },
    } = this.state

    this.onRunBoost(false)

    // build variables for mutation
    const variables = {
      boostStudentParams: {
        activeGradeId: activeGrade.id,
        metric,
        solutionId: id,
        studentId,
      },
      adminOnly: showSensitiveData(settings),
    }

    const previousStudentMetrics = this.state.data.studentMetrics.find(
      metrics => {
        return metrics.student.id === studentId
      }
    )

    const refetchQueries = [
      {
        query: getAssignmentsQuery,
        variables: { gradeId: activeGrade.id },
      },
    ]

    return boostStudentMetricMutation({ variables, refetchQueries })
      .then(data => {
        this.onSuccessfulBoost(data.data.boostStudentMetric)

        this.setState({
          metricDifferences: {
            ...getBoostedMetricDifferences(
              data.data.boostStudentMetric,
              friendPreferences,
              schoolSettings.avoidSameTeacher
            ),
            student: getStudentMetricDifferences(
              data.data.boostStudentMetric,
              studentId,
              previousStudentMetrics,
              friendPreferences
            ),
          },
        })
      })
      .catch(error => {
        this.onBoostError(error)
      })
  }

  csvExport = async () => {
    const { data } = this.state

    const id = data ? data.id : 0

    const { user, settings } = this.props
    const token = await this.context.getTokenSilently()
    const schoolId = getSchoolId()
    const userRole = user.role
    if (!schoolId || !userRole || !token) {
      // todo: show an alert
      return
    }

    const baseUrl = getApiBaseUri()
    const filterSensitive = settings.adminOnlyRequests ? "false" : "true"
    const url = `${baseUrl}/export_solution_data/${schoolId}/${id}?filter_sensitive=${filterSensitive}`

    const response = await downloadAuthenticatedFile(token, url)

    if (!response.ok) {
      const message = await response.text()
      handleSessionExpired({ message })

      this.setState({
        errors: { export: "Network error attempting to export" },
      })
    }
  }

  sharedSolutionCsvExport = async activeGradeId => {
    const token = sessionStorage.getItem(AUTH_TOKEN)

    const baseUrl = getApiBaseUri()
    const url = `${baseUrl}/export/shared_solution/${activeGradeId}`

    const response = await downloadAuthenticatedFile(token, url)

    if (!response.ok) {
      const message = await response.text()
      handleSessionExpired({ message })

      this.setState({
        errors: { export: "Network error attempting to export" },
      })
    }
  }

  onStudentClick = (e, id, isSelected) => {
    // Remove Dropped Student Highlighting
    this.setState({
      highlightStudents: [],
      metricDifferences: {},
    })

    const allStudents = this.state.data.classes.flatMap(
      ({ students }) => students
    )

    const studentToAdd = allStudents.find(student => student.id === id)

    const data = this.state.data
    const lockedStudentIds = data.lockedStudentIds
    const lockState = isDefinedNotNull(lockedStudentIds)
      ? lockedStudentIds.includes(parseInt(id, 10))
      : false

    const shiftKeyDown = e.shiftKey

    // Check if shift button is pressed or not
    if (shiftKeyDown && lockState) {
      // Does nothing
    } else if (shiftKeyDown && !lockState) {
      this.clearSelectedStudent()

      const draggedStudentsToModify = [
        ...this.state.studentsSelectedForDragging,
      ]

      let updatedStudentsSelectedForDragging
      if (
        draggedStudentsToModify.filter(student => student.id === id).length > 0
      ) {
        const draggedStudentsWithDraggedRemoved =
          draggedStudentsToModify.filter(student => student.id !== id)

        updatedStudentsSelectedForDragging = draggedStudentsWithDraggedRemoved
      } else {
        const newStudentsSelectedForDragging = [
          ...draggedStudentsToModify,
          studentToAdd,
        ]

        updatedStudentsSelectedForDragging = newStudentsSelectedForDragging
      }

      // This part is to add the student currently selected
      const selectedStudentId = this.props.selectedStudent.studentId
      if (selectedStudentId !== "") {
        const selectedStudent = allStudents.find(
          student => selectedStudentId === student.id
        )
        updatedStudentsSelectedForDragging.push(selectedStudent)
      }

      updatedStudentsSelectedForDragging.sort((a, b) => {
        const indexA = allStudents.findIndex(x => a === x)
        const indexB = allStudents.findIndex(x => b === x)

        return indexA - indexB
      })

      this.setState({
        studentsSelectedForDragging: updatedStudentsSelectedForDragging,
      })
    } else {
      // Either Clear or select student
      if (isSelected) {
        this.clearSelectedStudent()
      } else {
        this.setState({ selectedStudentId: id })
      }

      // Clear studentsSelectedForDragging
      this.setState({
        studentsSelectedForDragging: [],
      })
    }
  }

  hasSolution = () => {
    const { data } = this.state
    if (!data || hasAsyncSolveInProgress(data)) return false

    const assignedStudents = data.classes.map(c => c.students).flat()

    return assignedStudents.length !== 0
  }

  hasActiveSolution = () =>
    this.hasSolution() &&
    this.state.data.classes.some(c => c.counts.total !== 0)

  toggleStep3Video = () => {
    this.setState(prevState => {
      return { ...prevState, showStep3Video: !prevState.showStep3Video }
    })
  }

  allowedInClass = (student, newClass) => {
    if (student) {
      return newClass.schoolGrades.some(
        grade => grade.id === student.newGrade.id
      )
    } else {
      return true
    }
  }

  render() {
    const {
      undoMoves = [],
      redoMoves = [],
      selectedStudent,
      refetchQueries,
      adminMode,
      schoolSettings,
      solverGrades,
      updateSelectedTeacherId,
      updateSelectedStudent,
      updateStudentEditFlag,
      selectedTeacherId,
      studentFlags,
      solverMode,
      showTeachers,
      showTeacherRequests,
      showStudentRequests,
      showMetrics,
      showCharacteristics,
      showFriendships,
      schoolCharacteristics,
      activeCurrentClasses,
      gettextObj,
      location,
      favouriteAnAssignmentMutation,
      unfavouriteAnAssignmentMutation,
      updateFavouritedAssignmentMutation,
      lastAssignmentCached,
      updateLastAssignment,
      updateSolverUndoRedo,
      assignmentsData,
      favouritesData,
    } = this.props
    const {
      data = DEFAULT_SOLVER_DATA,
      metricDifferences = {},
      panelOpen,
      teacherPanelIsOpen,
      highlightStudents,
      boostModal,
      shareSolutionModal,
      activeCharacteristicId,
      loading,
      loadingMessage,
      loadingModal,
      showLoadingOverlay,
      errors,
      selectedStudentId,
      runningSolverPrompt,
      unassignedTeacherPrompt,
      showStep3Video,
      draggedStudent,
      studentsSelectedForDragging,
      sortByCharacteristicResponses,
      classDataPreview,
    } = this.state

    const notifications =
      !this.props.loadingNotifications && this.props.getNotifications
        ? this.props.getNotifications
        : []
    const unallocatedStudentsNotification = notifications.find(
      notification => notification.notificationId === "UNALLOCATED_STUDENTS"
    )

    if (loading) {
      return <Loader />
    }

    const asyncSolveInProgress = hasAsyncSolveInProgress(data)

    const topAssignmentId =
      assignmentsData && assignmentsData[0] && assignmentsData[0].id

    const topAssignmentType =
      assignmentsData && assignmentsData[0] && assignmentsData[0].assignmentType

    // First clause is to check lastAssignmentCached has been set.
    // Second clause is to check if there is a discrepency between lastAssignmentCached and the top assignment received from the api.
    if (lastAssignmentCached && lastAssignmentCached !== topAssignmentId) {
      // The first clause is to check there a 2 or more asignments. Otherwise UndoRedo does not make sense otherwise.
      // The second clause is to check that the assignement type is "SOLVE". Otherwise pressing undo and redo will push the top assignment onto the stack on re-render
      // when it shouldn't.
      if (assignmentsData.length >= 2 && topAssignmentType === "SOLVE") {
        const newAssignment = assignmentsData[0]
        const oldAssignment = assignmentsData[1]
        const assignmentObject = {
          toAssignment: { id: oldAssignment.id, movedStudents: [] },
          fromAssignment: { id: newAssignment.id, movedStudents: [] },
        }

        updateSolverUndoRedo({
          variables: {
            type: PUSH,
            assignmentObject,
          },
        })
      }

      updateLastAssignment({
        variables: {
          lastAssignmentCached: null,
        },
      })
    }

    const allStudents = asyncSolveInProgress
      ? []
      : data.classes.flatMap(({ students }) => students)

    const currentClassById = allStudents.reduce((acc, student) => {
      if (student.currentClass) {
        const currentClass = student.currentClass

        const previousCount = acc[currentClass.id]
          ? acc[currentClass.id].studentCount
          : 0

        return {
          ...acc,
          [currentClass.id]: {
            ...currentClass,
            studentCount: previousCount + 1,
          },
        }
      }
      return acc
    }, {})

    const currentClasses = Object.values(currentClassById)

    const { classes, lockedStudentIds } = data
    const classesToShow = !classDataPreview ? classes : classDataPreview

    const classStyle = {
      minWidth: "220px",
      maxWidth: "220px",
    }
    let activeCharacteristic = { id: activeCharacteristicId }
    const characteristics = getPropertyIfDefined(
      this.state.data,
      "metrics.characteristicScores.characteristicMetrics"
    )
    if (characteristics && activeCharacteristicId !== "") {
      activeCharacteristic = characteristics.find(
        c =>
          c.characteristic.id.toString() === activeCharacteristicId.toString()
      ) || { id: activeCharacteristicId }
    }

    const solverClass = panelOpen ? "" : "c-solver__classes-container--expand"

    const sideBarRefetchQueries = refetchQueries.concat([
      {
        query: getNotificationsQuery,
        variables: { schoolId: getSchoolId() },
      },
    ])

    const hasGenderX = allStudents.some(student => student.gender === "X")

    const hasMax = classesHaveMax(classes)

    const showClassEntryCriteria = classes
      ? hasClassEntryCriteria(classes)
      : false

    return (
      <>
        <div className="u-row-fix">
          {unallocatedStudentsNotification && (
            <UnallocatedStudentsNote
              notification={unallocatedStudentsNotification}
            />
          )}
        </div>

        <DragDropContext
          onDragStart={this.onDragStart}
          onDragEnd={this.onDragEnd}>
          <div
            className={`c-solver u-row-fix u-layout-component-grow ${
              adminMode ? "bg-admin" : ""
            }`}>
            {showLoadingOverlay && (
              <div className="c-solver__overlay">
                <Loader />
              </div>
            )}
            <SideBar
              isOpen={panelOpen}
              togglePanel={this.toggle("panelOpen")}
              undoMoves={undoMoves}
              redoMoves={redoMoves}
              metrics={getPropertyIfDefined(this.state, "data.metrics")}
              studentMetrics={getPropertyIfDefined(
                this.state,
                "data.studentMetrics"
              )}
              activeGradeCount={studentCountForClasses(data.classes) || 0}
              gradesForSolution={gradesFromClasses(data.classes) || []}
              adminMode={adminMode}
              errors={errors}
              schoolSettings={schoolSettings}
              metricDifferences={metricDifferences}
              selectedStudentId={selectedStudentId}
              refetchQueries={sideBarRefetchQueries}
              solverGrades={solverGrades}
              solverRefetch={this.solverRefetch}
              csvExport={this.csvExport}
              sharedSolutionCsvExport={this.sharedSolutionCsvExport}
              shareSolution={this.toggle("shareSolutionModal")}
              onActiveGradeChange={this.onActiveGradeChange}
              activeGradeId={this.props.activeGradeId}
              activeCharacteristic={activeCharacteristic}
              studentFlags={studentFlags}
              updateStudentEditFlag={updateStudentEditFlag}
              solveActiveGrade={this.solveActiveGrade}
              onActiveCharacteristicChange={this.onActiveCharacteristicChange}
              updateSelectedStudent={updateSelectedStudent}
              onUndoRedoClick={this.onUndoRedoClick}
              asyncSolveInProgress={asyncSolveInProgress}
              onBoostSolutionMetricClick={this.maybeMultipleBoostClick(
                this.onBoostSolutionMetricClick
              )}
              onBoostCharacteristicClick={this.maybeMultipleBoostClick(
                this.onBoostCharacteristicClick
              )}
              onBoostStudentMetricClick={this.maybeMultipleBoostClick(
                this.onBoostStudentMetricClick
              )}
              solverMode={solverMode}
              hasSolution={this.hasActiveSolution()}
              showMetrics={showMetrics}
              showCharacteristics={showCharacteristics}
              showTeacherRequests={showTeacherRequests}
              showStudentRequests={showStudentRequests}
              showFriendships={showFriendships}
              currentClasses={activeCurrentClasses}
              hasAsyncSolveInProgress={asyncSolveInProgress}
              clearSelectedStudent={this.clearSelectedStudent}
              solutionSuggestions={data.suggestions}
              unassignedTeacherPrompt={unassignedTeacherPrompt}
              toggleCharacteristicResponseSort={
                this.toggleCharacteristicResponseSort
              }
              showClassEntryCriteria={showClassEntryCriteria}
              location={location}
              assignments={assignmentsData}
              favourites={favouritesData}
              favouriteAnAssignmentMutation={favouriteAnAssignmentMutation}
              unfavouriteAnAssignmentMutation={unfavouriteAnAssignmentMutation}
              updateFavouritedAssignmentMutation={
                updateFavouritedAssignmentMutation
              }
              pickAnAssignment={this.pickAnAssignment}
              assignmentWithClasses={this.assignmentWithClasses}
              user={this.props.user}
            />
            {asyncSolveInProgress ? (
              <div
                className={`c-solver__classes-container container-fluid ${solverClass}`}>
                <RunningSolveView solverRefetch={this.solverRefetch} />
              </div>
            ) : this.hasSolution() ? (
              <>
                <div
                  className={`c-solver__classes-container ${solverClass} d-print-none`}>
                  {isDefinedNotNull(classesToShow) &&
                    classesToShow.map((c, index) => {
                      const isDropDisabled = !this.allowedInClass(
                        draggedStudent,
                        c.classDetails
                      )

                      return (
                        <SolverClass
                          key={index}
                          classStyle={classStyle}
                          clearSelectedStudent={this.clearSelectedStudent}
                          clearStudentsToBeDragged={
                            this.clearStudentsToBeDragged
                          }
                          onStudentClick={this.onStudentClick}
                          studentMetrics={getPropertyIfDefined(
                            this.state,
                            "data.studentMetrics"
                          )}
                          maxTeacherPerClass={
                            maxTeacherPerClass(data.classes) || 0
                          }
                          schoolSettings={schoolSettings}
                          lockedStudentIds={lockedStudentIds}
                          selectedStudent={selectedStudent}
                          highlightStudents={highlightStudents}
                          draggedStudent={draggedStudent}
                          studentsSelectedForDragging={
                            studentsSelectedForDragging
                          }
                          classData={c}
                          onLockStudent={this.onLockStudent}
                          updateLockedStudentIdsOptimization={
                            this.updateLockedStudentIdsOptimization
                          }
                          lockStudentsMutation={this.props.lockStudentsMutation}
                          solutionId={data.id}
                          refetch={this.solverRefetch}
                          refetchQueries={refetchQueries}
                          activeGrade={getPropertyIfDefined(
                            data,
                            "activeGrade.id"
                          )}
                          activeCharacteristic={activeCharacteristic}
                          updateSelectedTeacherId={updateSelectedTeacherId}
                          selectedTeacherId={selectedTeacherId}
                          solverMode={solverMode}
                          showTeachers={showTeachers}
                          showTeacherRequests={showTeacherRequests}
                          showStudentRequests={showStudentRequests}
                          showFriendships={showFriendships}
                          currentClasses={currentClasses}
                          schoolCharacteristics={schoolCharacteristics}
                          activeCurrentClasses={activeCurrentClasses}
                          hasGenderX={hasGenderX}
                          isDropDisabled={isDropDisabled}
                          sortByCharacteristicResponses={
                            sortByCharacteristicResponses
                          }
                          hasMax={hasMax}
                          showClassEntryCriteria={showClassEntryCriteria}
                          toggleTeacherPanel={this.toggleTeacherPanel}
                          teacherPanelIsOpen={teacherPanelIsOpen}
                        />
                      )
                    })}
                </div>
                <PrintableSolver
                  solution={data}
                  showTeachers={showTeachers}
                  hasGenderX={hasGenderX}
                />
              </>
            ) : solverMode === NORMAL ? (
              <div
                className={`c-solver__classes-container container-fluid ${solverClass}`}>
                <ColdStartView buttonClick={this.solveActiveGrade} />
              </div>
            ) : (
              <div className="c-solver__classes-container container-fluid align-items-center justify-content-center h4">
                The solver has not been run for this grade yet. Please contact
                your school administrator.
              </div>
            )}
            {solverMode === NORMAL && (
              <>
                {shareSolutionModal && (
                  <ShareSolutionModal
                    schoolId={getSchoolId()}
                    toggle={this.toggle("shareSolutionModal")}
                    user={this.props.user}
                    allowWritableSharedSolutions={
                      schoolSettings.allowWritableSharedSolutions
                    }
                  />
                )}
                {runningSolverPrompt && (
                  <RunningSolverPrompt
                    toggle={this.toggleRunningSolverPrompt}
                  />
                )}
                {sessionStorage.getItem(SHOW_SOLVER_MODAL) === null && (
                  <CautionModal
                    isOpen
                    onButtonClick={this.toggleSolverModal}
                    toggle={this.toggleSolverModal}
                    heading="Solver Page Notice"
                    text={`Two or more Administrators can make changes to ${gettextObj.gettext(
                      "class"
                    )} lists at the same time as long as they are not working on the same ${gettextObj.gettext(
                      "grade (or mixed/composite grade)"
                    )}.`}
                    buttonText="Dismiss"
                    showCancel={false}
                  />
                )}
              </>
            )}
            {boostModal && (
              <CautionModal
                isOpen
                onButtonClick={this.toggle("boostModal")}
                toggle={this.toggle("boostModal")}
                heading="Boost: No Improvements Found"
                text={
                  <>
                    <p>Consider trying the following:</p>
                    <p>
                      <b>1) Student Boost:</b> Clicking on the impacted student
                      and selecting a Boost option at the bottom left section of
                      the Control Panel.
                    </p>
                    <p>
                      <b>2) Manual Move:</b> Moving a student (or students) then
                      using the Lock & Boost functions to correct any issues.
                    </p>
                    <p>
                      Learn more in the Fixing Issues section of the{" "}
                      <a
                        className="color-blue-mid"
                        onClick={this.toggleStep3Video}>
                        Step 3 Video
                      </a>
                    </p>
                  </>
                }
                buttonText="Dismiss"
                showCancel={false}
              />
            )}
            {showStep3Video && (
              <VideoModal
                toggle={this.toggleStep3Video}
                videoUrl={INSTRUCTION_STEP_3_PLAYER_URL}
                title="Instruction Step 3"
              />
            )}
            {loadingModal && (
              <LoadingModal
                isOpen
                toggle={this.toggle("loadingModal")}
                heading={loadingMessage}
                loading={loadingMessage !== ""}
                /* Only Show Text for when running boost */
                text={
                  loadingMessage.includes("Boost")
                    ? "Any changes to your lists will be highlighted after Boost is completed."
                    : ""
                }
                error={errors.boost}
              />
            )}
          </div>
        </DragDropContext>
      </>
    )
  }
}

const SolverWithAssignments = props => {
  const { activeGradeId } = props

  const variables = { gradeId: activeGradeId }

  const {
    data: assignmentData,
    loading: loadingAssignmentData,
    error: assignmentDataError,
    refetch: refetchAssignments,
  } = useQuery(getAssignmentsQuery, {
    variables,
    fetchPolicy: "network-only",
  })

  const {
    data: favouriteData,
    loading: loadingFavouriteData,
    error: favouriteDataError,
  } = useQuery(getFavouritedAssignmentsQuery, {
    variables,
    fetchPolicy: "network-only",
  })

  const location = useLocation()
  const gettextObj = React.useContext(AccountTypeContext).gettextObj

  if (loadingAssignmentData) return <Loader />

  if (loadingFavouriteData) return <Loader />

  return (
    <SolverPre
      location={location}
      gettextObj={gettextObj}
      assignmentsData={
        assignmentDataError
          ? []
          : getPropertyIfDefined(assignmentData, "assignmentsForGradeId")
      }
      favouritesData={
        favouriteDataError
          ? []
          : getPropertyIfDefined(
              favouriteData,
              "favouritedAssignmentsForGradeId"
            )
      }
      refetchAssignments={refetchAssignments}
      {...props}
    />
  )
}

const SolverWithOutAssignments = props => {
  const location = useLocation()
  const gettextObj = React.useContext(AccountTypeContext).gettextObj

  return (
    <SolverPre
      location={location}
      gettextObj={gettextObj}
      assignmentsData={[]}
      favouritesData={[]}
      refetchAssignments={() => null}
      {...props}
    />
  )
}

export const Solver = props => {
  const { solverMode } = props

  const canAccessTimeline = solverMode !== SHARED_READ_ONLY

  // Split out the two scenarios into separate SolverWithAssignments and SolverWithOutAssignments components
  // to avoid warning when conditionally calling useQuery
  if (canAccessTimeline) {
    return <SolverWithAssignments {...props} />
  }

  return <SolverWithOutAssignments {...props} />
}
