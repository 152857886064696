export const dateToString = date => {
  const newDate = new Date(date + "Z")
  const options = { year: "numeric", month: "short", day: "2-digit" }
  // Use default locale
  return newDate.toLocaleDateString(undefined, options)
}

export const shortDateToString = date => {
  const newDate = new Date(date + "Z")
  const options = { year: "2-digit", month: "numeric", day: "numeric" }
  // Use default locale
  return newDate.toLocaleDateString(undefined, options)
}

export const timeFromDateTimeString = dateTime => {
  const newDateTime = new Date(dateTime + "Z")
  const options = {
    hour: "2-digit",
    minute: "2-digit",
    hour12: true,
  }
  // Use default locale
  const time = newDateTime.toLocaleTimeString(undefined, options)
  return `${time}`
}

export const dateTimeToString = dateTime => {
  const newDateTime = new Date(dateTime + "Z")
  const options = {
    hour: "numeric",
    minute: "numeric",
  }
  // Use default locale
  const date = dateToString(dateTime)
  const time = newDateTime.toLocaleTimeString(undefined, options)
  return `${date} ${time}`
}
