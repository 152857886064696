import React, { Component } from "react"
import { AdminList, SuccessModal, SuperAdminModal } from "components"
import {
  AccountDetails,
  Preference,
  SchoolDelete,
  ImportDataFiles,
  ExportDataFiles,
  Payments,
  CloneSchool,
  RollOver,
  AdminReports,
  AdminActions,
  AdminSearch,
} from "domains/accountSettings/components"

import { SCHOOL_ID } from "constants/storageTokens"
import { handleSessionExpired } from "util/app"

import { SUPPORT } from "domains/app/constants/roleTypes"
import { isSuper } from "util/userUtil"

import { Input } from "reactstrap"
const initialAdminData = {
  firstName: "",
  lastName: "",
  positionTitle: "",
  email: "",
  schools: [],
  accounts: [],
  mfa: false,
}

const schoolNameString = school =>
  school
    ? `${school.id} - ${school.stateCode}, ${school.suburb} - ${school.name}`
    : ""

export class SuperAdmin extends Component {
  constructor(props) {
    super(props)
    this.state = {
      loading: false,
      adminModal: false,
      addAdmin: false,
      adminData: initialAdminData,
      successModal: "",
      successModalHeading: "",
      errors: {},
      deleteSchoolModal: {},
    }
  }

  toggleAdminModal = () => {
    this.setState({
      adminModal: !this.state.adminModal,
      errors: {},
    })
  }

  toggleSuccessModal = () => {
    this.setState({
      successModal: "",
      successModalHeading: "",
    })
  }

  toggleDeleteSchoolModal = () => {
    this.setState({
      deleteSchoolModal: {},
    })
  }

  onEditAdminClick = admin => {
    this.setState({
      adminData: { ...admin },
      adminModal: true,
      addAdmin: false,
    })
  }

  onAddAdminClick = () => {
    this.setState({
      adminData: initialAdminData,
      adminModal: true,
      addAdmin: true,
    })
  }

  onChange = (e, value) => {
    const { updateSchoolSettingMutation, preferencesQuery, school } = this.props

    // toggle value
    const schoolSettingParams = {
      schoolId: school.id,
      [e.target.name]: !value,
    }

    this.setState({
      loading: true,
    })

    updateSchoolSettingMutation({
      variables: { schoolSettingParams },
      refetchQueries: [
        {
          query: preferencesQuery,
          variables: { schoolId: school.id },
        },
      ],
    })
      .then(() => {
        // set time out to allow longer transitioning of cursor
        setTimeout(() => {
          this.setState({
            loading: false,
          })
        }, 500)
      })
      .catch(error => {
        handleSessionExpired(error)
        this.setState({
          loading: false,
        })
      })
  }

  onIntegerSchoolSettingChange = e => {
    const { updateSchoolSettingMutation, preferencesQuery, school } = this.props
    // toggle value
    const schoolSettingParams = {
      schoolId: school.id,
      [e.target.name]: parseInt(e.target.value),
    }

    this.setState({
      loading: true,
    })

    updateSchoolSettingMutation({
      variables: { schoolSettingParams },
      refetchQueries: [
        {
          query: preferencesQuery,
          variables: { schoolId: school.id },
        },
      ],
    })
      .then(() => {
        // set time out to allow longer transitioning of cursor
        setTimeout(() => {
          this.setState({
            loading: false,
          })
        }, 500)
      })
      .catch(error => {
        handleSessionExpired(error)
        this.setState({
          loading: false,
        })
      })
  }

  onDeleteButtonClick = () => {
    const { school } = this.props
    this.setState({
      deleteSchoolModal: {
        heading: "Delete WHOLE School?",
        text: `This will permanently delete the "${schoolNameString(
          school
        )}" school and everything associated with it. Are you sure you want to continue?`,
        onClick: this.deleteSchool,
      },
    })
  }

  onRemoveDataButtonClick = () => {
    const { school } = this.props

    this.setState({
      deleteSchoolModal: {
        heading: "Delete School's Data?",
        text: `This will delete the data for "${schoolNameString(
          school
        )}". Are you sure you want to continue?`,
        onClick: this.deleteSchoolData,
      },
    })
  }

  deleteSchool = () => {
    const {
      deleteSchoolMutation,
      school: { id: schoolId },
    } = this.props
    this.setState({
      loading: true,
    })
    deleteSchoolMutation({
      variables: { schoolId },
    })
      .then(() => {
        this.setState({
          loading: false,
          deleteSchoolModal: {},
        })
        sessionStorage.removeItem(SCHOOL_ID)
        window.location.href = "/"
      })
      .catch(() => {
        this.setState({
          loading: false,
          errors: {
            deleteSchool: "Network Error",
          },
        })
      })
  }

  deleteSchoolData = () => {
    const {
      deleteSchoolDataMutation,
      school: { id: schoolId },
    } = this.props
    this.setState({
      loading: true,
    })
    deleteSchoolDataMutation({
      variables: { schoolId },
    })
      .then(() => {
        this.setState({
          loading: false,
          deleteSchoolModal: {},
        })
        window.location.reload()
      })
      .catch(() => {
        this.setState({
          loading: false,
          errors: {
            deleteSchool: "Network Error",
          },
        })
      })
  }

  linkSchoolToCustomer = async (email, description) => {
    const { linkPaymentToSchoolMutation, school } = this.props
    if (school) {
      this.setState({
        loading: true,
      })
      const variables = {
        schoolId: school.id,
        description,
        email,
      }
      await linkPaymentToSchoolMutation({ variables })
        .then(() => {
          this.setState({
            loading: false,
            errors: {},
            successModal:
              "Schools successfully linked to stripe customer with email: " +
              email,
            successModalHeading: "Successful Link!",
          })
        })
        .catch(error => {
          this.setState({
            loading: false,
            errors: {
              linking:
                "There was an error linking these schools to the stripe account",
            },
          })
          throw error
        })
    } else {
      this.setState({
        errors: {
          linking:
            "There is no school selected or users for this school, please try again",
        },
      })
    }
  }

  linkSchoolsToOneCustomer = async schoolIds => {
    const { linkMultipleSchoolsToOneCustomerMutation, school } = this.props
    this.setState({
      loading: true,
    })

    const variables = {
      schoolIdToLinkTo: school.id,
      schoolIds,
    }

    await linkMultipleSchoolsToOneCustomerMutation({ variables })
      .then(() => {
        this.setState({
          loading: false,
          errors: {},
          successModal: "Schools successfully linked to stripe customer",
          successModalHeading: "Successful Link!",
        })
      })
      .catch(error => {
        this.setState({
          loading: false,
          errors: {
            multiLink:
              "There was an error linking this schools to the stripe account",
          },
        })
        throw error
      })
  }

  render() {
    const {
      superAdmins,
      myprofile = {},
      refetchQueries,
      schoolSettings,
      school = {},
      rollOverData,
      deactivateAllStudentsMutation,
      managers,
    } = this.props

    const {
      adminModal,
      addAdmin,
      loading,
      adminData,
      successModal,
      successModalHeading,
      deleteSchoolModal,
      errors,
    } = this.state

    return (
      <div className={`container ${loading ? "cursor-wait" : ""}`}>
        <h1 className="mb-5 mt-5 u-blue-primary">Support Administrators</h1>
        <div className="u-content-border-bottom pb-4">
          <AdminList
            users={superAdmins}
            onAddAdminClick={this.onAddAdminClick}
            onEditAdminClick={this.onEditAdminClick}
            allowAdd={isSuper(myprofile.role)}
            allowEdit={isSuper(myprofile.role)}
          />
        </div>
        <AdminActions isSuper={isSuper(myprofile.role)} />
        <AdminReports />
        <AdminSearch />
        <div className="pt-5">
          <AccountDetails isSuper managers={managers} />
        </div>
        <div className="u-content-border-bottom pb-4">
          <h2 className="mb-5 mt-5 u-blue-primary">Preferences</h2>
          <Preference
            id="newAccountLock"
            name="newAccountLock"
            title="Blind Ding Lock"
            description="Apply Blind Ding lock which prevents users proceeding past Step 1"
            checked={schoolSettings.newAccountLock}
            onChangeHandler={e =>
              this.onChange(e, schoolSettings.newAccountLock)
            }
          />

          {/* On the frontend we want to display this with positive language, i.e. "Yes" to apply the lock and "No" to keep the account unlocked */}
          <Preference
            id="overrideSolverLock"
            name="overrideSolverLock"
            title="Solver Lock"
            description="Apply the Solver lock for this school"
            checked={!schoolSettings.overrideSolverLock}
            onChangeHandler={e =>
              this.onChange(e, schoolSettings.overrideSolverLock)
            }
          />

          <Preference
            id="autoServicing"
            name="autoServicing"
            title="Auto Servicing"
            description="Turn on Auto Servicing"
            checked={schoolSettings.autoServicing}
            onChangeHandler={e =>
              this.onChange(e, schoolSettings.autoServicing)
            }
          />

          <Preference
            id="requireOtp"
            name="requireOtp"
            title="Turn on One-Time Passwords"
            description="Whether this school uses One-Time Passwords"
            checked={schoolSettings.requireOtp}
            onChangeHandler={e => this.onChange(e, schoolSettings.requireOtp)}
          />

          <Preference
            id="requireMfa"
            name="requireMfa"
            title="Require Multi-Factor Authentication (MFA)"
            description="Whether this school requires MFA"
            checked={schoolSettings.requireMfa}
            onChangeHandler={e => this.onChange(e, schoolSettings.requireMfa)}
          />

          <Preference
            id="autoRollover"
            name="autoRollover"
            title="Whether to automatically rollover this school"
            description="Sets the auto rollover"
            checked={schoolSettings.autoRollover}
            onChangeHandler={e => this.onChange(e, schoolSettings.autoRollover)}
          />
          <Preference
            id="allowWritableSharedSolutions"
            name="allowWritableSharedSolutions"
            title="Allow editable shared solutions"
            description="Allows the school to share editable shared solutions"
            checked={schoolSettings.allowWritableSharedSolutions}
            onChangeHandler={e =>
              this.onChange(e, schoolSettings.allowWritableSharedSolutions)
            }
          />
          <Preference
            id="currentClassAsCharacteristic"
            name="currentClassAsCharacteristic"
            title="Current Class as characteristic"
            description="Use the current class as a characteristic in solutions"
            checked={schoolSettings.currentClassAsCharacteristic}
            onChangeHandler={e =>
              this.onChange(e, schoolSettings.currentClassAsCharacteristic)
            }
          />
          <Preference
            id="depersonaliseStudents"
            name="depersonaliseStudents"
            title="Depersonalise Student Data"
            description="Turn on Depersonalise Student Data"
            checked={schoolSettings.depersonaliseStudents}
            onChangeHandler={e =>
              this.onChange(e, schoolSettings.depersonaliseStudents)
            }
          />
          <div className="mb-3">
            <p className="mb-0">Minimum Friend Preferences</p>
            <Input
              type="number"
              name="minFriendPrefs"
              onChange={this.onIntegerSchoolSettingChange}
              value={schoolSettings.minFriendPrefs}
              min={1}
            />
          </div>
          <div className="mb-3">
            <p className="mb-0">Current Class Characteristic Priority</p>
            <Input
              type="number"
              name="currentClassCharacteristicPriority"
              onChange={this.onIntegerSchoolSettingChange}
              value={schoolSettings.currentClassCharacteristicPriority}
              min={1}
              disabled={!schoolSettings.currentClassAsCharacteristic}
            />
          </div>
          <div className="mb-3">
            <p className="mb-0">Current Class Characteristic Minimum Count</p>
            <Input
              type="number"
              name="currentClassCharacteristicMinRc"
              onChange={this.onIntegerSchoolSettingChange}
              value={schoolSettings.currentClassCharacteristicMinRc}
              min={1}
              disabled={!schoolSettings.currentClassAsCharacteristic}
            />
          </div>
          <div className="mb-3">
            <p className="mb-0">
              Minimum Student ID Length (adds zeroes to the front of numeric IDs
              that don't meet this length when exporting).
            </p>
            <Input
              type="number"
              name="minStudentCodeLength"
              onChange={this.onIntegerSchoolSettingChange}
              value={schoolSettings.minStudentCodeLength}
              min={0}
            />
          </div>
        </div>
        <CloneSchool school={school} />
        <SchoolDelete
          onRemoveDataButtonClick={this.onRemoveDataButtonClick}
          onDeleteButtonClick={this.onDeleteButtonClick}
          toggleDeleteSchoolModal={this.toggleDeleteSchoolModal}
          deleteSchoolModal={deleteSchoolModal}
          loading={loading}
          error={errors.deleteSchool}
        />
        <RollOver
          rollOverDataMutation={rollOverData}
          deactivateAllStudentsMutation={deactivateAllStudentsMutation}
        />
        <ImportDataFiles schoolId={school.id} />
        <ExportDataFiles schoolId={school.id} />
        <Payments
          loading={loading}
          errors={errors}
          linkSchoolToCustomer={this.linkSchoolToCustomer}
          linkSchoolsToOneCustomer={this.linkSchoolsToOneCustomer}
        />
        {adminModal && (
          <SuperAdminModal
            toggle={this.toggleAdminModal}
            refetchQueries={refetchQueries}
            addAdmin={addAdmin}
            canDelete={adminData.id !== myprofile.id}
            adminData={adminData}
            roleType={SUPPORT}
          />
        )}

        <SuccessModal
          isOpen={successModal !== ""}
          toggle={this.toggleSuccessModal}
          heading={successModalHeading}
          text={successModal}
        />
      </div>
    )
  }
}
