import React, { Component } from "react"
import { Modal, ModalBody } from "reactstrap"
import { ModalHeader } from "components"
import { PaymentSummary, PaymentOptions } from "domains/auth/components"

import { PAYMENT_TYPES } from "domains/auth/constants"

export class PaymentsModal extends Component {
  state = {
    data: {
      name: "",
      number: "",
      expiry: "",
      cvc: "",
    },
    type: PAYMENT_TYPES.card,
  }

  onChange = e => {
    this.setState({
      data: {
        ...this.state.data,
        [e.target.name]: e.target.value,
      },
    })
  }

  onConfirm = () => {}

  onToggleChange = () => {
    this.setState(prevState => ({
      type:
        prevState.type === PAYMENT_TYPES.card
          ? PAYMENT_TYPES.cheque
          : PAYMENT_TYPES.card,
    }))
  }

  render() {
    const { isOpen, toggle } = this.props
    const { type, data } = this.state

    return (
      <Modal isOpen={isOpen} className="c-payments-modal">
        <ModalHeader title="Make Payment" toggle={toggle} className="p-4" />
        <ModalBody className="container-fluid p-4">
          <div className="row mb-5">
            <div className="col-6">
              <PaymentSummary />
            </div>
            <div className="col-6">
              <PaymentOptions
                type={type}
                onToggleChange={this.onToggleChange}
                onChange={this.onChange}
                onConfirm={this.onConfirm}
                {...data}
              />
            </div>
          </div>
        </ModalBody>
      </Modal>
    )
  }
}
