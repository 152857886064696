import { gql } from "@apollo/client"

export const updateStudentImportMatchers = gql`
  mutation updateStudentImportMatchers($newMatchers: Matchers) {
    updateStudentImportMatchers(newMatchers: $newMatchers) @client {
      studentsImport {
        jobId
        grades
        columns
      }
    }
  }
`

export const getStudentImportMatchers = gql`
  query GetStudentImportMacthers {
    studentsImport @client {
      jobId
      grades
      columns
    }
  }
`

export const schoolUploadsQuery = gql`
  query ($schoolId: ID!, $columnMap: [ColumnMap]) {
    school(schoolId: $schoolId) {
      id
      uploadJobs {
        id
        status
        type
        fileSummary(columnMap: $columnMap) {
          ... on StudentUploadSummary {
            headers
            classes {
              name
              studentCount
            }
            grades {
              name
              studentCount
            }
            existingStudents {
              studentCode
            }
          }
          ... on TeacherUploadSummary {
            classes {
              name
              teacherCount
            }
          }
        }
        failureCount
      }
    }
  }
`

export const importUploadedFileFromJob = gql`
  mutation importUploadedFileFromJob(
    $jobId: ID!
    $classNameMappings: [ClassNameMap]!
    $gradeNameMappings: [GradeNameMap]!
    $columnMappings: [ColumnMap]!
  ) {
    importUploadedFileFromJob(
      jobId: $jobId
      classNameMappings: $classNameMappings
      gradeNameMappings: $gradeNameMappings
      columnMappings: $columnMappings
    ) {
      id
      status
      type
    }
  }
`

export const updateBulkUploadJob = gql`
  mutation updateBulkUploadJob($jobParams: JobParams!) {
    updateBulkUploadJob(jobParams: $jobParams) {
      id
      status
      failureCount
    }
  }
`

export const directlyImportStudentFileMutation = gql`
  mutation directlyImportStudentFile($schoolId: ID!, $file: Upload!) {
    directlyImportStudentFile(schoolId: $schoolId, file: $file)
  }
`

export const directlyImportTeacherFileMutation = gql`
  mutation directlyImportTeacherFile($schoolId: ID!, $file: Upload!) {
    directlyImportTeacherFile(schoolId: $schoolId, file: $file)
  }
`

export const directlyImportTeacherRequestsFileMutation = gql`
  mutation directlyImportTeacherRequestsFile(
    $schoolId: ID!
    $file: Upload!
    $ignoreMissingIndividualsErrors: Boolean
  ) {
    directlyImportTeacherRequestsFile(
      schoolId: $schoolId
      file: $file
      ignoreMissingIndividualsErrors: $ignoreMissingIndividualsErrors
    )
  }
`
export const directlyImportStudentRequestsFileMutation = gql`
  mutation directlyImportStudentRequestsFile(
    $schoolId: ID!
    $file: Upload!
    $ignoreMissingIndividualsErrors: Boolean
  ) {
    directlyImportStudentRequestsFile(
      schoolId: $schoolId
      file: $file
      ignoreMissingIndividualsErrors: $ignoreMissingIndividualsErrors
    )
  }
`

export const directlyImportCharacteristicResponsesFileMutation = gql`
  mutation directlyImportCharacteristicResponsesFile(
    $schoolId: ID!
    $file: Upload!
  ) {
    directlyImportCharacteristicResponsesFile(schoolId: $schoolId, file: $file)
  }
`
export const directlyImportFriendshipFileMutation = gql`
  mutation directlyImportFriendshipFile($schoolId: ID!, $file: Upload!) {
    directlyImportFriendshipFile(schoolId: $schoolId, file: $file)
  }
`
export const directlyImportSolutionFileMutation = gql`
  mutation directlyImportSolutionFile($schoolId: ID!, $file: Upload!) {
    directlyImportSolutionFile(schoolId: $schoolId, file: $file)
  }
`

export const directlyImportSchoolCharacteristicsFileMutation = gql`
  mutation directlyImportSchoolCharacteristicsFile(
    $schoolId: ID!
    $file: Upload!
  ) {
    directlyImportSchoolCharacteristicsFile(schoolId: $schoolId, file: $file)
  }
`

export const directlyImportNewClassesFileMutation = gql`
  mutation directlyImportNewClassesFile($schoolId: ID!, $file: Upload!) {
    directlyImportNewClassesFile(schoolId: $schoolId, file: $file)
  }
`

export const importChangeStudentCodesFileMutation = gql`
  mutation importChangeStudentCodesFile(
    $schoolId: ID!
    $file: Upload!
    $validateStudentCodes: Boolean
  ) {
    importChangeStudentCodesFile(
      schoolId: $schoolId
      file: $file
      validateStudentCodes: $validateStudentCodes
    )
  }
`

export const importChangeTeacherEmailsFileMutation = gql`
  mutation importChangeTeacherEmailsFile($schoolId: ID!, $file: Upload!) {
    importChangeTeacherEmailsFile(schoolId: $schoolId, file: $file)
  }
`

export const importDeleteStudentsFileMutation = gql`
  mutation importDeleteStudentsFile($schoolId: ID!, $file: Upload!) {
    importDeleteStudentsFile(schoolId: $schoolId, file: $file)
  }
`

export const syncStudentsFromFileMutation = gql`
  mutation syncStudentsFromFile(
    $schoolId: ID!
    $file: Upload!
    $syncActions: [SyncAction!]
    $currentGradeIds: [ID!]
  ) {
    syncStudentsFromFile(
      schoolId: $schoolId
      file: $file
      syncActions: $syncActions
      currentGradeIds: $currentGradeIds
    )
  }
`

export const mutationsIntrospectionQuery = gql`
  query {
    __schema {
      mutationType {
        name
        fields {
          name
          description
        }
      }
    }
  }
`
