import React, { useState } from "react"

import {
  SchoolDeleteModal,
  SettingItem,
  DeleteStudentsByCurrentGradesModal,
  DeleteStudentsByCurrentClassesModal,
} from "domains/accountSettings/components"

import { getSchoolId } from "util/app"

export const SchoolDelete = ({
  onDeleteButtonClick,
  onRemoveDataButtonClick,
  toggleDeleteSchoolModal,
  loading,
  deleteSchoolModal,
  error,
}) => {
  const [showDeleteStudentsByGradesModal, setShowDeleteStudentsByGradesModal] =
    useState(false)

  const toggleDeleteStudentsByGradesModal = () => {
    setShowDeleteStudentsByGradesModal(!showDeleteStudentsByGradesModal)
  }

  const [
    showDeleteStudentsByClassesModal,
    setShowDeleteStudentsByClassesModal,
  ] = useState(false)

  const toggleDeleteStudentsByClassesModal = () => {
    setShowDeleteStudentsByClassesModal(!showDeleteStudentsByClassesModal)
  }

  return (
    <div className="py-5 u-content-border-bottom">
      <h2 className="mb-5 u-blue-primary">Delete School Data</h2>
      <div className="mb-4">
        <SettingItem onButtonClick={onDeleteButtonClick} buttonText="Delete">
          Delete the School and everything associated with the school
        </SettingItem>
      </div>

      <div className="mb-4">
        <SettingItem
          onButtonClick={onRemoveDataButtonClick}
          buttonText="Remove Data">
          Delete the School's Data
        </SettingItem>
      </div>

      <div className="mb-4">
        <SettingItem
          onButtonClick={toggleDeleteStudentsByGradesModal}
          buttonText="Delete Students by Grade">
          Delete Students by Current Grade
        </SettingItem>
      </div>

      <div>
        <SettingItem
          onButtonClick={toggleDeleteStudentsByClassesModal}
          buttonText="Delete Students by Class">
          Delete Students by Current Class
        </SettingItem>
      </div>

      {deleteSchoolModal.text && (
        <SchoolDeleteModal
          toggle={toggleDeleteSchoolModal}
          loading={loading}
          onButtonClick={deleteSchoolModal.onClick}
          heading={deleteSchoolModal.heading}
          text={deleteSchoolModal.text}
          error={error}
        />
      )}
      {showDeleteStudentsByGradesModal && (
        <DeleteStudentsByCurrentGradesModal
          schoolId={getSchoolId()}
          toggle={toggleDeleteStudentsByGradesModal}
        />
      )}
      {showDeleteStudentsByClassesModal && (
        <DeleteStudentsByCurrentClassesModal
          schoolId={getSchoolId()}
          toggle={toggleDeleteStudentsByClassesModal}
        />
      )}
    </div>
  )
}
