import React from "react"
import { Tooltip } from "react-tooltip"
import { graphql } from "@apollo/client/react/hoc"
import compose from "lodash.flowright"
import { solveActiveGradeMutation } from "domains/solver/graphql"
import { teachersQuery } from "domains/teachers/graphql"
import { studentsQuery } from "domains/students/graphql"
import {
  createOrUpdateNewClass,
  activeNewSchoolGradesQuery,
  deleteNewClassMutation,
} from "domains/classes/graphql"
import { settingsClientQuery } from "domains/accountSettings/graphql"

import {
  ButtonGroup,
  Button,
  Modal,
  ModalBody,
  Row,
  FormGroup,
  Label,
  InputGroup,
  Input,
} from "reactstrap"
import Select from "react-select"

import {
  Loader,
  InlineError,
  DeleteModal,
  LoadingModal,
  ModalHeader,
  defaultSelectStyles,
  VideoModal,
} from "components"
import { EntryCriteria } from "domains/classes/components"

import { isDefinedNotNull } from "util/objUtil"
import { validateNonEmptyString } from "util/validators"
import { fullName } from "util/nameUtil"

import { handleSessionExpired, getSchoolId } from "util/app"
import { areEqualArrays, isNonEmptyArray } from "util/array"

import {
  getAndConvertEntryCriteriaStudentAttribute,
  getAndConvertEntryCriteriaCharacteristicResponse,
  getAndConvertEntryCriteriaNullResponse,
  getAvailableCriteria,
  getCurrentCriteria,
} from "domains/classes/components/entryCriteriaHelpers"

import {
  ADVANCED_CLASS_SETTINGS_PLAYER_URL,
  INSTRUCTION_ADD_CLASS_PLAYER_URL,
} from "constants/videos"

import { AccountTypeContext } from "config/accountTypeContext"

const BY_CLASS = "BY_CLASS"
const BY_GRADE = "BY_GRADE"

class ClassModalComponent extends React.Component {
  static contextType = AccountTypeContext

  constructor(props) {
    super(props)

    const schoolGrades = this.props.schoolGrades
      ? this.props.schoolGrades.map(g => {
          return {
            value: g.id,
            label: g.label,
            order: g.order,
            max: this.maxSizeForGrade(g),
          }
        })
      : []

    const teachers = this.props.teachers
      ? this.props.teachers.map(t => {
          return {
            value: t.id,
            label: fullName(t),
          }
        })
      : []

    const maxClassSize = Number.isInteger(this.props.maxClassSize)
      ? this.props.maxClassSize
      : ""

    /* This is sorting classes alphabetically by class label and handling numeric values in label, e.g. instead of [A1, A10, A2], we'll get [A1, A2, A10] */
    const sortedCurrentClasses = [...this.props.currentClasses].sort((a, b) =>
      a.label.localeCompare(b.label, undefined, {
        numeric: true,
      })
    )

    const availableCriteria = getAvailableCriteria(
      this.props.characteristics,
      sortedCurrentClasses
    )

    const currentCriteria = getCurrentCriteria(
      this.props.entryCriteriaCharacteristicResponse,
      this.props.entryCriteriaStudentAttribute,
      this.props.entryCriteriaNullResponse,
      availableCriteria
    )

    const maxClassSizeMode = isNonEmptyArray(this.props.maxSizePerGrade)
      ? BY_GRADE
      : BY_CLASS

    this.state = {
      loading: false,
      loadingModal: false,
      solverLoading: false,
      isDeleteClass: false,
      deleteModal: false,
      cautionModal: false,
      showVideo: false,
      id: this.props.id,
      label: this.props.label,
      maxClassSize,
      schoolGrades,
      teachers,
      errors: {},
      currentCriteria: currentCriteria,
      availableCriteria,
      advancedSettings:
        currentCriteria.length > 0 ||
        maxClassSize > 0 ||
        isNonEmptyArray(this.props.maxSizePerGrade),
      showAdvancedClassSettingsVideo: false,
      maxClassSizeMode,
    }
  }

  onChange = e => {
    this.setState({
      [e.target.name]: e.target.value,
    })
  }

  toggleDeleteModal = () => {
    this.setState({
      deleteModal: !this.state.deleteModal,
    })
  }

  toggleCautionModal = () => {
    this.setState({
      cautionModal: !this.state.cautionModal,
    })
  }

  toggleLoadingModal = () => {
    this.setState({
      loadingModal: !this.state.loadingModal,
    })
  }

  toggleAdvancedSettings = () => {
    this.setState({
      advancedSettings: !this.state.advancedSettings,
    })
  }

  openVideo = () => {
    this.setState({
      showVideo: true,
    })
  }

  closeVideo = () => {
    this.setState({
      showVideo: false,
    })
  }

  onMultiSelectChange = value => {
    const schoolGrades = value || []

    const maxClassSizeMode =
      schoolGrades.length <= 1 ? BY_CLASS : this.state.maxClassSizeMode

    this.setState({ schoolGrades, maxClassSizeMode })
  }

  onMultiTeacherSelectChange = value => {
    const teachers = value || []

    this.setState({ teachers })
  }

  getSchoolGradesLabel(schoolGrades) {
    if (schoolGrades && schoolGrades.length > 0) {
      return schoolGrades.map(grade => grade.label).join(", ")
    }
    return ""
  }

  duplicateClassLabel({ id: classId, label: classLabel }) {
    const { newClasses } = this.props

    if (newClasses) {
      return newClasses
        .filter(({ id }) => {
          if (classId) {
            return id !== classId
          } else {
            return true
          }
        })
        .some(({ label }) => label === classLabel)
    }
  }

  validateInputs(data) {
    const errors = {}
    if (!validateNonEmptyString(data.label)) errors.label = "Required Field"
    if (data.label.length === 1) {
      errors.classLabelTooShort =
        "Class Label needs to be more than one character e.g. 1A"
    }
    if (this.duplicateClassLabel(data)) {
      errors.duplicateClassLabel =
        "Please select a unique label. If you would like to swap class labels, create a temporary label (e.g. Class X)."
    }
    if (data.schoolGrades.length < 1) {
      errors.schoolGrades = "Must select at least one"
    }

    return errors
  }

  getDeleteSolverVariables(schoolGrades) {
    // build variables for solver Mutation
    let variables = []
    schoolGrades.forEach(grade => {
      if (this.canRerun(grade.id)) {
        variables.push({
          solution: {
            schoolId: getSchoolId(),
            gradeId: grade.id,
          },
        })
      }
    })
    return variables
  }

  getSaveSolverVariables(schoolGrades) {
    // build variables for solver Mutation
    let variables = []
    schoolGrades.forEach(grade => {
      if (this.canRerun(grade)) {
        variables.push({
          solution: {
            schoolId: getSchoolId(),
            gradeId: grade,
          },
        })
      }
    })
    return variables
  }

  gradesHaveChanged = () => {
    const { id, schoolGrades } = this.state
    const { newClasses } = this.props

    const oldClassDetails = newClasses.find(c => c.id === id)
    if (!oldClassDetails) return false

    const newGradeIds = schoolGrades.map(s => s.value).sort()
    const oldGradeIds = oldClassDetails.schoolGrades.map(s => s.id).sort()

    return !areEqualArrays(oldGradeIds, newGradeIds)
  }

  onSaveClassClick = addAgain => {
    const { newClasses } = this.props
    // Check if newClasses are defined
    // If not just save the class as solver does not need to be rerun
    if (!isDefinedNotNull(newClasses)) {
      this.saveClass(false)
    } else {
      const errors = this.validateInputs(this.state)

      this.setState({ errors, isDeleteClass: false })
      if (Object.keys(errors).length === 0) {
        this.saveClass(false, addAgain)
      }
    }
  }

  saveClass = (solverRerun, addAgain) => {
    const { createOrUpdateNewClass, toggle, refetchQueries, refetch } =
      this.props
    const {
      id,
      label,
      schoolGrades,
      teachers,
      maxClassSize,
      currentCriteria,
      maxClassSizeMode,
    } = this.state
    const errors = this.validateInputs(this.state)

    if (Object.keys(errors).length === 0) {
      this.setState({ loading: true })

      // build teachers
      const teacherIds = teachers.map(t => t.value)

      // build grades
      const grades = schoolGrades.map(g => g.value)

      const maximumClassSize = parseInt(maxClassSize)

      const entryCriteriaStudentAttribute =
        getAndConvertEntryCriteriaStudentAttribute(currentCriteria)

      const entryCriteriaCharacteristicResponse =
        getAndConvertEntryCriteriaCharacteristicResponse(currentCriteria)

      const entryCriteriaNullResponse =
        getAndConvertEntryCriteriaNullResponse(currentCriteria)

      let maxSizeParams
      if (maxClassSizeMode === BY_CLASS) {
        maxSizeParams = { maxClassSize: maximumClassSize, maxSizePerGrade: [] }
      } else if (maxClassSizeMode === BY_GRADE) {
        const maxSizePerGrade = schoolGrades.map(grade => {
          return {
            gradeId: grade.value,
            max: grade.max,
          }
        })

        maxSizeParams = {
          maxClassSize: null,
          maxSizePerGrade: maxSizePerGrade.every(({ max }) => !max)
            ? []
            : maxSizePerGrade,
        }
      }

      // build params
      const newClassParams = {
        schoolId: getSchoolId(),
        id,
        label,
        teachers: teacherIds,
        grades,
        entryCriteriaStudentAttribute,
        entryCriteriaCharacteristicResponse,
        entryCriteriaNullResponse,
        ...maxSizeParams,
      }

      createOrUpdateNewClass({
        variables: { newClassParams },
        refetchQueries: refetchQueries,
      })
        .then(() => {
          if (solverRerun === true) {
            this.onSolverRerun()
            // build variables for solver mutation
            const variables = this.getSaveSolverVariables(grades)
            // re run solver for affected grades
            this.rerunSolver(variables)
          } else {
            this.setState({ loading: false })
            // close the modal
            if (addAgain) {
              this.setState({
                label: "",
                maxClassSize: "",
                currentCriteria: [],
              })
            } else {
              toggle()
            }
            // call refetch if available
            if (refetch) {
              refetch()
            }
          }
        })
        .catch(error => {
          handleSessionExpired(error)
          // TODO: Fix the errors properly instead of this hack
          let errorMessage = "Error, please try again."

          if (
            maxClassSizeMode === BY_GRADE &&
            maxSizeParams.maxSizePerGrade.some(({ max }) => !max)
          ) {
            errorMessage = "Maximums must be given for all grades"
          }

          this.setState({
            loading: false,
            errors: { mutation: errorMessage },
          })
        })
    } else {
      this.setState({ errors })
    }
  }

  deleteClass = solverRerun => {
    const { deleteNewClassMutation, refetchQueries, toggle, id } = this.props

    this.setState({
      loading: true,
    })

    deleteNewClassMutation({
      variables: { id },
      refetchQueries,
    })
      .then(() => {
        if (solverRerun === true) {
          const { newSchoolGradesData } = this.props

          this.onSolverRerun()

          // build the solution variables for solver mutation
          const variables = this.getDeleteSolverVariables(newSchoolGradesData)

          // re run the solver for affected schools
          this.rerunSolver(variables)
        } else {
          this.setState({
            deleteModal: false,
            loading: false,
          })
          toggle()
        }
      })
      .catch(error => {
        handleSessionExpired(error)
        this.setState({
          loading: false,
          errors: { delete: "There was an error deleting. Try again." },
        })
      })
  }

  onSolverRerun = () => {
    // show to user that solver is running
    this.setState({
      loading: false,
      deleteModal: false,
      cautionModal: false,
      solverLoading: true,
      loadingModal: true,
    })
  }

  canRerun = gradeId => {
    const { newClasses } = this.props
    const { isDeleteClass } = this.state
    const classesInGrade = newClasses.filter(
      newClass =>
        // only check grades if the new class has a solution attached
        isDefinedNotNull(newClass.solution) &&
        newClass.schoolGrades.some(grade => grade.id === gradeId)
    )
    // cannot re run for last class in solution
    return isDeleteClass ? classesInGrade.length > 1 : classesInGrade.length > 0
  }

  rerunSolver = variables => {
    const { solveActiveGradeMutation } = this.props
    const refetchStudentsQuery = [
      { query: studentsQuery, variables: { schoolId: getSchoolId() } },
    ]
    // Running the solver for each school grade affected
    // cannot seem to get this working in a loop
    if (isDefinedNotNull(variables[0])) {
      // only refetch queries on last solver run
      const refetchQueries =
        variables.length === 1 ? refetchStudentsQuery : undefined
      solveActiveGradeMutation({ variables: variables[0], refetchQueries })
        .then(() => {
          if (isDefinedNotNull(variables[1])) {
            const refetchQueries =
              variables.length === 2 ? refetchStudentsQuery : undefined
            solveActiveGradeMutation({
              variables: variables[1],
              refetchQueries,
            }).then(() => {
              if (isDefinedNotNull(variables[2])) {
                const refetchQueries =
                  variables.length === 3 ? refetchStudentsQuery : undefined
                solveActiveGradeMutation({
                  variables: variables[2],
                  refetchQueries,
                }).then(() => {
                  this.onSuccessfulRerun()
                })
              } else {
                this.onSuccessfulRerun()
              }
            })
          } else {
            this.onSuccessfulRerun()
          }
        })
        .catch(() => {
          this.onErrorRerun()
        })
    } else {
      this.onSuccessfulRerun()
    }
  }

  onSuccessfulRerun = () => {
    this.setState({
      solverLoading: false,
      loadingModal: false,
    })
    this.props.toggle()
  }

  onErrorRerun = () => {
    this.setState({
      solverLoading: false,
      errors: {
        solver: "Network Error",
      },
    })
  }

  convertToEntryCriterionStudentAttributes = criterion => {
    return criterion.selectedOptions.map(({ id }) => ({
      attribute: criterion.id,
      value: id,
    }))
  }

  convertToEntryCriterionCharacteristicResponses = criterion => {
    return criterion.selectedOptions.map(({ id }) => ({
      characteristicResponseId: id,
    }))
  }

  toggleAdvancedClassSettingsVideo = () => {
    this.setState(prevState => {
      return {
        ...prevState,
        showAdvancedClassSettingsVideo:
          !prevState.showAdvancedClassSettingsVideo,
      }
    })
  }

  maxSizeForGrade = grade => {
    const maxSize =
      this.props.maxSizePerGrade &&
      this.props.maxSizePerGrade.find(({ grade: { id } }) => id === grade.id)

    return maxSize ? maxSize.max : null
  }

  render() {
    const {
      loadingOne,
      loadingTwo,
      newSchoolGradesData = [],
      teachersData = [],
      isOpen,
      toggle,
      disableInput,
      heading,
      canDelete,
      showAddAnotherButton,
    } = this.props

    const {
      loading,
      loadingModal,
      solverLoading,
      deleteModal,
      teachers,
      label,
      schoolGrades,
      errors,
      maxClassSize,
      currentCriteria,
      availableCriteria,
      advancedSettings,
      showAdvancedClassSettingsVideo,
      showVideo,
      maxClassSizeMode,
    } = this.state
    const canSetMaxByGrade = schoolGrades.length > 1

    const newSchoolGradeOptions = newSchoolGradesData.map(g => {
      return {
        label: g.label,
        value: g.id,
        order: g.order,
        max: this.maxSizeForGrade(g),
      }
    })

    const newTeacherOptions = teachersData.map(t => {
      return {
        label: fullName(t),
        value: t.id,
      }
    })

    const gettextObj = this.context.gettextObj

    return (
      <Modal isOpen={isOpen} className="modal-dialog-centered">
        <ModalHeader
          title={heading}
          toggle={toggle}
          toggle2={this.openVideo}
          className="px-4 py-3"
        />
        {loading || loadingOne || loadingTwo ? (
          <div className="position-relative p-5">
            <Loader />
          </div>
        ) : (
          <ModalBody className="px-4">
            <Row>
              <FormGroup className="col sm-6">
                <Label>{gettextObj.gettext("Class")} Label</Label>
                <Input
                  type="text"
                  name="label"
                  onChange={this.onChange}
                  value={label}
                  maxLength={10}
                />
                {errors.duplicateClassLabel && (
                  <InlineError text={errors.duplicateClassLabel} />
                )}
                {errors.classLabelTooShort && (
                  <InlineError text={errors.classLabelTooShort} />
                )}
              </FormGroup>
              <FormGroup className="col sm-6">
                <Label>Assigned Teacher/s</Label>
                <InputGroup>
                  <React.Fragment>
                    <Select
                      className="c-input__multiselect"
                      placeholder={`Select teachers`}
                      options={newTeacherOptions}
                      value={teachers}
                      onChange={this.onMultiTeacherSelectChange}
                      closeMenuOnSelect={false}
                      isMulti
                      styles={defaultSelectStyles}
                    />
                    {errors.schoolGrades && (
                      <InlineError text={errors.schoolGrades} />
                    )}
                  </React.Fragment>
                </InputGroup>
              </FormGroup>
            </Row>
            <Row>
              <FormGroup className="col col-6">
                <div
                  style={{ justifyContent: "space-between", display: "flex" }}>
                  <Label>{gettextObj.gettext("New Grade")}/s</Label>

                  <i
                    className="fa fa-info-circle color-blue-mid m-2"
                    data-tooltip-id="optional-info"
                  />
                  <Tooltip
                    className="c-badge-tooltip"
                    id="optional-info"
                    variant="light"
                    opacity={1}>
                    <p>
                      This drop-down list is automatically created off the
                      students listed on the Students page (based on their{" "}
                      {gettextObj.gettext("New Grade")}). 
                    </p>
                    <p>
                      If you don’t see the {gettextObj.gettext("grade")} for
                      students you are trying to place, then you may need to
                      import these students. 
                    </p>
                    <p>
                      To do this, go to Students, Import, then Bulk Import
                      Students.
                    </p>
                  </Tooltip>
                </div>
                <InputGroup>
                  {disableInput ? (
                    <Input
                      type="text"
                      value={this.getSchoolGradesLabel(schoolGrades)}
                      disabled
                    />
                  ) : (
                    <React.Fragment>
                      <Select
                        className="c-input__multiselect"
                        placeholder={`Select ${gettextObj.gettext("grade(s)")}`}
                        options={newSchoolGradeOptions}
                        value={schoolGrades}
                        onChange={this.onMultiSelectChange}
                        closeMenuOnSelect={false}
                        isMulti
                        styles={defaultSelectStyles}
                      />

                      {errors.schoolGrades && (
                        <InlineError text={errors.schoolGrades} />
                      )}
                    </React.Fragment>
                  )}
                </InputGroup>
              </FormGroup>
            </Row>
            <Row>
              <div
                className="col-12 cursor-pointer mt-2 mb-4"
                onClick={this.toggleAdvancedSettings}>
                {advancedSettings ? (
                  <>
                    <i className="fa fa-minus text-primary mr-2" />
                    <span className="text-primary">
                      Close Advanced Settings
                    </span>
                  </>
                ) : (
                  <>
                    <i className="fa fa-plus text-primary mr-2" />
                    <span className="text-primary">Open Advanced Settings</span>
                  </>
                )}
              </div>
            </Row>
            {advancedSettings && (
              <>
                <Row className="ml-0 mb-1 font-size-14">
                  To learn more about Advanced {gettextObj.gettext("Class")}{" "}
                  Settings
                  <a
                    className="d-block mb-3 color-blue-mid"
                    onClick={this.toggleAdvancedClassSettingsVideo}>
                    <i className="fa fa-play ml-2 mr-1 color-blue-mid" />
                    Watch Video
                  </a>
                </Row>
                <Row className="mb-1">
                  <FormGroup className="col-12 sm-6">
                    <div className="d-flex flex-row justify-content-between align-items-center mb-3">
                      <Label className="d-flex align-items-center mb-0">
                        Set a maximum {gettextObj.gettext("class")} size
                        (optional)
                      </Label>
                      {canSetMaxByGrade && (
                        <ButtonGroup>
                          <Button
                            className="btn-mid"
                            active={maxClassSizeMode === BY_CLASS}
                            onClick={() =>
                              this.setState({ maxClassSizeMode: BY_CLASS })
                            }>
                            By {gettextObj.gettext("Class")}
                          </Button>
                          <Button
                            className="btn-mid"
                            active={maxClassSizeMode === BY_GRADE}
                            onClick={() =>
                              this.setState({ maxClassSizeMode: BY_GRADE })
                            }>
                            By {gettextObj.gettext("Grade")}
                          </Button>
                        </ButtonGroup>
                      )}
                    </div>

                    {maxClassSizeMode === BY_CLASS && (
                      <div className="col-6 d-flex justify-content-between align-items-center">
                        <Label className="d-flex align-items-center mb-0">
                          Overall {gettextObj.gettext("class")} maximum:
                        </Label>
                        <Input
                          className="text-right"
                          style={{ width: "5em" }}
                          type="number"
                          name="maxClassSize"
                          onChange={this.onChange}
                          value={maxClassSize}
                          min={0}
                          max={999}
                        />
                      </div>
                    )}
                    {maxClassSizeMode === BY_GRADE &&
                      canSetMaxByGrade &&
                      schoolGrades.map((schoolGrade, index) => {
                        return (
                          <div
                            key={schoolGrade.value}
                            className="mb-1 col-6 d-flex justify-content-between">
                            <Label className="d-flex align-items-center mb-0">
                              {schoolGrade.label} maximum:
                            </Label>
                            <Input
                              className="text-right"
                              style={{ width: "5em" }}
                              type="number"
                              onChange={e => {
                                // Add the max for the particular grade
                                const schoolGrades = this.state.schoolGrades

                                const newSchoolGrades = [
                                  ...this.state.schoolGrades,
                                ]

                                newSchoolGrades.splice(index, 1, {
                                  ...schoolGrades[index],
                                  max: e.target.valueAsNumber,
                                })

                                this.setState({
                                  schoolGrades: newSchoolGrades,
                                })
                              }}
                              value={schoolGrade.max || ""}
                              min={0}
                              max={999}
                            />
                          </div>
                        )
                      })}
                    {errors.label && <InlineError text={errors.label} />}
                  </FormGroup>
                </Row>
                <EntryCriteria
                  currentCriteria={currentCriteria}
                  availableCriteria={availableCriteria}
                  onChange={currentCriteria => {
                    this.setState({ currentCriteria })
                  }}
                />
              </>
            )}
            <div className="d-flex justify-content-between align-items-center">
              {canDelete ? (
                <span
                  className="text-primary col-3 cursor-pointer"
                  onClick={this.toggleDeleteModal}>
                  Delete {gettextObj.gettext("Class")}
                </span>
              ) : (
                <span></span>
              )}
              <div className="col-9 d-flex justify-content-end align-items-center">
                <span
                  className="text-primary px-4 cursor-pointer"
                  onClick={toggle}>
                  Cancel
                </span>
                <Button
                  className="ml-2"
                  onClick={() => {
                    this.onSaveClassClick(false)
                  }}
                  color="primary">
                  Save {gettextObj.gettext("Class")}
                </Button>
                {showAddAnotherButton ? (
                  <Button
                    className="ml-2"
                    onClick={() => {
                      this.onSaveClassClick(true)
                    }}
                    color="primary">
                    Save & Add Another {gettextObj.gettext("Class")}
                  </Button>
                ) : null}
              </div>
            </div>
            {errors.mutation && <InlineError text={errors.mutation} />}
          </ModalBody>
        )}

        <DeleteModal
          isOpen={deleteModal}
          toggle={this.toggleDeleteModal}
          loading={loading}
          heading={`Delete ${gettextObj.gettext("Class")}?`}
          text={`If students have already been placed in this ${gettextObj.gettext(
            "class"
          )}, this will leave any students placed in this ${gettextObj.gettext(
            "class"
          )} without an assigned ${gettextObj.gettext(
            "class"
          )}. You will need to 're-run' the ${gettextObj.gettext(
            "class"
          )} lists on the Solver page to assign these students back into a ${gettextObj.gettext(
            "class"
          )}. This will result in entirely new ${gettextObj.gettext(
            "class"
          )} lists for the affected ${gettextObj.gettext("grade")}(s).`}
          onButtonClick={this.deleteClass}
          error={errors.delete}
        />

        <LoadingModal
          isOpen={loadingModal}
          toggle={this.toggleLoadingModal}
          heading="Running Solver, please wait..."
          loading={solverLoading}
          error={errors.solver}
        />
        {showAdvancedClassSettingsVideo && (
          <VideoModal
            toggle={this.toggleAdvancedClassSettingsVideo}
            videoUrl={ADVANCED_CLASS_SETTINGS_PLAYER_URL}
            title="Advanced Class Settings"
          />
        )}
        {showVideo && (
          <VideoModal
            toggle={this.closeVideo}
            videoUrl={INSTRUCTION_ADD_CLASS_PLAYER_URL}
            title="Advanced Class Settings"
          />
        )}
      </Modal>
    )
  }
}

export const ClassModal = compose(
  graphql(settingsClientQuery, {
    props: ({ data: { settings } }) => ({
      settings,
    }),
  }),
  graphql(teachersQuery, {
    options: () => ({
      variables: { schoolId: getSchoolId() },
    }),
    props: ({ data: { loading, teachers } }) => ({
      loadingOne: loading,
      teachersData: teachers,
    }),
  }),
  graphql(activeNewSchoolGradesQuery, {
    skip: props => props.disableInput,
    options: () => ({
      variables: {
        schoolId: getSchoolId(),
      },
    }),
    props: ({ data: { loading, activeNewSchoolGrades } }) => ({
      loadingTwo: loading,
      newSchoolGradesData: activeNewSchoolGrades,
    }),
  }),
  graphql(createOrUpdateNewClass, { name: "createOrUpdateNewClass" }),
  graphql(deleteNewClassMutation, { name: "deleteNewClassMutation" }),
  graphql(solveActiveGradeMutation, { name: "solveActiveGradeMutation" })
)(ClassModalComponent)
