import * as React from "react"
import { Loader } from "components"
import { MapColumnHeadings } from "components"
import { useNavigate, useLocation } from "react-router-dom"
import { UploadErrorModal } from "domains/upload"

import { useBulkUploadJob } from "domains/upload/hooks/useBulkUploadJob"

const defaultWarning = {
  body: error => {
    return (
      <div>
        <p>
          Sorry, there was an error importing your data. Please try again and if
          the error persists, please contact us by clicking "Need help
          importing?" with the following error details:
        </p>
        <pre>{error.message}</pre>
      </div>
    )
  },
}
const knownWarnings = {
  "duplicate-column-value": {
    body: ({ details }) => {
      const firstColumnName = details.column_key
      const otherColumnName = Object.keys(details.rows[0]).find(
        columnName => columnName !== firstColumnName
      )
      const errorDetails = details.rows
        .map(row => `${row[firstColumnName]},${row[otherColumnName]}`)
        .join("\n")

      return (
        <div>
          <p>
            The following rows were duplicated in your file. Please double check
            and remove them from the CSV file.
          </p>

          <div>
            <pre className="mb-0">{errorDetails}</pre>
          </div>
        </div>
      )
    },
  },
  "file-has-no-data": {
    body: () => (
      <div>
        <p>There is no data to import.</p>
      </div>
    ),
  },
  "student-code-does-not-exist": {
    title: "Student IDs Not Found",
    body: ({ details }) => (
      <div>
        <p>
          The file you uploaded contains Student IDs that are not listed in
          Class Solver:
        </p>
        <p>{details.missing_student_codes.join(", ")}</p>

        <p>
          This is likely because the students have not yet been added to Class
          Solver (eg. new students).
        </p>

        <p>
          Would you like to <b>Import</b> the file for students where we found
          matching Student IDs (if any), or <b>Cancel</b> and check the csv
          file?
        </p>
      </div>
    ),
  },
}

export const MapColumns = () => {
  const navigate = useNavigate()

  // Bring in the router location so we can grab the jobId from state
  const { state: routeState } = useLocation()

  // Load up the upload job
  const {
    fetch: [uploadJob, { error: uploadJobError }],
    update: [updateBulkUploadJob, { loading: isUpdatingJob }],
    cancel: [cancelUploadJob],
    import: [importBulkUploadJob],
  } = useBulkUploadJob(routeState.jobId)

  // Handle when the user wishes to abort the process and cancel the job
  // in flight. To accomplish this, tell the API the job is cancelled and
  // navigate back to the root Students page.
  const handleCancel = React.useCallback(() => {
    return cancelUploadJob().then(() => {
      navigate("/Students")
    })
  }, [cancelUploadJob, navigate])

  if (uploadJobError) {
    return (
      <UploadErrorModal
        isOpen
        title="Error with uploaded file"
        type="Student Notes"
        jobId={routeState.jobId}
        toggle={handleCancel}
        actions={[
          {
            color: "warning",
            onClick: handleCancel,
            text: "Ok",
          },
        ]}>
        {defaultWarning.body(uploadJobError)}
      </UploadErrorModal>
    )
  }

  if (!uploadJob) {
    return <Loader />
  }

  return (
    <MapColumnPresentation
      navigate={navigate}
      uploadJob={uploadJob}
      isUpdatingJob={isUpdatingJob}
      updateBulkUploadJob={updateBulkUploadJob}
      importBulkUploadJob={importBulkUploadJob}
      handleCancel={handleCancel}
    />
  )
}

const MapColumnPresentation = ({
  navigate,
  uploadJob,
  isUpdatingJob,
  updateBulkUploadJob,
  importBulkUploadJob,
  handleCancel,
}) => {
  // Keep a piece of state which holds any upload errors
  const [uploadError, setUploadError] = React.useState()

  // Get the file summary
  const { fileSummary } = uploadJob

  // Memoize a list of suggestions we will automatically apply
  // if a user-facing option exists in `options`.
  const automaticSuggestions = React.useMemo(() => {
    return {
      student_code: ["student id", "student_code"],
      comments: ["notes", "comments"],
    }
  }, [])

  // Define the minimum set of columns that need to be selected. The object
  // is in the shape of `required_column_id` -> `label` or `required_column_id`
  // -> `{label, tooltip}`
  const requiredColumns = React.useMemo(() => {
    return {
      student_code: "Student ID",
      comments: "Notes / Comments",
    }
  }, [])

  // Keep a piece of state which indicates which columns have been mapped.
  const [mappedColumns, setMappedColumns] = React.useState(() => {
    // Initialize the accumulator
    const initialMappedColumns = {}

    // Attempt to apply some automatic mappings, or just end up with an empty
    // object meaning no mappings
    Object.keys(requiredColumns).forEach(requiredColumnName => {
      // Get the suggestions for this column
      const suggestions = automaticSuggestions[requiredColumnName]

      // Check if any of the suggestions exists in the `fileSummary` headers
      // case-insensitively
      fileSummary.headers.forEach(fileHeader => {
        const lowerCase = fileHeader.toLowerCase()
        const noSpaces = lowerCase.replace(/\s/g, "")

        if (
          suggestions.includes(fileHeader) ||
          suggestions.includes(lowerCase) ||
          suggestions.includes(noSpaces) ||
          suggestions.some(suggestion => {
            var regex = new RegExp(`${suggestion}`, "i")
            return fileHeader.match(regex)
          })
        ) {
          initialMappedColumns[requiredColumnName] = fileHeader
        }
      })
    })

    // Return the accumulated mappings
    return initialMappedColumns
  })

  // Keep a piece of state which determines if the help modal should be
  // displayed
  const [showHelpModal, setShowHelpModal] = React.useState(false)

  // Whenever `mappedColumns` changes, allow the user to proceed if all
  // required columns have been mapped
  const isMappingComplete = React.useMemo(() => {
    const mapped = Object.keys(mappedColumns)
    const required = Object.keys(requiredColumns)

    if (mapped.length >= required.length) {
      return required.every(column => mapped.includes(column))
    } else {
      return false
    }
  }, [mappedColumns, requiredColumns])

  // Handle when the help modal should be toggled
  const handleToggleHelpModal = React.useCallback(() => {
    setShowHelpModal(showHelpModal => !showHelpModal)
  }, [])

  // Handle when a column is mapped by the user
  const handleColumnMapped = React.useCallback(({ selectedOption, mapTo }) => {
    // Update state where mapped columns are stored
    setMappedColumns(columns => ({
      ...columns,
      [mapTo]: selectedOption,
    }))
  }, [])

  // Handle when a column is cleared by the user
  const handleColumnClear = React.useCallback(columnName => {
    // Update state such that the nominated column is empty
    setMappedColumns(columns => {
      delete columns[columnName]
      return { ...columns }
    })
  }, [])

  // Handle when the 'Import Now' button is clicked
  const handleContinue = React.useCallback(() => {
    // Compute the meta we want to set on this job from this step
    const meta = JSON.stringify({
      student_code_column: mappedColumns["student_code"],
      student_notes_column: mappedColumns["notes"],
      ignore_student_code_errors: false,
    })

    // Put params together nicely
    const params = { meta: meta, overwriteMeta: false }

    // Update the job and show a dialog on success
    return updateBulkUploadJob(params).then(result => {
      if (result.data && result.data.updateBulkUploadJob) {
        importBulkUploadJob()
          .then(() => {
            navigate("/Students")
          })
          .catch(error => {
            setUploadError(error.graphQLErrors[0])
          })
      }
    })
  }, [mappedColumns, updateBulkUploadJob, navigate, importBulkUploadJob])

  // Handle when the user wishes to import csv file even when there are unknown student ids
  const handleImportWithUnknownIDs = React.useCallback(() => {
    // Compute the new meta we want to assign to the upload job from this step
    const meta = JSON.stringify({
      student_code_column: mappedColumns["student_code"],
      student_notes_column: mappedColumns["notes"],
      ignore_student_code_errors: true,
    })

    // Put params together nicely
    const params = { meta: meta, overwriteMeta: false }

    // Update the job and show a dialog on success
    return updateBulkUploadJob(params).then(result => {
      if (result.data && result.data.updateBulkUploadJob) {
        importBulkUploadJob()
          .then(() => {
            navigate("/Students")
          })
          .catch(error => {
            setUploadError(error.graphQLErrors[0])
          })
      }
    })
  }, [mappedColumns, updateBulkUploadJob, navigate, importBulkUploadJob])

  const errorModalActions =
    uploadError && uploadError.message === "student-code-does-not-exist"
      ? [
          {
            color: "link",
            onClick: handleCancel,
            text: "Cancel",
          },
          {
            color: "primary",
            onClick: handleImportWithUnknownIDs,
            text: "Import",
          },
        ]
      : [
          {
            color: "link",
            onClick: handleCancel,
            text: "Cancel",
          },
        ]
  return (
    <MapColumnHeadings
      importType={"StudentNotes"}
      handleToggleHelpModal={handleToggleHelpModal}
      showHelpModal={showHelpModal}
      uploadJob={uploadJob}
      requiredColumns={requiredColumns}
      mappedColumns={mappedColumns}
      fileSummary={fileSummary}
      options={fileSummary ? fileSummary.headers : []}
      handleColumnMapped={handleColumnMapped}
      handleColumnClear={handleColumnClear}
      isMappingComplete={isMappingComplete}
      isUpdatingJob={isUpdatingJob}
      handleContinue={handleContinue}
      handleCancel={handleCancel}
      uploadError={uploadError}
      errorType={"Student Notes"}
      setUploadError={setUploadError}
      knownWarnings={knownWarnings}
      defaultWarning={defaultWarning}
      errorModalActions={errorModalActions}
    />
  )
}
