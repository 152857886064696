import React, { Component } from "react"

import { SchoolListModal, CautionModal, TextInputModal } from "components"

export class Payments extends Component {
  state = {
    schoolLinkModal: false,
    cautionModal: false,
    textInputModal: false,
    linkedSchools: [],
  }

  toggleModal = key => {
    this.setState(prevState => ({
      [key]: !prevState[key],
    }))
  }

  onSchoolClick = id => {
    const { linkedSchools } = this.state
    let newLinkedSchools = linkedSchools

    const index = newLinkedSchools.findIndex(schoolId => schoolId === id)

    if (index === -1) {
      newLinkedSchools.push(id)
    } else {
      newLinkedSchools.splice(index, 1)
    }
    this.setState({
      linkedSchools: newLinkedSchools,
    })
  }

  linkSchools = () => {
    this.props.linkSchoolsToOneCustomer(this.state.linkedSchools).then(() => {
      this.setState({
        schoolLinkModal: false,
        cautionModal: false,
      })
    })
  }

  linkSchoolToCustomer = (email, description) => {
    this.props.linkSchoolToCustomer(email, description).then(() => {
      this.setState({
        textInputModal: false,
      })
    })
  }

  render() {
    const { loading, errors } = this.props
    const { schoolLinkModal, linkedSchools, cautionModal, textInputModal } =
      this.state

    return (
      <div className="u-content-border-bottom pb-5">
        <h2 className="mb-5 mt-5 u-blue-primary">Payments</h2>
        <div className="d-flex justify-content-between align-items-center">
          <div className="">Link school to stripe account</div>
          <div
            className="c-button--secondary"
            onClick={() => this.toggleModal("textInputModal")}>
            Link School
          </div>
        </div>
        <div className="d-flex justify-content-between my-4 align-items-center">
          <div className="w-50">
            Link multiple schools to one stripe customer(all schools must have
            an attached customer)
          </div>
          <div
            className="c-button--secondary"
            onClick={() => this.toggleModal("schoolLinkModal")}>
            Link Multiple Schools
          </div>
        </div>
        <SchoolListModal
          isOpen={schoolLinkModal}
          toggle={() => this.toggleModal("schoolLinkModal")}
          linkSchoolClick={() => this.toggleModal("cautionModal")}
          onSchoolClick={this.onSchoolClick}
          linkedSchools={linkedSchools}
          payments
        />
        <CautionModal
          isOpen={cautionModal}
          loading={loading}
          error={errors.multiLink}
          toggle={() => this.toggleModal("cautionModal")}
          heading="Link Schools?"
          text="Are you sure that you want to link these schools together as a stripe customer?"
          buttonText="Yes, link"
          onButtonClick={this.linkSchools}
        />
        <TextInputModal
          isOpen={textInputModal}
          loading={loading}
          toggle={() => this.toggleModal("textInputModal")}
          modalTitle="Enter Stripe Customer Details"
          textInputLabel="Email"
          textAreaLabel="Description"
          submitButtonText="Link"
          onSubmit={this.linkSchoolToCustomer}
          withTextArea
          error={errors.linking}
        />
      </div>
    )
  }
}
