import * as React from "react"
import { Button, Input } from "reactstrap"
import { useNavigate, useLocation } from "react-router-dom"

import { HelpModal } from "domains/upload/helpModal"
import { ButtonBar } from "components/buttonBar"

import { useBulkUploadJob } from "domains/upload/hooks/useBulkUploadJob"
import { UploadErrorModal } from "domains/upload"

import { IGNORED, NEW } from "domains/characteristicsImport/constants"

const defaultWarning = {
  body: error => {
    return (
      <div>
        <p>
          Sorry, there was an error importing your data. Please try again and if
          the error persists, please contact us by clicking "Need help
          importing?" with the following error details:
        </p>
        <pre>{error.message}</pre>
      </div>
    )
  },
}
const knownWarnings = {
  "duplicate-column-value": {
    body: ({ details }) => {
      const firstColumnName = details.column_key
      const otherColumnName = Object.keys(details.rows[0]).find(
        columnName => columnName !== firstColumnName
      )
      const errorDetails = details.rows
        .map(row => `${row[firstColumnName]},${row[otherColumnName]}`)
        .join("\n")

      return (
        <div>
          <p>
            The following rows were duplicated in your file. Please double check
            and remove them from the CSV file.
          </p>

          <div>
            <pre className="mb-0">{errorDetails}</pre>
          </div>
        </div>
      )
    },
  },
  "file-has-no-data": {
    body: () => (
      <div>
        <p>
          There is no data to import. Please double check your response label
          matchings and try again.
        </p>
      </div>
    ),
  },
  "student-code-does-not-exist": {
    body: ({ details }) => (
      <div>
        <p>
          The file you uploaded contains Student IDs that are not listed in
          Class Solver:
        </p>
        <p>{details.missing_student_codes.join(", ")}</p>

        <p>
          This is likely because the students have not yet been added to Class
          Solver (eg. new students).
        </p>

        <p>
          Would you like to <b>Import</b> the file for students where we found
          matching Student IDs (if any), or <b>Cancel</b> and check the csv
          file?
        </p>
      </div>
    ),
  },
}

export const MapResponseLabels = () => {
  // Utility for manipulating the router
  const navigate = useNavigate()

  // Bring in the router location so we can grab the jobId from state
  const { state: routeState } = useLocation()

  // Keep a piece of state which determines if the help modal should be
  // displayed
  const [showHelpModal, setShowHelpModal] = React.useState(false)

  // Keep a piece of state which holds any upload errors
  const [uploadError, setUploadError] = React.useState()

  // Inactivate assignAllUnmatchedAsNew button
  const [disableAssignAllUnmatchedAsNew, setDisableAssignAllUnmatchedAsNew] =
    React.useState(false)

  // Load up the upload job
  const {
    fetch: [uploadJob],
    cancel: [cancelUploadJob],
    update: [updateBulkUploadJob, { loading: isUpdating }],
    import: [importBulkUploadJob],
  } = useBulkUploadJob(routeState.jobId)

  // Memoize the subject characteristic name
  const characteristicName = React.useMemo(() => {
    if (uploadJob && uploadJob.fileSummary) {
      return uploadJob.fileSummary.subjectCharacteristic.name
    } else {
      return ""
    }
  }, [uploadJob])

  // Memoize a list of possible response labels to select
  const possibleResponses = React.useMemo(() => {
    if (uploadJob && uploadJob.fileSummary) {
      return uploadJob.fileSummary.subjectCharacteristic.characteristicResponses
        .map(response => {
          return {
            id: response.id,
            label: response.label,
          }
        })
        .concat([
          {
            id: NEW,
            label: "Add As New",
          },
        ])
        .concat([
          {
            id: IGNORED,
            label: "Do Not Import",
          },
        ])
    } else {
      return []
    }
  }, [uploadJob])

  // Memoize a list of responses that are in the CSV file. These are the
  // responses that need to be matched to a possible response above in `possibleResponses`
  const sourceResponses = React.useMemo(() => {
    if (uploadJob && uploadJob.fileSummary) {
      // Ignore blank responses
      return uploadJob.fileSummary.sourceResponseLabels.filter(
        ({ label }) => label !== ""
      )
    } else {
      return []
    }
  }, [uploadJob])

  const isSameLabel = (label1, label2) => {
    // Normalize the labels
    const l1 = label1.toLowerCase()
    const l2 = label2.toLowerCase()

    return l1 === l2 || (l1.startsWith("no") && l2.startsWith("no"))
  }

  // Prefill the mapped responses if they already match a possible response
  const defaultMappedResponses = Object.fromEntries(
    sourceResponses.flatMap(({ label: sourceResponseLabel }) => {
      const preMappedResponse = possibleResponses.find(
        ({ label: possibleResponseLabel }) => {
          return isSameLabel(possibleResponseLabel, sourceResponseLabel)
        }
      )

      return preMappedResponse
        ? [[sourceResponseLabel, preMappedResponse.id]]
        : []
    })
  )

  // Keep a piece of state to store the user's response mappings. The object
  // will be in the form of...
  // ```
  // {"source_response_label" => "mapped_possible_response_id"}
  // ```
  const [mappedResponses, setMappedResponses] = React.useState(
    defaultMappedResponses
  )

  // Memoize whether the Import button should be disabled because
  // the user is yet to map some fields
  const isMappingComplete = React.useMemo(() => {
    return Object.keys(mappedResponses).length >= sourceResponses.length
  }, [mappedResponses, sourceResponses])

  // Helper to toggle the help modal
  const toggleHelpModal = React.useCallback(() => {
    setShowHelpModal(showModal => !showModal)
  }, [])

  const assignAllUnmatchedAsNew = () => {
    const mappedResponsesToUpdate = { ...mappedResponses }

    for (const sourceResponse of sourceResponses) {
      const responseLabel = sourceResponse.label

      if (
        mappedResponses[responseLabel] === undefined ||
        mappedResponses[responseLabel] === "empty"
      ) {
        mappedResponsesToUpdate[responseLabel] = "NEW"
      }
    }

    setMappedResponses(mappedResponsesToUpdate)
  }

  React.useEffect(() => {
    let disablebutton = true
    for (const val of Object.values(sourceResponses)) {
      if (
        mappedResponses[val.label] === undefined ||
        mappedResponses[val.label] === "empty"
      ) {
        disablebutton = disablebutton && false
      }
    }
    setDisableAssignAllUnmatchedAsNew(disablebutton)
  }, [mappedResponses, sourceResponses])

  // Handle when the user wants to go back to the mapping columns screen
  const handleBack = React.useCallback(() => {
    return navigate("/Students/Characteristics/MapColumns", {
      state: {
        jobId: routeState.jobId,
      },
    })
  }, [navigate, routeState])

  // Handle when the 'Import Now' button is clicked
  const handleContinue = React.useCallback(() => {
    // Compute the new meta we want to assign to the upload job from this step
    const meta = JSON.stringify({
      response_map: mappedResponses,
    })

    // Put params together nicely
    const params = { meta: meta, overwriteMeta: false }

    // Update the job and show a dialog on success
    return updateBulkUploadJob(params).then(result => {
      if (result.data && result.data.updateBulkUploadJob) {
        importBulkUploadJob()
          .then(() => {
            navigate("/Students")
          })
          .catch(error => {
            setUploadError(error.graphQLErrors[0])
          })
      } else {
        // TODO: Show an error
      }
    })
  }, [mappedResponses, updateBulkUploadJob, navigate, importBulkUploadJob])

  // Handle when the user wishes to cancel upload job
  const handleCancel = React.useCallback(() => {
    return cancelUploadJob().then(() => {
      navigate("/Students")
    })
  }, [cancelUploadJob, navigate])

  // Handle when the user wishes to import csv file even when there are unknown student ids
  const handleImportWithUnknownIDs = React.useCallback(() => {
    // Compute the new meta we want to assign to the upload job from this step
    const meta = JSON.stringify({
      response_map: mappedResponses,
      ignore_student_code_errors: true,
    })

    // Put params together nicely
    const params = { meta: meta, overwriteMeta: false }

    // Update the job and show a dialog on success
    return updateBulkUploadJob(params).then(result => {
      if (result.data && result.data.updateBulkUploadJob) {
        importBulkUploadJob()
          .then(() => {
            navigate("/Students")
          })
          .catch(error => {
            setUploadError(error.graphQLErrors[0])
          })
      } else {
        // TODO: Show an error
      }
    })
  }, [mappedResponses, updateBulkUploadJob, navigate, importBulkUploadJob])

  // Handle when a response label is mapped to a possible response id
  const handleResponseMapped = React.useCallback((sourceLabel, responseId) => {
    setMappedResponses(mappedResponses => {
      return {
        ...mappedResponses,
        [sourceLabel]: responseId,
      }
    })
  }, [])

  return (
    <div className="container mt-5 pt-5 w-lg-75">
      <div className="mt-5 d-flex flex-row align-items-center">
        <h1 className="mb-0">Match Your Characteristic Response Labels</h1>

        <Button
          className="u-font-weight-medium"
          color="link"
          onClick={toggleHelpModal}>
          Need Help Importing?
        </Button>

        <HelpModal
          isOpen={showHelpModal}
          toggle={toggleHelpModal}
          jobId={uploadJob && uploadJob.id}
          onSentNotification={toggleHelpModal}
          type="StudentCharacteristics"
        />
      </div>

      <div className="pt-3 pb-4">
        The following Response Labels were found in your file. Please match
        these to the labels you have setup in Class Solver.
      </div>

      <div className="container">
        <div className="row pb-2 mb-4 u-content-border-bottom">
          <div className="col-6 font-weight-bold">
            Response Labels in your file
          </div>

          <div className="col-6 justify-between font-weight-bold">
            Response Labels for {characteristicName.toUpperCase()}
            <Button
              className="u-font-weight-medium p-0"
              color="link"
              disabled={disableAssignAllUnmatchedAsNew}
              onClick={assignAllUnmatchedAsNew}>
              Add All Unmatched As New
            </Button>
          </div>
        </div>

        {sourceResponses.map((sourceResponse, index) => {
          // Helper to map this response when the dropdown changes
          const handleResponseSelect = event => {
            handleResponseMapped(sourceResponse.label, event.target.value)
          }

          return (
            <div key={index} className="row mt-3">
              <div className="col-6 pt-1 d-flex">
                <div className="w-75 pr-4">
                  <div className="text-center mb-1 c-grade-label-mapping__gradeLabel">
                    {sourceResponse.label}
                  </div>

                  <div className="text-center align-center font-italic color-grey-default c-grade-label-mapping__records">
                    {sourceResponse.studentCount} records
                  </div>
                </div>

                <div className="w-25 text-center">
                  <em>match to</em>
                </div>
              </div>

              <div className="col-6">
                <div className="d-flex">
                  <Input
                    type="select"
                    value={mappedResponses[sourceResponse.label]}
                    onChange={handleResponseSelect}>
                    <option value="empty">Select</option>

                    {possibleResponses.map(response => {
                      return (
                        <option key={response.id} value={response.id}>
                          {response.label}
                        </option>
                      )
                    })}
                  </Input>
                  <div className="pl-3 text-success-or-danger">
                    {mappedResponses[sourceResponse.label] &&
                    mappedResponses[sourceResponse.label] !== "empty" ? (
                      <i className="fa fa-check text-success" />
                    ) : (
                      <i className="fa fa-warning text-danger" />
                    )}
                  </div>
                </div>
              </div>
            </div>
          )
        })}
      </div>

      <div className="u-content-border-bottom">&nbsp;</div>

      <div className="mt-3 d-flex align-items-center">
        <span className="text-primary mr-a cursor-pointer" onClick={handleBack}>
          <i className="fa fa-chevron-left mr-2" /> Back to Mapping Columns
        </span>

        <ButtonBar
          buttonText="Import Now"
          cancelText="Cancel Import"
          className="p-3"
          disabled={isUpdating || !isMappingComplete}
          onButtonClick={handleContinue}
          onCancelClick={handleCancel}
        />
      </div>

      {uploadError && (
        <UploadErrorModal
          isOpen
          title="Student IDs Not Found"
          type="Student Characteristics"
          jobId={uploadJob && uploadJob.id}
          toggle={() => setUploadError()}
          actions={[
            {
              color: "link",
              onClick: handleCancel,
              text: "Cancel",
            },
            {
              color: "primary",
              onClick: handleImportWithUnknownIDs,
              text: "Import",
            },
          ]}>
          {(knownWarnings[uploadError.message] || defaultWarning).body(
            uploadError
          )}
        </UploadErrorModal>
      )}
    </div>
  )
}
