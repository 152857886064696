import React from "react"

import { ButtonBar } from "components"
import { UploadErrorModal, HelpModal } from "domains/upload"
import { Button } from "reactstrap"
import { GradeMappingRow } from "./GradeMappingRow"
import { isNonEmptyArray } from "util/array"
import { activeCurrentClassesQuery } from "domains/classes/graphql"
import { getSchoolId } from "util/app"
import compose from "lodash.flowright"
import { hasNewGradesByGender, isSpecialGrade } from "util/gradeUtil"

export class GradeLabels extends React.Component {
  constructor(props) {
    super(props)

    const { matchingGrades } = props

    this.state = {
      gradeLabels: matchingGrades,
      helpModal: false,
      warningModal: false,
      warningCode: null,
      warningDetails: null,
    }
  }

  // When the component mounts, attempt to automatically match current grades
  // and next grades
  componentDidMount() {
    this.setState(prevState => {
      const { gradeLabels } = prevState

      const unorderedLabels = gradeLabels.map(grade => {
        // Strip any leading zeros from the grade name
        const gradeName = grade.name
        // Check if a grade's label or code matches exactly
        const matchingGrade = this.props.schoolGrades.find(grade => {
          return (
            !isSpecialGrade(grade) &&
            (this.probablyMatches(grade.code, gradeName) ||
              this.probablyMatches(grade.label, gradeName))
          )
        })

        if (matchingGrade) {
          return {
            ...grade,
            matchedGrade: matchingGrade,
            nextGrade: this.findNextGrade(matchingGrade),
          }
        } else {
          return grade
        }
      })

      const compareGrades = (grade1, grade2) => {
        // Sort by the grade order if they both exist
        if (grade1.matchedGrade && grade2.matchedGrade) {
          return grade1.matchedGrade.order - grade2.matchedGrade.order
        }

        // Otherwise already matched grades come first
        if (grade1.matchedGrade) {
          return -1
        }

        if (grade2.matchedGrade) {
          return 1
        }

        // Otherwise do nothing
        return 0
      }

      return { gradeLabels: [...unorderedLabels].sort(compareGrades) }
    })
  }

  extractNumber = string => {
    // Find the last set of digits in the string
    const regex = /\d+[^\d]*$/
    const matchedNumber = string.match(regex)

    if (matchedNumber) {
      return parseInt(matchedNumber)
    }
  }

  probablyMatches = (s1, s2) => {
    const string1 = s1.toLowerCase()
    const string2 = s2.toLowerCase()

    // If it's an exact match then just return true
    if (string1 === string2) {
      return true
    }

    // If we statically match something then return true
    const staticMatches = {
      p: ["py"],
    }

    if ((staticMatches[string1] || []).includes(string2)) {
      return true
    }

    // Otherwise try to match the numbers which we extract from the string
    const string2Number = this.extractNumber(string2)

    if (string2Number) {
      return this.extractNumber(string1) === string2Number
    } else {
      return false
    }
  }

  toggleHelpModal = () => {
    this.setState({ helpModal: !this.state.helpModal })
  }

  back = () => {
    this.props.navigate("/Students/Upload/Grades")
  }

  // todo: move this to the grade view
  transformGradesNameMapForRemote = grades => {
    return grades.map(g => ({
      gradeNameInFile: g.name,
      gradeCode: g.matchedGrade ? g.matchedGrade.code : null,
      newGradeCode: g.nextGrade ? g.nextGrade.code : null,
      ignored: g.ignored === true,
    }))
  }

  importData = mappings => {
    // This page is the last stage of the student import wizard
    // we transform everything, even what is not specific to this page and
    // send it to backend
    const {
      updateStudentImportMatchers,
      importUploadedFileFromJob,
      currentUploadJob,
    } = this.props

    if (isNonEmptyArray(mappings.grades)) {
      // Need the clone array because Apollo cache is read-only
      const columnMappings = [...mappings.columns]
      const variables = {
        jobId: currentUploadJob.id,
        columnMappings,
        classNameMappings: [],
        gradeNameMappings: this.transformGradesNameMapForRemote(
          mappings.grades
        ),
      }

      const refetchQueries = [
        {
          query: activeCurrentClassesQuery,
          variables: { schoolId: getSchoolId() },
        },
      ]

      this.setState({ loading: true })

      importUploadedFileFromJob({ variables, refetchQueries }).then(
        () => {
          // clear out cached state
          updateStudentImportMatchers({
            variables: {
              newMatchers: {
                grades: [],
                columns: [],
              },
            },
          })
          this.props.navigate("/Students/Upload/Success", {
            state: {
              redirectTo: "/Students",
            },
          })
        },
        error => {
          this.setState({
            warningModal: true,
            warningCode: error.graphQLErrors[0].message,
            warningDetails: error.graphQLErrors.length
              ? error.graphQLErrors[0].details
              : "",
            loading: false,
          })
        }
      )
    } else {
      this.setState({
        warningModal: true,
      })
    }
  }

  save = () => {
    const grades = this.state.gradeLabels.map(grade => {
      return {
        ...grade,
        matchedGrade: grade.matchedGrade || null,
      }
    })

    this.props
      .updateStudentImportMatchers({
        variables: {
          newMatchers: {
            grades: grades,
          },
        },
      })
      .then(
        ({
          data: {
            updateStudentImportMatchers: { studentsImport },
          },
        }) => this.importData(studentsImport)
      )
  }

  cancel = () => {
    const { currentUploadJob, updateBulkUploadJob, navigate } = this.props
    const variables = {
      jobParams: {
        id: currentUploadJob.id,
        type: currentUploadJob.type,
        status: "CANCELLED",
      },
    }

    updateBulkUploadJob({ variables }).then(() => {
      // clear out cached state
      this.props
        .updateStudentImportMatchers({
          variables: {
            newMatchers: {
              grades: [],
              columns: [],
            },
          },
        })
        .then(() => {
          navigate("/Students")
        })
    })
  }

  findNextGrade = fromGrade => {
    if (hasNewGradesByGender(fromGrade)) {
      // We don't want to match any new grades for gendered new grades
      return null
    } else {
      return fromGrade.defaultNewGrade
    }
  }

  handleGradeMappingSelected = (gradeLabel, selectedGrade) => {
    this.setState(prevState => {
      return {
        gradeLabels: prevState.gradeLabels.map(grade => {
          if (grade.name === gradeLabel.name) {
            // This is the grade to be updated! Do so by setting
            // its `matchedGrade` property to the selected grade
            return {
              ...grade,
              matchedGrade: selectedGrade,
              // Also attempt to automatically find `selectedGrade`'s next,
              // natural grade.
              nextGrade: this.findNextGrade(selectedGrade),
            }
          } else {
            return grade
          }
        }),
      }
    })
  }

  handleNextGradeSelect = (gradeLabel, selectedGrade) => {
    this.setState(prevState => {
      return {
        gradeLabels: prevState.gradeLabels.map(grade => {
          if (grade.name === gradeLabel.name) {
            // This is the grade to be updated! Do so by setting
            // its `nextGrade` property to the selected grade
            return {
              ...grade,
              nextGrade: selectedGrade,
            }
          } else {
            return grade
          }
        }),
      }
    })
  }

  handleGradeIgnore = (gradeLabel, isIgnored) => {
    this.setState(prevState => {
      return {
        gradeLabels: prevState.gradeLabels.map(grade => {
          if (grade.name === gradeLabel.name) {
            // This is the grade to be updated! Do so by setting
            // its `nextGrade` property to the selected grade
            return {
              ...grade,
              ignored: isIgnored,
            }
          } else {
            return grade
          }
        }),
      }
    })
  }

  hasKnownWarning = (warningCode, knownWarnings) =>
    warningCode && knownWarnings && knownWarnings[warningCode]

  toggleWarningModal = () => {
    this.setState(prevState => ({ warningModal: !prevState.warningModal }))
  }

  resetWarningCode = () => {
    this.setState({
      warningCode: null,
      warningDetails: null,
    })
  }

  render() {
    const {
      gradeLabels,
      helpModal,
      warningModal,
      warningCode,
      warningDetails,
    } = this.state

    const { knownWarnings, currentUploadJob, schoolGrades } = this.props

    // If the default new grades are by gender, we don't allow mapping of new grades
    const allowNewGradeMapping = !hasNewGradesByGender(schoolGrades[0])

    return (
      <div className="container mt-5 pt-5 w-lg-75">
        <div className="mt-5 d-flex flex-row align-items-center">
          <h1 className="mb-0">Match Your Grade Labels</h1>

          <Button
            className="u-font-weight-medium"
            color="link"
            onClick={this.toggleHelpModal}>
            Need Help Importing?
          </Button>

          <HelpModal
            isOpen={helpModal}
            toggle={this.toggleHelpModal}
            onSentNotification={this.cancel}
            jobId={currentUploadJob.id}
            type="Students"
          />
        </div>

        <div className="pt-3 pb-4">
          Please match the Grades found in your file to the Current Grade in
          Class Solver and check the students are then “going to” the right New
          Grade for the next academic year. For example, Current Grade 1’s are
          “going to” Grade 2 next year.
        </div>

        <div className="container">
          <div className="row pb-2 mb-3 u-content-border-bottom">
            <div className="col-lg font-weight-bold">Grades in Your File</div>
            <div className="col-md font-weight-bold">Current Grade</div>
            {allowNewGradeMapping && (
              <>
                <div className="col-1">&nbsp;</div>
                <div className="col-md font-weight-bold">New Grade</div>
              </>
            )}
            <div className="col-sm font-weight-bold text-center">
              Don't Import
            </div>
          </div>
        </div>

        {gradeLabels.map((gradeLabel, index) => {
          return (
            <GradeMappingRow
              key={index}
              gradeLabel={gradeLabel}
              grades={schoolGrades}
              handleGradeMappingSelected={this.handleGradeMappingSelected}
              handleNextGradeSelect={this.handleNextGradeSelect}
              handleGradeIgnore={this.handleGradeIgnore}
              allowNewGradeMapping={allowNewGradeMapping}
            />
          )
        })}

        <div className="u-content-border-bottom">&nbsp;</div>

        <div className="mt-3 d-flex align-items-center">
          <span
            className="text-primary mr-a cursor-pointer"
            onClick={this.back}>
            <i className="fa fa-chevron-left mr-2" /> Back to Grade System
          </span>

          <ButtonBar
            className="p-3"
            buttonText="Save & Continue"
            cancelText="Cancel Import"
            onButtonClick={this.save}
            onCancelClick={this.cancel}
            disabled={gradeLabels.some(gradeLabel => {
              return (
                !gradeLabel.ignored &&
                (allowNewGradeMapping
                  ? !gradeLabel.matchedGrade || !gradeLabel.nextGrade
                  : !gradeLabel.matchedGrade)
              )
            })}
          />
        </div>
        {!this.hasKnownWarning(warningCode, knownWarnings) ? (
          <UploadErrorModal
            toggle={this.toggleWarningModal}
            type="Students"
            jobId={currentUploadJob.id}
            isOpen={warningModal}>
            Something went wrong with the upload. Please review the classes and
            grades.
          </UploadErrorModal>
        ) : (
          <UploadErrorModal
            title={knownWarnings[warningCode].title}
            isOpen={warningModal}
            jobId={currentUploadJob.id}
            type="Students"
            toggle={compose(this.resetWarningCode, this.toggleWarningModal)}
            actions={knownWarnings[warningCode].actions(this.props)}>
            {knownWarnings[warningCode].body("Students", warningDetails)}
          </UploadErrorModal>
        )}
      </div>
    )
  }
}
