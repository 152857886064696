import React from "react"
import { Link } from "react-router-dom"
import {
  Dropdown,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
} from "reactstrap"
import { NORMAL } from "domains/solver/constants"
import { AccountTypeContext } from "config/accountTypeContext"

import { shortDateToString, timeFromDateTimeString } from "util/dateTimeUtil"

import {
  EditAssignmentModal,
  AddAssignmentModal,
  UnFavouriteAssignmentModal,
  MaximumNumberOfFavouritesReachedModal,
} from "components"

import { getFavouritedAssignmentsQuery } from "domains/solver/graphql"

export const MoreDropdown = ({
  dropdownExportsOpen,
  toggle,
  solverMode,
  print,
  csvExport,
  shareSolution,
}) => {
  // Utility for manipulating the router

  return (
    <Dropdown
      className="c-side-bar__panel__minor-actions-container__dropdown"
      isOpen={dropdownExportsOpen}
      toggle={toggle}>
      <DropdownToggle caret>More ...</DropdownToggle>
      <DropdownMenu>
        <DropdownItem onClick={print}>Print</DropdownItem>
        {solverMode === NORMAL && (
          <React.Fragment>
            <DropdownItem onClick={csvExport}>Export</DropdownItem>
            <DropdownItem onClick={shareSolution}>Share</DropdownItem>
          </React.Fragment>
        )}
      </DropdownMenu>
    </Dropdown>
  )
}

export const ReportsDropdown = ({ dropdownReportsOpen, toggle }) => {
  const gettextObj = React.useContext(AccountTypeContext).gettextObj

  return (
    <Dropdown
      className="c-side-bar__panel__minor-actions-container__dropdown"
      isOpen={dropdownReportsOpen}
      toggle={toggle}>
      <DropdownToggle caret>Reports</DropdownToggle>
      <DropdownMenu>
        <DropdownItem>
          <Link to="/SummaryReport/Class">{`${gettextObj.gettext(
            "Class"
          )} Summary Report`}</Link>
        </DropdownItem>
        <DropdownItem>
          <Link to="/SummaryReport/School">School Summary Report</Link>
        </DropdownItem>
        <DropdownItem>
          <Link to="/SummaryReport/Sociogram">Sociogram View</Link>
        </DropdownItem>
      </DropdownMenu>
    </Dropdown>
  )
}

const descriptionFromAssignment = assignment => {
  const description = assignment.assignmentType

  if (description === "MOVE") {
    return "Move"
  } else if (description === "BOOST") {
    return "Boost"
  } else if (description === "ADD") {
    return "Add"
  } else if (description === "REMOVE") {
    return "Remove"
  } else if (description === "SOLVE") {
    return "Run"
  } else if (description === "LOAD") {
    return "Load"
  } else if (description === "UNDO") {
    return "Undo"
  } else if (description === "REDO") {
    return "Redo"
  } else {
    return "Change"
  }
}

const userNameFromUser = user => {
  if (user && (user.role === "SUPPORT" || user.role === "SUPER")) {
    return "(Class Solver Support)"
  } else if (user && user.firstName && user.lastName) {
    return `(${user.firstName} ${user.lastName.charAt(0)})`
  }

  return ""
}

const findFavouriteForAssignment = (assignment, favourites) => {
  const parent = assignment.parent

  let favouriteForAssignment
  if (parent) {
    favouriteForAssignment = favourites.find(favourite => {
      return favourite.assignmentId === parent.id
    })
  }

  return favouriteForAssignment
}

const informationForAssignment = (assignment, favourites) => {
  const description = assignment.assignmentType
  const movedStudents = assignment.movedStudents

  if (
    (description === "LOAD" ||
      description === "UNDO" ||
      description === "REDO") &&
    assignment.parent
  ) {
    const favouriteForAssignment = findFavouriteForAssignment(
      assignment,
      favourites
    )

    return favouriteForAssignment && favouriteForAssignment.notes
      ? `From ${favouriteForAssignment.notes}`
      : `From ${shortDateToString(assignment.parent.insertedAt) || ""} - ${timeFromDateTimeString(assignment.parent.insertedAt) || ""}`
  }

  if (!movedStudents) {
    return ""
  }

  if (description === "MOVE") {
    return getChangesStringFromMovedStudents(movedStudents)
  } else if (description === "BOOST") {
    return getChangesStringFromBoostedStudents(movedStudents)
  } else if (description === "ADD") {
    return getChangesStringFromMovedStudents(movedStudents)
  } else if (description === "REMOVE") {
    return getChangesStringFromRemovedStudents(movedStudents)
  } else if (description === "SOLVE") {
    return "Grade Run"
  } else {
    return "Information Not Available"
  }
}

const studentNameString = movedStudent => {
  const student = movedStudent.student

  return student
    ? `${student.firstName} ${student.lastName}`
    : "removed student"
}

const fromClassNameString = student => {
  return student.from ? student.from.label : "no class"
}

const toClassNameString = student => {
  return student.to ? student.to.label : "no class"
}

const getChangesStringFromMovedStudents = movedStudents => {
  const numStudents = movedStudents.length
  if (numStudents === 1) {
    const student = movedStudents[0]

    const studentName = studentNameString(student)
    const toClass = toClassNameString(student)

    return `${studentName} > ${toClass}`
  } else if (numStudents > 1) {
    const firstStudent = movedStudents[0]
    const firstStudentClass = firstStudent?.to

    const allSameClass =
      firstStudentClass &&
      movedStudents.every(movedStudent => {
        return movedStudent.to?.label === firstStudentClass.label
      })

    if (firstStudentClass && allSameClass) {
      return `${numStudents} students > ${toClassNameString(firstStudent)}`
    } else {
      return `${numStudents} students`
    }
  }
}

const getChangesStringFromBoostedStudents = movedStudents => {
  const numStudents = movedStudents.length

  if (numStudents === 0) {
    return "No changes"
  } else if (numStudents === 2) {
    const student1Name = studentNameString(movedStudents[0])
    const student2Name = studentNameString(movedStudents[1])

    return `${student1Name} <> ${student2Name}`
  }
}

const getChangesStringFromRemovedStudents = movedStudents => {
  const numStudents = movedStudents.length
  if (numStudents === 1) {
    const student = movedStudents[0]

    const studentName = studentNameString(student)
    const fromClass = fromClassNameString(student)

    return `${studentName} - ${fromClass}`
  } else if (numStudents > 1) {
    return `${numStudents} students`
  }
}

export const SolutionsDropDown = ({
  solutionsDropDownOpen,
  toggleSolutionsDropDown,
  favourites,
  assignments,
  favouriteAnAssignmentMutation,
  unfavouriteAnAssignmentMutation,
  updateFavouritedAssignmentMutation,
  pickAnAssignment,
  activeGradeId,
}) => {
  const [assignmentIdForSave, setAssignmentIdForSave] = React.useState()
  const [assignmentIdForUpdate, setAssignmentIdForUpdate] = React.useState()
  const [favouriteNotes, setFavouriteNotes] = React.useState()
  const [assignmentIdForUnFavouriting, setAssignmentIdForUnFavouriting] =
    React.useState()
  const [
    maximumNumberOfFavouritesReached,
    setMaximumNumberOfFavouritesReached,
  ] = React.useState(false)

  const setDataForUpdatingFavourite = favourite => {
    if (favourite) {
      setAssignmentIdForUpdate(favourite.assignmentId)
      setFavouriteNotes(favourite.notes)
    } else {
      setAssignmentIdForUpdate(null)
      setFavouriteNotes(null)
    }
  }

  const favouriteAnAssignmentClick = assignment => {
    if (favourites.length >= 5) {
      setMaximumNumberOfFavouritesReached(true)
    } else {
      setMaximumNumberOfFavouritesReached(false)
      setAssignmentIdForSave(assignment.id)
    }
  }

  const pickAnAssignmentClick = assignment => {
    if (assignment.id !== assignments[0]?.id) {
      pickAnAssignment(assignment.id, activeGradeId)
    }
  }

  const pickAFavouriteClick = favourite => {
    pickAnAssignment(favourite.assignmentId, activeGradeId)
  }

  // PREVIEW FEATURE: This is a beta feature which allows the user to preview an assignment without actually updating the solution
  // const previewAssignment = assignment => {
  //   assignmentWithClasses(assignment.id)
  // }

  const assignmentIsfavourited = assignment => {
    const assignmentId = assignment.id

    return favourites.some(favourite => favourite.assignmentId === assignmentId)
  }

  const favouriteAssignmentClick = assignment => {
    if (!maximumNumberOfFavouritesReached) {
      favouriteAnAssignmentClick(assignment)
    }
  }

  const favouriteAnAssignment = (assignmentId, notes) => {
    let variables = {
      favouriteParams: { assignmentId, notes },
    }

    const refetchQueries = [
      {
        query: getFavouritedAssignmentsQuery,
        variables: { gradeId: activeGradeId },
      },
    ]

    return favouriteAnAssignmentMutation({ variables, refetchQueries })
  }

  const updateFavourite = (assignmentId, notes) => {
    const variables = {
      updateFavouritedAssignmentParams: { assignmentId, notes },
    }

    const refetchQueries = [
      {
        query: getFavouritedAssignmentsQuery,
        variables: { gradeId: activeGradeId },
      },
    ]

    return updateFavouritedAssignmentMutation({ variables, refetchQueries })
  }

  // TODO Nick: Somewhat hacky solution to keeping menu open when opening modal, closing menu when clicking onto rest of page
  const eventDependentToggle = () => {
    if (
      maximumNumberOfFavouritesReached ||
      assignmentIdForUnFavouriting ||
      assignmentIdForUpdate ||
      assignmentIdForSave
    ) {
    } else {
      toggleSolutionsDropDown()
    }
  }

  const lastLoadedAssignmentIndex = assignments.findIndex(
    ({ assignmentType }) => assignmentType === "LOAD"
  )

  return (
    <>
      <Dropdown
        // This switches off Popper.js (we don't need it here) so things are snappier
        inNavbar
        isOpen={solutionsDropDownOpen}
        toggle={eventDependentToggle}>
        <DropdownToggle
          className="pl-2 pt-2 d-flex flex-column align-items-center justify-content-center"
          tag="div">
          <i className="fa-solid fa fa-clock-o c-side-bar__clock" />
        </DropdownToggle>
        <DropdownMenu className="c-side-bar__timeline-container" right>
          <div className="c-side-bar__timeline-sub-heading pl-3 pb-1 pt-1">
            <b className="">Pinned Times</b>
          </div>

          {favourites.length === 0 ? (
            <p className="px-4 u-text-12">
              {"To save a time click on a pin in the Timeline"}
            </p>
          ) : (
            favourites.map((favourite, index) => (
              <DropdownItem
                toggle={false}
                className="c-side-bar__timeline-item py-2 pr-0"
                key={`favourite-${index}`}>
                <div className="d-flex flex-row justify-content-between">
                  <div
                    className="d-flex flex-row overflow-hidden"
                    onClick={() => pickAFavouriteClick(favourite)}>
                    <div className="d-flex flex-column pr-3">
                      <p className="m-0 u-text-12">
                        {shortDateToString(favourite.insertedAt) || ""}
                      </p>
                      <p className="m-0 u-text-12">
                        {timeFromDateTimeString(favourite.insertedAt) || ""}
                      </p>
                    </div>

                    <div className="d-flex flex-column overflow-hidden ">
                      <p className="u-text-12 m-0 u-bold text-truncate">
                        {favourite.notes || "add description"}
                      </p>
                      <p className="m-0 u-text-12">
                        {userNameFromUser(favourite.user) || "no user"}
                      </p>
                    </div>
                  </div>
                  <div className="d-flex flex-row align-items-center mx-2">
                    <i
                      className="fa fa-pencil c-side-bar__pencil mr-2"
                      onClick={() => {
                        setDataForUpdatingFavourite(favourite)
                      }}
                    />

                    <i
                      className="fa fa-thumb-tack c-side-bar__blue-pin"
                      onClick={() =>
                        setAssignmentIdForUnFavouriting(favourite.assignmentId)
                      }
                    />
                  </div>
                </div>
              </DropdownItem>
            ))
          )}

          <div className="c-side-bar__panel__divider-line d-print-none" />

          <div className="c-side-bar__timeline-sub-heading pl-3 pb-1 pt-3">
            <b className="">Timeline</b>
          </div>

          {assignments.map((assignment, index) => (
            <DropdownItem
              toggle={false}
              className={`c-side-bar__timeline-item py-2 pr-0 ${lastLoadedAssignmentIndex === index ? "bg-grey-requests" : ""}`}
              key={`assignment-${index}`}>
              <div className="d-flex flex-row justify-content-between">
                <div
                  className="d-flex flex-row overflow-hidden"
                  onClick={() => pickAnAssignmentClick(assignment)}>
                  <div className="d-flex flex-column pr-3">
                    <p className="m-0 u-text-12">
                      {shortDateToString(assignment.insertedAt) || ""}
                    </p>
                    <p className="m-0 u-text-12">
                      {timeFromDateTimeString(assignment.insertedAt) || ""}
                    </p>
                  </div>
                  <div className="d-flex flex-column overflow-hidden">
                    <div className="d-flex flex-row">
                      <p className="m-0 u-text-12 u-bold">
                        {descriptionFromAssignment(assignment)}
                      </p>

                      <p className="m-0 ml-1 u-text-12 text-truncate">
                        {userNameFromUser(assignment.user)}
                      </p>
                    </div>

                    <p className="m-0 u-text-12 text-truncate">
                      {informationForAssignment(assignment, favourites)}
                    </p>
                  </div>
                </div>

                <div className="d-flex flex-row align-items-center mx-2">
                  {assignmentIsfavourited(assignment) ? (
                    <i
                      className="fa fa-thumb-tack c-side-bar__blue-pin"
                      onClick={() => {
                        setAssignmentIdForUnFavouriting(assignment.id)
                      }}
                    />
                  ) : (
                    <i
                      className="fa fa-thumb-tack c-side-bar__grey-star"
                      onClick={() => favouriteAssignmentClick(assignment)}
                    />
                  )}
                </div>
              </div>
            </DropdownItem>
          ))}
        </DropdownMenu>
      </Dropdown>

      {assignmentIdForSave && (
        <AddAssignmentModal
          assignmentId={assignmentIdForSave}
          favouriteAnAssignment={favouriteAnAssignment}
          toggle={() => setAssignmentIdForSave(null)}
        />
      )}

      {assignmentIdForUpdate && (
        <EditAssignmentModal
          assignmentId={assignmentIdForUpdate}
          updateFavourite={updateFavourite}
          toggle={() => setDataForUpdatingFavourite(null)}
          favouriteNotes={favouriteNotes}
        />
      )}

      {assignmentIdForUnFavouriting && (
        <UnFavouriteAssignmentModal
          assignmentIdForUnFavouriting={assignmentIdForUnFavouriting}
          unfavouriteAnAssignmentMutation={unfavouriteAnAssignmentMutation}
          setAssignmentIdForUpdate={setAssignmentIdForUpdate}
          setDataForUpdatingFavourite={setDataForUpdatingFavourite}
          setAssignmentIdForSave={setAssignmentIdForSave}
          closeModal={() => setAssignmentIdForUnFavouriting(null)}
          activeGradeId={activeGradeId}
        />
      )}

      {maximumNumberOfFavouritesReached && (
        <MaximumNumberOfFavouritesReachedModal
          closeModal={() => setMaximumNumberOfFavouritesReached(false)}
        />
      )}
    </>
  )
}
