import { gql } from "@apollo/client"

export const teachersQuery = gql`
  query ($schoolId: ID!) {
    teachers(schoolId: $schoolId) {
      id
      firstName
      lastName
      email
      currentClasses {
        id
        label
        grades {
          id
          order
        }
      }
      survey {
        id
        completed
        allocatedClasses {
          id
          label
        }
        updatedAt
        url
        expired
        restricted
      }
    }
  }
`

export const teachersInfoQuery = gql`
  query ($schoolId: ID!) {
    teachers(schoolId: $schoolId) {
      id
      firstName
      lastName
      email
      currentClasses {
        id
        label
        grades {
          id
          order
        }
      }
    }
  }
`

export const teacherNameQuery = gql`
  query ($schoolId: ID!) {
    teachers(schoolId: $schoolId) {
      firstName
      lastName
      id
    }
  }
`

export const teacherQuery = gql`
  query get($id: ID, $email: String, $schoolId: ID) {
    teacher(id: $id, email: $email, schoolId: $schoolId) {
      email
      firstName
      id
      schoolId
      lastName
      currentClasses {
        id
        label
      }
      active
    }
  }
`

export const closeOrOpenAllSurveysMutation = gql`
  mutation ($schoolId: ID!, $completed: Boolean!) {
    closeOrOpenAllSurveys(schoolId: $schoolId, completed: $completed) {
      totalUpdated
    }
  }
`

export const closeOrOpenTeacherSurveyMutation = gql`
  mutation ($surveyCloseOpenParams: SurveyCloseOpenParams!) {
    closeOrOpenSurvey(surveyCloseOpenParams: $surveyCloseOpenParams) {
      id
      teacherId
      completed
    }
  }
`

export const updateTeachersMutation = gql`
  mutation createOrUpdateTeacher($teacherParams: TeacherParams) {
    createOrUpdateTeacher(teacher: $teacherParams) {
      id
    }
  }
`

export const inviteTeachersToSurveysMutation = gql`
  mutation (
    $schoolId: ID!
    $message: String!
    $classAssignments: [ClassAssignment!]!
    $ccList: [String!]
    $restricted: Boolean!
    $sendNotification: Boolean
  ) {
    inviteTeachersToSurveys(
      message: $message
      classAssignments: $classAssignments
      ccList: $ccList
      schoolId: $schoolId
      restricted: $restricted
      sendNotification: $sendNotification
    ) {
      id
    }
  }
`

export const deleteTeacherMutation = gql`
  mutation ($id: ID!) {
    deleteTeacher(id: $id) {
      id
    }
  }
`

export const bulkUploadTeachersMutation = gql`
  mutation ($schoolId: ID!, $file: Upload!) {
    bulkUploadTeachers(schoolId: $schoolId, file: $file) {
      id
      status
    }
  }
`

export const deactivateAllTeachersMutation = gql`
  mutation ($schoolId: ID!) {
    deactivateAllTeachers(schoolId: $schoolId)
  }
`
