import * as React from "react"
import { MapColumnHeadings } from "components"
import { useNavigate } from "react-router-dom"

export const ColumnMapping = props => {
  const navigate = useNavigate()

  // Destructure the props we want
  const { currentUploadJob, updateBulkUploadJob, updateStudentImportMatchers } =
    props

  // Get the file summary
  const { fileSummary } = currentUploadJob

  // Memoize a list of suggestions we will automatically apply
  // if a user-facing option exists in `options`.
  const automaticSuggestions = React.useMemo(() => {
    return {
      student_code: ["student id", "student_code"],
      first_name: [
        "first name",
        "f name",
        "fname",
        "given name",
        "first_name",
        "firstname",
      ],
      last_name: [
        "last name",
        "l name",
        "lname",
        "surname",
        "last_name",
        "family name",
        "lastname",
      ],
      gender: ["gender", "sex"],
      current_grade: [
        "current grade",
        "curr. grade",
        "curr grade",
        "current_grade",
        "year",
      ],
      current_class: [
        "current class",
        "curr. class",
        "curr class",
        "current_class",
        "roll class",
      ],
    }
  }, [])

  // Define the minimum set of columns that need to be selected. The object
  // is in the shape of `required_column_id` -> `label` or `required_column_id`
  // -> `{label, tooltip}`
  const requiredColumns = React.useMemo(() => {
    return {
      student_code: "Student ID",
      first_name: "First Name",
      last_name: "Last Name",
      gender: "Gender",
      current_grade: "Current Grade",
      current_class: {
        label: "Current Class",
        tooltip: "e.g. 1A or 1 Mrs Smith",
      },
    }
  }, [])

  // Keep a piece of state which indicates which columns have been mapped. A
  // column mapping is an object where the keys are a member of either
  // `requiredColumns` or `optionalColumns` and the values are the column found
  // in the user's CSV
  //
  // EG
  // {
  //   "student_code": "user_student_code_column",
  //   "first_name": "user_first_name_column",
  //   ...
  // }
  const [mappedColumns, setMappedColumns] = React.useState(() => {
    // Initialize the accumulator
    const initialMappedColumns = {}

    // Attempt to apply some automatic mappings, or just end up with an empty
    // object meaning no mappings
    Object.keys(requiredColumns).forEach(requiredColumnName => {
      // Get the suggestions for this column
      const suggestions = automaticSuggestions[requiredColumnName]

      // Check if any of the suggestions exists in the `fileSummary` headers
      // case-insensitively
      fileSummary.headers.forEach(fileHeader => {
        // E.G.
        // requiredColumnName = first_name
        // fileHeader = fname
        // suggestions = ["first name", "f name", "given name"]
        const lowerCase = fileHeader.toLowerCase()
        const noSpaces = lowerCase.replace(/\s/g, "")

        if (
          suggestions.includes(fileHeader) ||
          suggestions.includes(lowerCase) ||
          suggestions.includes(noSpaces) ||
          suggestions.some(suggestion => {
            var regex = new RegExp(`${suggestion}`, "i")
            return fileHeader.match(regex)
          })
        ) {
          initialMappedColumns[requiredColumnName] = fileHeader
        }
      })
    })

    // Return the accumulated mappings
    return initialMappedColumns
  })

  // Keep a piece of state which determines if the help modal should be
  // displayed
  const [showHelpModal, setShowHelpModal] = React.useState(false)

  // Keep a piece of state to determine if the user has made any mappings since
  // the component has mounted. This is used in conjunction with
  // `isMappingComplete` to determine if we need to automatically navigate to
  // the next step
  const [userDidInput, setUserDidInput] = React.useState(false)

  // Whenever `mappedColumns` changes, allow the user to proceed if all
  // required columns have been mapped
  const isMappingComplete = React.useMemo(() => {
    const mapped = Object.keys(mappedColumns)
    const required = Object.keys(requiredColumns)
    const mappedValues = Object.values(mappedColumns)

    const hasNoDuplicateValues =
      new Set(mappedValues).size === mappedValues.length

    if (mapped.length >= required.length && hasNoDuplicateValues) {
      return required.every(column => mapped.includes(column))
    } else {
      return false
    }
  }, [mappedColumns, requiredColumns])

  // Handle when the help modal should be toggled
  const handleToggleHelpModal = React.useCallback(() => {
    setShowHelpModal(showHelpModal => !showHelpModal)
  }, [])

  // Handle when a column is mapped by the user
  const handleColumnMapped = React.useCallback(({ selectedOption, mapTo }) => {
    // Update state such that we don't automatically navigate to the next step
    // if this was the last column to be mapped
    setUserDidInput(true)

    // Update state where mapped columns are stored
    setMappedColumns(columns => ({
      ...columns,
      [mapTo]: selectedOption,
    }))
  }, [])

  // Handle when a column is cleared by the user
  const handleColumnClear = React.useCallback(columnName => {
    // Update state such that the nominated column is empty
    setMappedColumns(columns => {
      delete columns[columnName]
      return { ...columns }
    })
  }, [])

  // Handle when the user wishes to abort the process and cancel the job
  // in flight. To accomplish this, tell the API the job is cancelled and
  // navigate back to the root Students page.
  const handleCancel = React.useCallback(() => {
    const variables = {
      jobParams: {
        id: currentUploadJob.id,
        type: currentUploadJob.type,
        status: "CANCELLED",
      },
    }

    // Tell the API the job is cancelled and clear out local cache
    updateBulkUploadJob({ variables }).then(() => {
      updateStudentImportMatchers({
        variables: {
          newMatchers: {
            grades: [],
            columns: [],
          },
        },
      }).then(() => {
        navigate("/Students")
      })
    })
  }, [
    updateBulkUploadJob,
    updateStudentImportMatchers,
    navigate,
    currentUploadJob.id,
    currentUploadJob.type,
  ])

  // Handle when the user has completed mapping by pushing the data over to
  // the API
  const handleContinue = React.useCallback(() => {
    // Build the variables to be sent to the API. `columnMap` is a list of the
    // `ColumnMap` input object type
    //
    // EG: [{"Student ID": "student_code"}, ...]
    const columnMap = Object.keys(mappedColumns).map(requiredColumn => {
      return {
        columnNameInFile: mappedColumns[requiredColumn],
        columnName: requiredColumn,
      }
    })

    // Persist the column mapping data to `studentsImport` state and proceed
    // to the next screen
    return updateStudentImportMatchers({
      variables: {
        newMatchers: {
          columns: columnMap,
        },
      },
    }).then(() => {
      navigate("/Students/Upload/IdChangeWarning")
    })
  }, [updateStudentImportMatchers, mappedColumns, navigate])

  // When the component mounts, check if mapping has been completed
  // automatically and proceed with no user input if it has
  React.useEffect(() => {
    if (isMappingComplete && !userDidInput) {
      handleContinue()
    }
  }, [isMappingComplete, userDidInput, handleContinue])

  return (
    <MapColumnHeadings
      importType={"Students"}
      handleToggleHelpModal={handleToggleHelpModal}
      showHelpModal={showHelpModal}
      uploadJob={currentUploadJob}
      requiredColumns={requiredColumns}
      mappedColumns={mappedColumns}
      options={fileSummary.headers}
      handleColumnMapped={handleColumnMapped}
      handleColumnClear={handleColumnClear}
      isMappingComplete={isMappingComplete}
      isUpdatingJob={!isMappingComplete}
      handleContinue={handleContinue}
      handleCancel={handleCancel}
    />
  )
}
