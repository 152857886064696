import * as React from "react"
import { useTable, useSortBy } from "react-table"
import { needHighlighting } from "util/teacherUtil"
import moment from "moment"

export const TeachersTable = props => {
  // Destructure the props we want
  const {
    teachers,
    onRowClick,
    closeSurveysButton,
    onSendMultipleSurveysClick,
    adminEmailDomain,
    surveysSent,
  } = props

  // Define and memoize the table's columns
  const columns = React.useMemo(() => {
    return [
      {
        Header: "Teacher Details",
        columns: [
          {
            Header: "First Name",
            accessor: "firstName",
            className: "first-name-cell",
          },
          {
            Header: "Last Name",
            accessor: "lastName",
            className: "last-name-cell",
          },
          {
            Header: "Email",
            Cell: EmailCell,
            accessor: "email",
            className: "email-cell wide-cell",
            adminEmailDomain: adminEmailDomain,
          },
        ],
      },
      {
        Header: "CURRENT CLASS OR CLASSES",
        headerClassName: "first-in-group-header text-nowrap",
        columns: [
          {
            Header: "Current School Year",
            accessor: "allClasses",
            id: "allClasses",
            className: "current-class-cell class-1-cell first-in-group-cell",
            headerClassName: "first-in-group-header classes-header",
          },
        ],
      },
      {
        Header: "Survey",
        headerClassName: "first-in-group-header",
        className: "wide-cell",
        columns: [
          {
            Header: "Allocated to Class",
            accessor: "allocated",
            className: "first-in-group-cell wide-cell passive-cell",
            headerClassName: "first-in-group-header passive-cell",
            disableSortBy: true,
          },
        ],
      },
      {
        id: "send-multiple-new-surveys",
        Header: () => (
          <span onClick={onSendMultipleSurveysClick}>
            Send Multiple New Surveys
          </span>
        ),
        headerClassName: "button-header less-wide-cell",
        columns: [
          {
            Header: "Status",
            Cell: SurveyStatusCell,
            accessor: "status",
            className: "less-wide-cell passive-cell",
            headerClassName: "passive-cell",
            disableSortBy: true,
          },
        ],
      },
      {
        id: "close-all-surveys",
        Header: () => closeSurveysButton,
        headerClassName: "button-header close-header",
        columns: [
          {
            Header: "",
            accessor: "resend",
            className: "passive-cell button-cell end-cell",
            headerClassName: "passive-cell",
            disableSortBy: true,
          },
        ],
      },
    ]
  }, [adminEmailDomain, closeSurveysButton, onSendMultipleSurveysClick])

  // Memoize the data to render
  const teacherData = React.useMemo(() => teachers, [teachers])

  // Set up the table's props
  const tableProps = {
    ...props,
    data: teacherData,
    columns: columns,
    initialState: {
      // If surveys has not been sent, we need to hide the survey columns
      hiddenColumns:
        surveysSent === true ? [] : ["status", "allocated", "resend"],
    },
  }

  // Bootstrap the table
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable(tableProps, useSortBy)

  // Render the table!
  return (
    <div className="teacher-sortable-table">
      <table {...getTableProps()} cellSpacing="0">
        <thead className={teachers.length === 0 ? "no-data" : ""}>
          {headerGroups.map(headerGroup => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map(column => {
                // Apply sortable props and header class name if it exists
                const props = {
                  ...column.getHeaderProps({
                    ...column.getSortByToggleProps(),
                    className: column.headerClassName,
                  }),
                }

                // Render the 'th' cell as a sortable column
                return (
                  <th {...props} title="">
                    {column.render("Header")}
                    {/* Add a sort direction indicator */}
                    <span>
                      {column.isSorted
                        ? column.isSortedDesc
                          ? " ↓"
                          : " ↑"
                        : ""}
                    </span>
                  </th>
                )
              })}
            </tr>
          ))}
        </thead>

        {teachers.length === 0 && (
          <tbody>
            <tr className="empty-row">
              <td className="empty-data-cell" colspan="100">
                There are no teachers to view. Try adding a new one.
              </td>
            </tr>
          </tbody>
        )}

        {teachers.length > 0 && (
          <tbody {...getTableBodyProps()}>
            {rows.map(row => {
              prepareRow(row)

              // Handle which this teacher is clicked
              const handleClick = () => onRowClick(teacherData[row.index])

              return (
                <tr onClick={handleClick} {...row.getRowProps()}>
                  {row.cells.map(cell => {
                    // Inject the cell's className in to its other props if
                    // it is set, react table doesn't handle this itself.
                    const cellProps = cell.getCellProps({
                      className: cell.column.className,
                    })

                    // Render the cell
                    return <td {...cellProps}>{cell.render("Cell")}</td>
                  })}
                </tr>
              )
            })}
          </tbody>
        )}
      </table>
    </div>
  )
}

const EmailCell = table => {
  // Determine if this email needs to be highlighted
  const needsHighlighting = needHighlighting(
    table.value,
    table.column.adminEmailDomain
  )

  // Render the cell
  return <span className={`highlight-${needsHighlighting}`}>{table.value}</span>
}

const SurveyStatusCell = table => {
  // Retrieve the teacher from the table's data set
  const rowIndex = table.row.index
  const teacher = table.data[rowIndex]

  return (
    <div className="d-flex justify-content-between align-items-center w-100">
      <div>{table.value}</div>
      {teacher.survey && (
        <span className="font-weight-light px-5">
          {moment(teacher.survey.updatedAt).format("D MMM YYYY")}
        </span>
      )}
    </div>
  )
}
