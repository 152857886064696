import React, { Component } from "react"
import { Modal, ModalBody } from "reactstrap"
import { graphql, withApollo } from "@apollo/client/react/hoc"
import compose from "lodash.flowright"
import { createOrUpdateSchoolMutation } from "domains/accountSettings/graphql"

import {
  SchoolDetailsInputs,
  InlineError,
  ButtonBar,
  Loader,
  ModalHeader,
} from "components"

import { validateSchoolData } from "util/validators"

import { SCHOOL_ID } from "constants/storageTokens"

class EditSchoolModalComponent extends Component {
  constructor(props) {
    super(props)
    this.state = {
      data: {
        schoolName: this.props.data.name,
        addressLine1: this.props.data.address,
        suburb: this.props.data.suburb,
        countryCode: this.props.data.countryCode,
        stateCode: this.props.data.stateCode,
        postcode: this.props.data.postcode,
        contactNumber: this.props.data.contactNumber,
        numberOfStudents: this.props.data.numberOfStudents,
        crmAccountName: this.props.data.crmAccountName,
        managers: this.transformToSelectOptions(this.props.data.managers),
        crmStatus: this.props.data.crmStatus,
        accountType: this.props.data.accountType,
        accountName: this.props.data.accountName,
      },
      errors: {},
      loading: false,
    }
  }

  transformToSelectOptions = managers => {
    if (!managers) return []

    return managers.map(manager => ({
      label: `${manager.firstName} ${manager.lastName}`,
      value: manager.id,
    }))
  }

  onManagerChange = managers => {
    this.setState({
      data: { ...this.state.data, managers },
    })
  }

  onChange = e => {
    this.setState(prevState => ({
      data: {
        ...prevState.data,
        [e.target.name]: e.target.value,
      },
      errors: { ...prevState.errors, [e.target.name]: null },
    }))
  }

  saveSchool = () => {
    const { createOrUpdateSchoolMutation, toggle, refetchQueries, isSuper } =
      this.props
    const { data } = this.state

    // remove addressLine1 from object
    const { addressLine1, accountType, schoolName, managers, ...others } = data

    const errors = validateSchoolData(data, false)
    this.setState({ errors })
    if (Object.keys(errors).length === 0) {
      this.setState({ loading: true })

      const schoolVariables = {
        ...others,
        name: schoolName,
        accountType: accountType,
        address: addressLine1,
        numberOfStudents: parseInt(data.numberOfStudents, 10),
        id: sessionStorage.getItem(SCHOOL_ID),
        managers: managers.map(manager => manager.value),
      }

      if (!isSuper) {
        delete schoolVariables["accountType"]
      }

      const variables = {
        school: schoolVariables,
      }

      createOrUpdateSchoolMutation({ variables, refetchQueries })
        .then(() => {
          this.setState({
            loading: false,
          })
          toggle()
        })
        .catch(() => {
          this.setState({
            loading: false,
            errors: {
              mutation: "There was an error with updating. Please try again",
            },
          })
        })
    }
  }

  render() {
    const { isOpen, toggle, client, isSuper, managers } = this.props
    const { data, errors, loading } = this.state

    return (
      <Modal isOpen={isOpen}>
        <ModalHeader
          title="Edit School"
          toggle={toggle}
          className="px-4 py-4"
        />
        <ModalBody className="p-4">
          {loading ? (
            <div className="position-relative p-5">
              <Loader />
            </div>
          ) : (
            <div>
              <SchoolDetailsInputs
                isSuper={isSuper}
                client={client}
                data={data}
                errors={errors}
                edit
                onChange={this.onChange}
                availableManagers={this.transformToSelectOptions(managers)}
                onManagerChange={this.onManagerChange}
              />
              <ButtonBar
                onCancelClick={toggle}
                buttonText="Save"
                onButtonClick={this.saveSchool}
              />
              <InlineError text={errors.mutation} />
            </div>
          )}
        </ModalBody>
      </Modal>
    )
  }
}

export const EditSchoolModal = compose(
  graphql(createOrUpdateSchoolMutation, {
    name: "createOrUpdateSchoolMutation",
  })
)(withApollo(EditSchoolModalComponent))
