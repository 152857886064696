import React from "react"
import { Query } from "@apollo/client/react/components"
import { activeCurrentClassesQuery } from "domains/classes/graphql"
import { Modal } from "reactstrap"

import { Loader, QueryError, ColdStart } from "components"
import { TeacherModal, ClassOption } from "domains/teachers/components"

import teachersTemplate from "templateFiles/teachers_template.csv"

import { SCHOOL_ID } from "constants/storageTokens"
import { warnings } from "domains/students/upload/warnings"
import { sortCurrentClassesByLabel } from "util/sortUtil"

import { INSTRUCTION_ADD_TEACHERS_PLAYER_URL } from "constants/videos"

export const TeachersColdStart = ({
  teacher,
  teacherAddEdit,
  mutationLoading,
  bulkUploadTeachersMutation,
  importUploadedFileFromJob,
  teacherRefetch,
  errors,
  onChange,
  edit,
  teacherModal,
  toggleTeacherModal,
  inviteModal,
  toggleInviteModal,
  uploadsDisabled = false,
  toggleBulkUploadScreen,
  children,
  displayBulkUploadScreen,
}) => {
  const uploadAndProcess = arg => {
    return bulkUploadTeachersMutation(arg)
      .then(({ data: { bulkUploadTeachers } }) => {
        const variables = {
          jobId: bulkUploadTeachers.id,
          classNameMappings: [],
          gradeNameMappings: [],
          columnMappings: [],
        }
        return importUploadedFileFromJob({ variables })
      })
      .then(() => {
        teacherRefetch()
      })
  }
  return (
    <React.Fragment>
      <ColdStart
        type="Teachers"
        title="Import Teachers"
        step1="1. Download and complete the teachers template"
        backText="< Back to Teachers page"
        file={teachersTemplate}
        fileUploadMutation={uploadAndProcess}
        knownWarnings={warnings}
        nextStep="/Teachers"
        uploadsDisabled={uploadsDisabled}
        toggleBulkUploadScreen={toggleBulkUploadScreen}
        toggleTeacherModal={toggleTeacherModal}
        displayBulkUploadScreen={displayBulkUploadScreen}
        requiredHeaders="First Name,Surname,Email,Current Class,Additional Class (if applicable)"
        videoUrl={INSTRUCTION_ADD_TEACHERS_PLAYER_URL}
        videoOpenText={"Watch Video"}>
        {children}
      </ColdStart>
      {/* Classes Query For Invite Modal */}
      <Query
        query={activeCurrentClassesQuery}
        variables={{ schoolId: sessionStorage.getItem(SCHOOL_ID) }}>
        {({ loading, error, data, refetch }) => {
          if (loading) {
            // Loading Modal
            return (
              // TODO: Work out what toggleInviteModal is doing here
              <Modal isOpen={inviteModal} toggle={toggleInviteModal}>
                <Loader />
              </Modal>
            )
          }

          if (error) {
            // Error Modal
            return (
              <Modal isOpen={inviteModal} toggle={toggleInviteModal}>
                <QueryError error={error} refetch={refetch} />
              </Modal>
            )
          }

          const { activeCurrentClasses } = data

          // create the options for class select fields
          const classOptions = sortCurrentClassesByLabel(
            activeCurrentClasses
          ).map((c, index) => <ClassOption key={index} schoolClass={c} />)

          return (
            <TeacherModal
              classOptions={classOptions}
              toggle={toggleTeacherModal}
              isOpen={teacherModal}
              edit={edit}
              onChange={onChange}
              teacher={teacher}
              onAddEditClick={teacherAddEdit}
              errors={errors}
              loading={mutationLoading}
            />
          )
        }}
      </Query>
    </React.Fragment>
  )
}
