import React, { useCallback, useState, useEffect } from "react"
import { useQuery } from "@apollo/client"
import { Button } from "reactstrap"
import { areEqualArrays } from "util/array"
import { useNavigate } from "react-router-dom"

import { Loader } from "components"
import { ButtonBar } from "components/buttonBar"
import { HelpModal } from "domains/upload/helpModal"
import { useBulkUploadJob } from "domains/upload/hooks/useBulkUploadJob"
import { UploadErrorModal } from "domains/upload"

import { FriendshipPreferencesTable } from "./FriendshipPreferencesTable"
import { studentsQuery } from "domains/students/graphql"
import { getSchoolId } from "util/app"
import { schoolUploadsQuery } from "domains/upload/graphql"
import { pendingImport } from "domains/students/pendingImport"

const defaultWarning = {
  body: error => {
    return (
      <div>
        <p>
          Sorry, there was an error importing your file. Please try importing
          again and if the error persists, please contact us by clicking "Need
          help importing?" with the following error details:
        </p>
        <pre>{error.message}</pre>
      </div>
    )
  },
}

// We need to first find out what the latest pending job is
export const FriendshipPreferencesMapStudents = () => {
  const schoolId = getSchoolId()
  const { data, loading } = useQuery(schoolUploadsQuery, {
    variables: { schoolId },
    fetchPolicy: "network-only",
    errorPolicy: "all",
  })

  if (loading) {
    return <Loader />
  }

  const { job: currentUploadJob } = pendingImport(data, "FRIENDSHIP_PREFERENCE")

  return <MapStudents currentUploadJob={currentUploadJob} schoolId={schoolId} />
}

const MapStudents = ({ currentUploadJob, schoolId }) => {
  const navigate = useNavigate()

  useEffect(() => {
    if (!currentUploadJob) {
      navigate("/Students")
    }
  })

  const jobId = currentUploadJob?.id

  // Keep track of whether to show the help modal
  const [showHelpModal, setShowHelpModal] = useState(false)

  // Get all the students for the school
  const { data: studentsData, loading: loadingStudents } = useQuery(
    studentsQuery,
    { variables: { schoolId } }
  )

  // Load up the upload job
  const {
    fetch: [uploadJob, { loading: loadingUploadJob, error: uploadJobError }],
    update: [updateBulkUploadJob],
    cancel: [cancelUploadJob],
    import: [
      importBulkUploadJob,
      { loading: loadingImport, error: importJobError },
    ],
  } = useBulkUploadJob(jobId)

  // Handle when the user presses the Cancel button
  const handleCancel = useCallback(() => {
    return cancelUploadJob().then(() => {
      navigate("/Students")
    })
  }, [cancelUploadJob, navigate])

  // Toggle the help modal
  const toggleHelpModal = useCallback(() => {
    setShowHelpModal(showModal => !showModal)
  }, [])

  if (loadingStudents || loadingUploadJob) {
    return <Loader />
  }

  if (uploadJobError) {
    return (
      <UploadErrorModal
        isOpen
        title="Error with uploaded file"
        type="Friendship Preferences"
        jobId={jobId}
        toggle={handleCancel}
        actions={[
          {
            color: "warning",
            onClick: handleCancel,
            text: "Ok",
          },
        ]}>
        {defaultWarning.body(uploadJobError)}
      </UploadErrorModal>
    )
  }

  // Update the meta field in the job
  const updateMeta = meta => {
    const params = { meta: JSON.stringify(meta), overwriteMeta: false }

    return updateBulkUploadJob(params)
  }

  // Import the uploaded file along with the meta
  const importFriendshipPreferences = () => {
    importBulkUploadJob().then(() => {
      navigate("/Students")
    })
  }

  // Collect all the data required for later operations
  const allStudents = studentsData.students
  const meta = parseMeta(uploadJob)
  const nameMap = getNameMap(meta, allStudents)
  const ignoredRows = getIgnoredRows(meta)
  const { rows, canonicalHeaders } = uploadJob.uploadedFile.csvData

  const rowData = rows.map(row => JSON.parse(row))

  // Update the name_map field in the meta
  const updateNameMap = (name, student) => {
    const studentId = student === "" ? "" : parseInt(student)
    updateMeta({
      ...meta,
      name_map: { ...meta.name_map, [name]: studentId },
    })
  }

  const selectedRows = [...Array(rows.length).keys()].filter(
    rowIndex => !ignoredRows.includes(rowIndex)
  )

  const updateSelectedRows = selectedRowIndices => {
    const newIgnoredRows = [...Array(rows.length).keys()].filter(
      rowIndex => !selectedRowIndices.includes(rowIndex)
    )
    if (!areEqualArrays(newIgnoredRows, ignoredRows)) {
      updateMeta({ ...meta, ignored_rows: newIgnoredRows })
    }
  }

  return (
    <>
      <div>
        <div className="d-flex flex-column my-3 ml-3">
          <div className="d-flex flex-row align-items-center">
            <h1 className="mb-0">Match Student Names</h1>

            <Button
              className="u-font-weight-medium"
              color="link"
              onClick={toggleHelpModal}>
              Need Help Importing?
            </Button>

            <HelpModal
              isOpen={showHelpModal}
              toggle={toggleHelpModal}
              jobId={jobId}
              onSentNotification={toggleHelpModal}
              type="FriendshipPreferencesImport"
            />
          </div>
          <div className="d-flex justify-content-between">
            <p className="mb-0">
              We were unable to match some of the students in your file to the
              student names in Class Solver. <br />
              Please click on these students and type the student's name listed
              in Class Solver. When you are finished, click Import.
            </p>
            <ButtonBar
              buttonText="Import Friends"
              onButtonClick={importFriendshipPreferences}
              onCancelClick={handleCancel}
              disabled={!validImportData(rowData, nameMap, ignoredRows)}
            />
          </div>
        </div>
        {loadingImport ? (
          <Loader />
        ) : (
          <FriendshipPreferencesTable
            rowData={rowData}
            canonicalHeaders={canonicalHeaders}
            nameMap={nameMap}
            selectedRows={selectedRows}
            updateSelectedRows={updateSelectedRows}
            updateNameMap={updateNameMap}
            students={allStudents}
          />
        )}
      </div>
      {importJobError && (
        <UploadErrorModal
          isOpen
          title="Error with uploaded file"
          type="Friendship Preferences"
          jobId={jobId}
          toggle={handleCancel}
          actions={[
            {
              color: "warning",
              onClick: handleCancel,
              text: "Ok",
            },
          ]}>
          {defaultWarning.body(importJobError)}
        </UploadErrorModal>
      )}
    </>
  )
}

const validImportData = (rowData, nameMap, ignoredRows) => {
  const mappedNames = Object.keys(nameMap)

  const importRows = rowData.filter((_, index) => !ignoredRows.includes(index))
  if (importRows.length === 0) return false

  return (
    importRows
      .flatMap(row => Object.values(row))
      // We don't care about empty fields
      .filter(name => name !== "")
      .every(name => mappedNames.includes(name))
  )
}

// Whether all the names of an uploaded job has been matched already
export const allNamesMatched = uploadJob => {
  const meta = parseMeta(uploadJob)
  // We don't care about the actual mapped students in the nameMap, just that it is completed
  const nameMap = getNameMap(meta, [])
  const ignoredRows = getIgnoredRows(meta)
  const rowData = uploadJob.uploadedFile.csvData.rows.map(row =>
    JSON.parse(row)
  )

  return validImportData(rowData, nameMap, ignoredRows)
}

const parseMeta = uploadJob => {
  return JSON.parse(uploadJob.meta) || { name_map: {}, ignored_rows: [] }
}

const getNameMap = (meta, allStudents) => {
  // Parse and replace the name_map with the mapped students
  return Object.fromEntries(
    Object.entries(meta.name_map).map(([name, studentId]) => [
      name,
      allStudents.find(student => parseInt(student.id) === studentId),
    ])
  )
}

const getIgnoredRows = meta => {
  return meta.ignored_rows
}
