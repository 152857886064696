import React, { Component } from "react"
import { Navigate } from "react-router-dom"
import { graphql } from "@apollo/client/react/hoc"
import compose from "lodash.flowright"
import {
  schoolWithSisSuperQuery,
  createOrUpdateUserMutation,
  administratorsQuery,
  preferencesQuery,
  updateSchoolSettingMutation,
  deleteSchoolMutation,
  deleteSchoolDataMutation,
  linkPaymentToSchoolMutation,
  linkMultipleSchoolsToOneCustomerMutation,
  rollOverData,
} from "domains/accountSettings/graphql"
import { deactivateAllStudentsMutation } from "domains/students/graphql"
import { userProfileQuery } from "domains/auth/graphql"
import { requestQuery } from "domains/requests/graphql"
import { getNotificationsQuery } from "domains/notifications/graphql"

import { Loader } from "components"
import { SuperAdmin } from "domains/superAdmin/components"

import { SUPPORT, SUPER } from "domains/app/constants/roleTypes"
import { getSchoolId } from "util/app"
import { isPrivilegedRole } from "util/userUtil"

class SuperAdminPageComponent extends Component {
  render() {
    const {
      loadingUsers,
      loadingPreferences,
      loadingSchool,
      loadingSuperUsers,
      users,
      superUsers,
      myprofile,
      createOrUpdateUserMutation,
      updateSchoolSettingMutation,
      deleteSchoolMutation,
      deleteSchoolDataMutation,
      schoolSettings,
      school,
      linkPaymentToSchoolMutation,
      linkMultipleSchoolsToOneCustomerMutation,
      rollOverData,
      deactivateAllStudentsMutation,
    } = this.props

    if (!isPrivilegedRole(myprofile.role)) return <Navigate to="/" replace />
    if (
      loadingUsers ||
      loadingPreferences ||
      loadingSchool ||
      loadingSuperUsers
    )
      return <Loader />

    // Queries to refetch
    const refetchQueries = [
      {
        query: administratorsQuery,
        variables: {
          filterRole: SUPPORT,
        },
      },
      {
        query: administratorsQuery,
        variables: {
          filterRole: SUPER,
        },
      },
    ]

    const managers = users.concat(superUsers)

    return (
      <SuperAdmin
        superAdmins={users}
        managers={managers}
        myprofile={myprofile}
        refetchQueries={refetchQueries}
        createOrUpdateUserMutation={createOrUpdateUserMutation}
        updateSchoolSettingMutation={updateSchoolSettingMutation}
        schoolSettings={schoolSettings}
        preferencesQuery={preferencesQuery}
        school={school}
        deleteSchoolDataMutation={deleteSchoolDataMutation}
        deleteSchoolMutation={deleteSchoolMutation}
        linkPaymentToSchoolMutation={linkPaymentToSchoolMutation}
        linkMultipleSchoolsToOneCustomerMutation={
          linkMultipleSchoolsToOneCustomerMutation
        }
        rollOverData={rollOverData}
        deactivateAllStudentsMutation={deactivateAllStudentsMutation}
      />
    )
  }
}

export const SuperAdminPage = compose(
  graphql(administratorsQuery, {
    options: () => ({
      variables: {
        filterRole: SUPPORT,
      },
    }),
    props: ({ data: { users, loading } }) => ({
      loadingUsers: loading,
      users,
    }),
  }),
  graphql(administratorsQuery, {
    options: () => ({
      variables: {
        filterRole: SUPER,
      },
    }),
    props: ({ data: { users, loading } }) => ({
      loadingSuperUsers: loading,
      superUsers: users,
    }),
  }),
  graphql(userProfileQuery, {
    props: ({ data: { myprofile } }) => ({
      myprofile,
    }),
  }),
  graphql(createOrUpdateUserMutation, { name: "createOrUpdateUserMutation" }),
  graphql(preferencesQuery, {
    options: () => ({
      variables: { schoolId: getSchoolId() },
    }),
    props: ({ data: { schoolSettings, loading } }) => ({
      loadingPreferences: loading,
      schoolSettings,
    }),
  }),
  graphql(updateSchoolSettingMutation, { name: "updateSchoolSettingMutation" }),
  graphql(schoolWithSisSuperQuery, {
    options: () => ({
      variables: { schoolId: getSchoolId() },
    }),
    props: ({ data: { school, loading } }) => ({
      loadingSchool: loading,
      school,
    }),
  }),
  graphql(deleteSchoolMutation, { name: "deleteSchoolMutation" }),
  graphql(deleteSchoolDataMutation, { name: "deleteSchoolDataMutation" }),
  graphql(linkMultipleSchoolsToOneCustomerMutation, {
    name: "linkMultipleSchoolsToOneCustomerMutation",
  }),
  graphql(linkPaymentToSchoolMutation, { name: "linkPaymentToSchoolMutation" }),
  graphql(rollOverData, {
    name: "rollOverData",
    options: () => {
      return {
        variables: { schoolId: getSchoolId() },
        refetchQueries: [
          {
            query: requestQuery,
            variables: {
              adminOnly: true,
              schoolId: getSchoolId(),
            },
          },
          {
            query: getNotificationsQuery,
            variables: { schoolId: getSchoolId() },
          },
        ],
      }
    },
  }),
  graphql(deactivateAllStudentsMutation, {
    name: "deactivateAllStudentsMutation",
    options: () => ({
      variables: { schoolId: getSchoolId() },
    }),
  })
)(SuperAdminPageComponent)
