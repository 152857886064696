export const PAYMENT_TYPES = {
  card: "card",
  cheque: "cheque",
}

export const DIRECT_TRANSFER_TABLE = [
  { label: "Bank", value: "Bankwest" },
  { label: "Account Name", value: "Class Solver Pty Ltd" },
  { label: "BSB", value: "303 500" },
  { label: "Account No.", value: "009 5188" },
  { label: "Reference", value: "[schoolID]" },
]

export const CHEQUE_TABLE = [
  { label: "Payable to", value: "Class Solver Pty Ltd" },
  { label: "Address", value: "2/47 Wangara Road Cheltenham VIC 3192" },
]

export const PAYMENT_TABLE = [
  [
    {
      label: "No. of students enrolled",
      id: "1",
    },
    {
      label: "Price per student/year",
      id: "2",
    },
  ],
  [
    {
      label: "Total Price (exc. GST)",
      id: "3",
    },
    {
      label: "Promotional Discount",
      id: "4",
    },
    {
      label: "GST",
      id: "5",
    },
    {
      label: "Total Price (inc. GST)",
      id: "6",
    },
  ],
]

export const SURVEY = "SURVEY"
export const SHARED_SOLUTION = "SHARED_SOLUTION"

export const JWT_CLAIM_NAMESPACE = "https://classsolver.com"
export const MFA_CLAIM = `${JWT_CLAIM_NAMESPACE}/mfa`
